import { TFunction } from "i18next";
import { SortOption, TimeOption } from "../../../../utils/utils-filter";
import { Headline } from "../templates/headline/Headline";

export function getCreatorSpecificHeadline(
    t: TFunction,
    sort: SortOption,
    timeOption: TimeOption,
    filterKey: string,
    category: string | null
): Headline {
    console.log("getSpecificHeadline", sort, timeOption, filterKey, category);

    const timeSentenceKey = `sort_${sort.key}_time_${timeOption.key}`;
    const timeSentence = t(`creators:timeSentenceOptions.${timeSentenceKey}`);

    const titles: { [key: string]: string } = {
        "all": t("creators:titles.all"),
        "verified": t("creators:titles.verified"),
        "notion-certified": t("creators:titles.certified"),
        "featured": t("creators:titles.featured"),
    };
    const descriptions: { [key: string]: string } = {
        "all": category
            ? t("creators:descriptions.allWithCategory", { category: t(category) })
            : t("creators:descriptions.allWithoutCategory"),
        "verified": category
            ? t("creators:descriptions.verifiedWithCategory", { category: t(category) })
            : t("creators:descriptions.verifiedWithoutCategory"),
        "notion-certified": category
            ? t("creators:descriptions.certifiedWithCategory", { category: t(category) })
            : t("creators:descriptions.certifiedWithoutCategory"),
        "featured": category
            ? t("creators:descriptions.featuredWithCategory", { category })
            : t("creators:descriptions.featuredWithoutCategory"),
    };
    const title = titles[filterKey] || t("creators:titles.default");
    const description = descriptions[filterKey] || t("creators:descriptions.default");
    return {
        title,
        description: description + " " + timeSentence,
    };
}
