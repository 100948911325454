import React from "react";
import { connect, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import AudiencePage from "../pages/account/audience/AudiencePage";
import ComingSoon from "../components/common/common/ComingSoonScreen";
import AccountPage from "../pages/account/AccountPage";
import HomePage from "../pages/account/home/HomePage";
import WishlistPage from "../pages/account/wishlist/WishlistPage";
import ProfilePage from "../pages/account/profile/ProfilePage";
import ProfilePromotionScreen from "../pages/account/promotion/ProfilePromotionScreen";
import TemplatePromotionScreen from "../pages/account/promotion/TemplatePromotionScreen";
import SettingsPage from "../pages/account/settings/SettingsPage";
import TemplatePage from "../pages/account/template/TemplatePage";
import TemplatesPage from "../pages/account/templates/TemplatesPage";
import { RootState } from "../store";
import { Destination } from "../utils/constants-navigation";

const AccountApp = () => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const [title, setTitle] = React.useState<string>("");
  const [titleView, setTitleView] = React.useState<React.ReactNode>(<></>);
  const location = useLocation();

  /**
   * Handle title update event.
   * @param newTitle The new title.
   */
  const handleTitleUpdate = (newTitle: string) => {
    setTitle(newTitle);
  };

  React.useEffect(() => {
    // Get the current pathname from thπe location object
    const pathnames = location.pathname.split("/");
    const pathname = pathnames[pathnames.length - 1];

    const titleMap: { [key: string]: string } = {
      dashboard: "common:dashboard",
      profile: "common:profile",
      templates: "common:templates",
      settings: "common:settings",
      analytics: "common:analytics",
      audience: "common:audience",
      liked: "common:liked",
      library: "common:library",
    };

    // Set the title based on the current pathname or other conditions
    if (pathname in titleMap) {
      setTitle(titleMap[pathname]);
    } else if (location.pathname.includes("template-promotion")) {
      setTitle("common:template_promotion");
    } else {
      // No title or dynamic title
    }
    setTitle("");
  }, [location]);

  return (
    <>
      {isSignedIn ? (
        <AccountPage title={title} titleView={titleView}>
          <Routes>
            <Route path={Destination.REL_ACCOUNT_PROFILE} element={<ProfilePage />} />
            <Route path={Destination.REL_ACCOUNT_DASHBOARD_START} element={<HomePage />} />
            <Route path={Destination.REL_ACCOUNT_DASHBOARD} element={<HomePage />} />
            <Route path={Destination.REL_ACCOUNT_TEMPLATES} element={<TemplatesPage onUpdateTitle={handleTitleUpdate} />} />
            <Route path={Destination.REL_ACCOUNT_TEMPLATES_ID} element={<TemplatePage onUpdateTitle={handleTitleUpdate} />} />
            <Route path={Destination.REL_ACCOUNT_TEMPLATE_PROMOTION_ID} element={<TemplatePromotionScreen />} />
            <Route path={Destination.REL_ACCOUNT_PROFILE_PROMOTION} element={<ProfilePromotionScreen />} />
            <Route path={Destination.REL_ACCOUNT_AUDIENCE} element={<AudiencePage />} />
            <Route path={Destination.REL_ACCOUNT_ANALYTICS} element={<ComingSoon />} />
            <Route path={Destination.REL_ACCOUNT_WISHLIST} element={<WishlistPage />} />
            <Route path={Destination.REL_ACCOUNT_LIBRARY} element={<ComingSoon />} />
            <Route path={Destination.REL_ACCOUNT_SETTINGS} element={<SettingsPage />} />
          </Routes>
        </AccountPage>
      ) : (
        <Navigate to={Destination.LOGIN} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(AccountApp);
