import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { SupportedLocales } from "../utils/supported-locales";

import adminEn from './en/admin.json';
import commonEn from './en/common.json';
import templatesEn from './en/templates.json';
import faqsEn from './en/faqs.json';
import creatorsEn from './en/creators.json';
import categoriesEn from './en/categories.json';
import featuresEn from './en/features.json';
import seoEn from './en/seo.json';

import adminDe from './de/admin.json';
import commonDe from './de/common.json';
import templatesDe from './de/templates.json';
import faqsDe from './de/faqs.json';
import creatorsDe from './de/creators.json';
import categoriesDe from './de/categories.json';
import featuresDe from './de/features.json';
import seoDe from './de/seo.json';

import adminFr from './fr/admin.json';
import commonFr from './fr/common.json';
import templatesFr from './fr/templates.json';
import faqsFr from './fr/faqs.json';
import creatorsFr from './fr/creators.json';
import categoriesFr from './fr/categories.json';
import featuresFr from './fr/features.json';
import seoFr from './fr/seo.json';

import adminJa from './ja/admin.json';
import commonJa from './ja/common.json';
import templatesJa from './ja/templates.json';
import faqsJa from './ja/faqs.json';
import creatorsJa from './ja/creators.json';
import categoriesJa from './ja/categories.json';
import featuresJa from './ja/features.json';
import seoJa from './ja/seo.json';

import adminKo from './ko/admin.json';
import commonKo from './ko/common.json';
import templatesKo from './ko/templates.json';
import faqsKo from './ko/faqs.json';
import creatorsKo from './ko/creators.json';
import categoriesKo from './ko/categories.json';
import featuresKo from './ko/features.json';
import seoKo from './ko/seo.json';

import adminZhCN from './zh-CN/admin.json';
import commonZhCN from './zh-CN/common.json';
import templatesZhCN from './zh-CN/templates.json';
import faqsZhCN from './zh-CN/faqs.json';
import creatorsZhCN from './zh-CN/creators.json';
import categoriesZhCN from './zh-CN/categories.json';
import featuresZhCN from './zh-CN/features.json';
import seoZhCN from './zh-CN/seo.json';

import adminZhTW from './zh-TW/admin.json';
import commonZhTW from './zh-TW/common.json';
import templatesZhTW from './zh-TW/templates.json';
import faqsZhTW from './zh-TW/faqs.json';
import creatorsZhTW from './zh-TW/creators.json';
import categoriesZhTW from './zh-TW/categories.json';
import featuresZhTW from './zh-TW/features.json';
import seoZhTW from './zh-TW/seo.json';

import adminFi from './fi/admin.json';
import commonFi from './fi/common.json';
import templatesFi from './fi/templates.json';
import faqsFi from './fi/faqs.json';
import creatorsFi from './fi/creators.json';
import categoriesFi from './fi/categories.json';
import featuresFi from './fi/features.json';
import seoFi from './fi/seo.json';

import adminDa from './da/admin.json';
import commonDa from './da/common.json';
import templatesDa from './da/templates.json';
import faqsDa from './da/faqs.json';
import creatorsDa from './da/creators.json';
import categoriesDa from './da/categories.json';
import featuresDa from './da/features.json';
import seoDa from './da/seo.json';

import adminNl from './nl/admin.json';
import commonNl from './nl/common.json';
import templatesNl from './nl/templates.json';
import faqsNl from './nl/faqs.json';
import creatorsNl from './nl/creators.json';
import categoriesNl from './nl/categories.json';
import featuresNl from './nl/features.json';
import seoNl from './nl/seo.json';

import adminPtBR from './pt-BR/admin.json';
import commonPtBR from './pt-BR/common.json';
import templatesPtBR from './pt-BR/templates.json';
import faqsPtBR from './pt-BR/faqs.json';
import creatorsPtBR from './pt-BR/creators.json';
import categoriesPtBR from './pt-BR/categories.json';
import featuresPtBR from './pt-BR/features.json';
import seoPtBR from './pt-BR/seo.json';

import adminPtPT from './pt/admin.json';
import commonPtPT from './pt/common.json';
import templatesPtPT from './pt/templates.json';
import faqsPtPT from './pt/faqs.json';
import creatorsPtPT from './pt/creators.json';
import categoriesPtPT from './pt/categories.json';
import featuresPtPT from './pt/features.json';
import seoPtPT from './pt/seo.json';

import adminEs from './es/admin.json';
import commonEs from './es/common.json';
import templatesEs from './es/templates.json';
import faqsEs from './es/faqs.json';
import creatorsEs from './es/creators.json';
import categoriesEs from './es/categories.json';
import featuresEs from './es/features.json';
import seoEs from './es/seo.json';
import adminEs419 from './es-419/admin.json';
import commonEs419 from './es-419/common.json';
import templatesEs419 from './es-419/templates.json';
import faqsEs419 from './es-419/faqs.json';
import creatorsEs419 from './es-419/creators.json';
import categoriesEs419 from './es-419/categories.json';
import featuresEs419 from './es-419/features.json';
import seoEs419 from './es-419/seo.json';



export const supportedWebLanguages = [
    SupportedLocales.ENGLISH_US,
    SupportedLocales.GERMAN,
    // SupportedLocales.RUSSIAN,
    // SupportedLocales.TURKISH,
    SupportedLocales.KOREAN,
    SupportedLocales.JAPANESE,
    SupportedLocales.CHINESE_TRADITIONAL,
    SupportedLocales.CHINESE_SIMPLE,
    SupportedLocales.DANISH,
    SupportedLocales.FINISH,
    // SupportedLocales.VIETNAMESE,
    // SupportedLocales.THAI,
    // SupportedLocales.GREEK,
    SupportedLocales.FRENCH,
    // SupportedLocales.ITALIAN,
    SupportedLocales.PORTUGUESE_BRAZIL,
    SupportedLocales.PORTUGUESE_PORTUGAL,
    SupportedLocales.SPANISH_LATIN_AMERICA,
    SupportedLocales.SPANISH_SPAIN,
    SupportedLocales.DUTCH,
].map(lng => lng.webLanguageKey);

// Supported / Available languages for markets https://en.wikipedia.org/wiki/Notion_(productivity_software)
// Spanish
// Spanish(Latin America)
// Finnish(Beta)
// Danish(Beta)
// Dutch(Beta)
// Norwegian(Beta)
// Swedish(Beta)

export function getLanguagesPath(path: string) {
    return `/:lng${path}`;
};

export function getLanguagesPathBy(path: string, lng: string) {
    const actualLng = lng == '' ? '' : `/${lng}`;
    const isSupported = supportedWebLanguages.includes(actualLng);
    const matchedLngOrDefault = isSupported ? actualLng : 'en';
    return `/${matchedLngOrDefault}${path}`;
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                common: commonEn,
                templates: templatesEn,
                faqs: faqsEn,
                creators: creatorsEn,
                categories: categoriesEn,
                features: featuresEn,
                admin: adminEn,
                seo: seoEn,
            },
            de: {
                common: commonDe,
                templates: templatesDe,
                faqs: faqsDe,
                creators: creatorsDe,
                categories: categoriesDe,
                features: featuresDe,
                admin: adminDe,
                seo: seoDe,
            },
            fr: {
                common: commonFr,
                templates: templatesFr,
                faqs: faqsFr,
                creators: creatorsFr,
                categories: categoriesFr,
                features: featuresFr,
                admin: adminFr,
                seo: seoFr,
            },
            ja: {
                common: commonJa,
                templates: templatesJa,
                faqs: faqsJa,
                creators: creatorsJa,
                categories: categoriesJa,
                features: featuresJa,
                admin: adminJa,
                seo: seoJa,
            },
            ko: {
                common: commonKo,
                templates: templatesKo,
                faqs: faqsKo,
                creators: creatorsKo,
                categories: categoriesKo,
                features: featuresKo,
                admin: adminKo,
                seo: seoKo,
            },
            fi: {
                common: commonFi,
                templates: templatesFi,
                faqs: faqsFi,
                creators: creatorsFi,
                categories: categoriesFi,
                features: featuresFi,
                admin: adminFi,
                seo: seoFi,
            },
            da: {
                common: commonDa,
                templates: templatesDa,
                faqs: faqsDa,
                creators: creatorsDa,
                categories: categoriesDa,
                features: featuresDa,
                admin: adminDa,
                seo: seoDa,
            },
            nl: {
                common: commonNl,
                templates: templatesNl,
                faqs: faqsNl,
                creators: creatorsNl,
                categories: categoriesNl,
                features: featuresNl,
                admin: adminNl,
                seo: seoNl,
            },
            'zh-CN': {
                common: commonZhCN,
                templates: templatesZhCN,
                faqs: faqsZhCN,
                creators: creatorsZhCN,
                categories: categoriesZhCN,
                features: featuresZhCN,
                admin: adminZhCN,
                seo: seoZhCN,
            },
            'zh-TW': {
                common: commonZhTW,
                templates: templatesZhTW,
                faqs: faqsZhTW,
                creators: creatorsZhTW,
                categories: categoriesZhTW,
                features: featuresZhTW,
                admin: adminZhTW,
                seo: seoZhTW,
            },
            'pt-BR': {
                common: commonPtBR,
                templates: templatesPtBR,
                faqs: faqsPtBR,
                creators: creatorsPtBR,
                categories: categoriesPtBR,
                features: featuresPtBR,
                admin: adminPtBR,
                seo: seoPtBR,
            },
            pt: {
                common: commonPtPT,
                templates: templatesPtPT,
                faqs: faqsPtPT,
                creators: creatorsPtPT,
                categories: categoriesPtPT,
                features: featuresPtPT,
                admin: adminPtPT,
                seo: seoPtPT,
            },
            'es-419': {
                common: commonEs419,
                templates: templatesEs419,
                faqs: faqsEs419,
                creators: creatorsEs419,
                categories: categoriesEs419,
                features: featuresEs419,
                admin: adminEs419,
                seo: seoEs419,
            },
            es: {
                common: commonEs,
                templates: templatesEs,
                faqs: faqsEs,
                creators: creatorsEs,
                categories: categoriesEs,
                features: featuresEs,
                admin: adminEs,
                seo: seoEs,
            },
        },
        fallbackLng: 'en',
        supportedLngs: supportedWebLanguages,
        ns: ['common', 'templates', 'faqs', 'creators', 'categories', 'features', 'admin', "seo"],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
        },
        debug: false, // Enable debug mode
        detection: {
            order: ['path', 'localStorage', 'cookie', 'navigator', 'htmlTag'], // Detect language from URL first
            caches: ['localStorage', 'cookie'], // Cache language in cookie
            lookupFromPathIndex: 0,
            cookieMinutes: 43200,
            cookieDomain: 'elcovia.com',
        },
    });

export default i18n;