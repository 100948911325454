import { PlusCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import SettingsGroupComponent from "../../../components/common/settings/SettingsGroupComponent";
import EmptyButtonStateComponent from "../../../components/common/state/EmptyButtonState";
import DragAndDropGridComponent from "../../../components/core/common/DragAndDropGrid";
import ImageItemComponent from "../../../components/template/account/ImageItem";
import { ErrorState } from "./TemplatePage";
import { useTranslation } from "react-i18next";

type TemplateVisualsSectionsProps = {
  thumbnailImage: string;
  coverImages: string[];
  errors: ErrorState;
  fileInputRef: React.RefObject<HTMLInputElement>;
  fileInputCoverImagesRef: React.RefObject<HTMLInputElement>;
  handleInputChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLocalizedInputChange: (key: string, value: string) => void;
  handleLocalizedCoverImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLocalizedThumbnailImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLocalizedCoverImagesUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCoverImagesOrderChange: (order: string[]) => void;
  handleCoverImageDelete: (index: number) => void;
};

const TemplateVisualsSections = ({
  thumbnailImage,
  coverImages,
  errors,
  fileInputRef,
  fileInputCoverImagesRef,
  handleInputChangeEvent,
  handleLocalizedInputChange,
  handleLocalizedCoverImageUpload,
  handleLocalizedThumbnailImageUpload,
  handleLocalizedCoverImagesUpload,
  handleCoverImagesOrderChange,
  handleCoverImageDelete,
}: TemplateVisualsSectionsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SettingsGroupComponent
        name="thumbnail"
        title={t("page_account.page_template.visuals.thumbnail.title")}
        description={t("page_account.page_template.visuals.thumbnail.subtitle")}
        content={
          <>
            <input type="file" accept="image/jpeg, image/png" ref={fileInputRef} onChange={handleLocalizedThumbnailImageUpload} style={{ display: "none" }} />
            <div className="mt-6 w-56">
              {thumbnailImage ? (
                <ImageItemComponent key={thumbnailImage} ratio="1/1" onClick={() => fileInputRef.current?.click()} url={thumbnailImage} />
              ) : (
                <EmptyButtonStateComponent
                  icon={PlusCircleIcon}
                  onClick={() => fileInputRef.current?.click()}
                  visible={true}
                  error={errors.thumbnailImage}
                  text={t("page_account.page_template.visuals.thumbnail.add_thumbnail")}
                  details={t("page_account.page_template.visuals.thumbnail.details")}
                />
              )}
            </div>
          </>
        }
      />
      <SettingsGroupComponent
        name="coverImages"
        title={t("page_account.page_template.visuals.cover_images.title")}
        description={t("page_account.page_template.visuals.cover_images.subtitle")}
        content={
          <>
            <input type="file" accept="image/jpeg, image/png" multiple ref={fileInputCoverImagesRef} onChange={handleLocalizedCoverImageUpload} style={{ display: "none" }} />
            <DragAndDropGridComponent
              items={coverImages}
              className="mt-6 grid grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:gap-y-8 xl:gap-x-8"
              onOrderChange={handleCoverImagesOrderChange}
              addOnView={
                <EmptyButtonStateComponent
                  icon={PlusCircleIcon}
                  onClick={() => fileInputCoverImagesRef.current?.click()}
                  visible={coverImages.length < 6}
                  error={errors.coverImages}
                  text={t("page_account.page_template.visuals.cover_images.add_cover")}
                  details={t("page_account.page_template.visuals.cover_images.details")}
                />
              }
              childItem={(index, coverImage) => (
                <ImageItemComponent
                  key={index}
                  options={[
                    {
                      name: t("commo:delete"),
                      onClick: () => {
                        handleCoverImageDelete(index);
                      },
                      icon: TrashIcon,
                    },
                  ]}
                  ratio="16/9"
                  url={coverImage}
                />
              )}
            />
          </>
        }
      />
    </>
  );
};

export default TemplateVisualsSections;
