// In your LinksFooter component file
import React from "react";

import FooterLinkComponent, { FooterSections } from "./FooterLinkComponent";
import { ExternalLinks, AffiliateLinks } from "../../../utils/constants";
import { Destination } from "../../../utils/constants-navigation";
import { useTranslation } from "react-i18next";

interface FooterLinksComponentProps {}

const FooterLinksComponent: React.FC<FooterLinksComponentProps> = () => {
  const sections: FooterSections = {
    sections: [
      {
        title: "common:notion_content",
        links: [
          { href: Destination.DISCOVER, text: "common:discover" },
          {
            href: ExternalLinks.ELCOVIA_GTP,
            text: "common:discover_via_elcovia_gpt",
            newTab: true,
          },
          { href: Destination.TEMPLATES, text: "common:notion_templates" },
          {
            href: Destination.TEMPLATES_CATEGORIES,
            text: "common:template_categories",
          },
          { href: Destination.USERS, text: "common:notion_creators" },
        ],
      },
      {
        title: "common:notion_links",
        links: [
          { href: AffiliateLinks.NOTION, newTab: true, text: "common:notion" },
          {
            href: AffiliateLinks.NOTION_AI,
            newTab: true,
            text: "common:notion_ai",
          },
          {
            href: AffiliateLinks.NOTION_PROJECTS,
            newTab: true,
            text: "common:notion_projects",
          },
        ],
      },
      {
        title: "common:notion_creators",
        links: [
          {
            href: Destination.LOGIN,
            text: "common:login",
          },
          {
            href: Destination.ACCOUNT_DASHBOARD,
            text: "common:creator_dashboard",
          },
        ],
      },
      {
        title: "Elcovia",
        links: [
          { href: Destination.APPS, text: "common:elcovia_apps" },
          { href: Destination.CONTACT, text: "common:contact" },
          { href: Destination.FAQS_NOTION, text: "common:faqs_notion" },
          { href: Destination.FAQS_ELCOVIA, text: "common:faqs_elcovia" },
        ],
      },
    ],
  };

  return (
    <div className="w-full">
      <div className="mx-auto grid grid-cols-2 gap-8 lg:grid-cols-4 lg:gap-y-16">
        {sections.sections.map((section) => (
          <FooterLinkComponent key={section.title} section={section} />
        ))}
      </div>
    </div>
  );
};

export default FooterLinksComponent;
