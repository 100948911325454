type HeaderItemProps = {
  title: React.ReactNode;
  desc?: React.ReactNode;
  titleClassName?: string; // Allow overriding class for <h1>
  descClassName?: string; // Allow overriding class for <p>
};

const HeaderH1LargeItem: React.FC<HeaderItemProps> = ({
  title,
  desc,
  titleClassName,
  descClassName,
}) => {
  return (
    <div className="max-w-8xl">
      <h1
        id="header-title"
        className={`text-5xl lg:text-7xl font-bold tracking-tight text-gray-900 leading-[1.15] lg:leading-[1.15] ${titleClassName}`}
      >
        {title}
      </h1>
      {desc && (
        <p
          className={`mt-6 font-normal text-lg text-gray-600 leading-7 ${descClassName}`}
        >
          {desc}
        </p>
      )}
    </div>
  );
};

export default HeaderH1LargeItem;
