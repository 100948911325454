import { TFunction } from 'i18next';
import { SortOption, TimeOption } from '../../../../../utils/utils-filter';
import { Headline } from './Headline';

export function getTemplatesHeadline(
    t: TFunction,
    sort: SortOption,
    timeOption: TimeOption,
    filterKey: string,
    category: string | null
): Headline {
    console.log("getSpecificHeadline", sort, timeOption, filterKey, category);

    const timeSentenceKey = `sort_${sort.key}_time_${timeOption.key}`;
    const timeSentence = t(`templates:timeSentenceOptions.${timeSentenceKey}`);

    const titles: { [key: string]: string } = {
        all: t('templates:titles.all'),
        free: t('templates:titles.free'),
        paid: t('templates:titles.paid'),
        featured: t('templates:titles.featured'),
    };

    const descriptions: { [key: string]: string } = {
        all: category
            ? t('templates:descriptions.allWithCategory', { category: t(category) })
            : t('templates:descriptions.allWithoutCategory'),
        free: category
            ? t('templates:descriptions.freeWithCategory', { category: t(category) })
            : t('templates:descriptions.freeWithoutCategory'),
        paid: category
            ? t('templates:descriptions.paidWithCategory', { category: t(category) })
            : t('templates:descriptions.paidWithoutCategory'),
        featured: category
            ? t('templates:descriptions.featuredWithCategory', { category: t(category) })
            : t('templates:descriptions.featuredWithoutCategory'),
    };

    const title = titles[filterKey] || t('templates:titles.default');
    const description = descriptions[filterKey] || t('templates:descriptions.default');

    return {
        title,
        description: description + " " + timeSentence,
    };
}