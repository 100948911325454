import React from "react";
import { Link } from "react-router-dom";

import { Profile } from "../../models/Profile";
import { ARGS_PARAM_DISCOVER, Destination, setPathId, setPathIdAndAppend } from "../../utils/constants-navigation";
import { getProfileId } from "../../utils/utils-profile";
import ProfileStatisticItemComponent from "./ProfileStatisticsItem";
import { CheckBadgeIcon, AcademicCapIcon, SparklesIcon } from "@heroicons/react/20/solid";
import CustomLink from "../common/links/CustomLink";

interface TemplateProps {
  itemKey?: string;
  discover?: boolean;
  profile: Profile;
}

const ProfileSmallItemComponent: React.FC<TemplateProps> = ({ itemKey, discover = false, profile }) => {
  return (
    <CustomLink to={setPathIdAndAppend(Destination.USER_ID_SHORT, getProfileId(profile), discover ? ARGS_PARAM_DISCOVER : undefined)} key={itemKey + "-" + profile.id}>
      <div className="transition-all ease-in-out duration-300 group relative bg-white divide-y divide-solid divide-gray-200/50 shadow-sm rounded-lg border border-1 border-gray-200/50 hover:shadow-md hover:md:scale-[1.04]">
        <div className="flex items-center">
          <div className="bg-indigo-500/[0.025] flex px-4 items-center border-r">
            <img src={profile.image} alt={profile.name || ""} className="my-3 aspect-[1/1] w-12 rounded-2xl shadow-md" />
          </div>
          <div className="px-3 py-3">
            <h3 className="text-md font-semibold text-gray-800 line-clamp-1">{profile.name}</h3>
            <div className="flex gap-x-2 items-center">
              {profile.profileId && <p className="text-xs font-medium text-gray-600 line-clamp-1">@{profile.profileId}</p>}
              {(profile.verified || profile.notionCertified || profile.promotion) && (
                <p className="mt-1">
                  {profile.verified == true && <CheckBadgeIcon className="text-indigo-600 h-4 w-4" />}
                  {profile.notionCertified == true && <AcademicCapIcon className="text-gray-600 h-4 w-4" />}
                  {profile.promotion != null && <SparklesIcon className="text-gray-600 h-4 w-4" />}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </CustomLink>
  );
};

export default ProfileSmallItemComponent;
