import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Logger } from "../../../utils/utils-logging";
import { useNavigate, useLocation } from "react-router-dom";
import { LocaleDropDownComponent } from "../../common/dropdowns/LocaleDropDown";
import {
  DEFAULT_LOCALE,
  LocaleKey,
  localeKey,
  SupportedLocale,
  SupportedLocales,
} from "../../../utils/supported-locales";
import { supportedWebLanguages } from "../../../languages/i18n";

const logger = new Logger("LanguageSwitcher");

type LanguageSwitcherProps = {
  isSmall?: boolean;
};

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ isSmall = false }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [locale, setLocale] = useState<SupportedLocale>(getLocaleByWebLanguageKey(i18n.language));
  const [localeKeyValue, setLocaleKeyValue] = useState<LocaleKey>(localeKey(locale));

  function getLocaleByWebLanguageKey(webLanguageKey: string): SupportedLocale {
    logger.log(`Getting locale by web language key ${webLanguageKey}`);
    return Object.values(SupportedLocales).find((locale) => locale.webLanguageKey === webLanguageKey) || DEFAULT_LOCALE;
  }

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    const regexSupportedLngs = new RegExp(`^/(${supportedWebLanguages.join("|")})`);
    const currentPath = location.pathname.replace(regexSupportedLngs, ""); // Remove current language
    const newPath = lng === "en" ? currentPath : `/${lng}${currentPath}`;
    i18n.changeLanguage(lng);
    navigate(newPath);
    logger.log(`Changed language to ${lng}`);
  };

  const onUpdateLocale = (locale: SupportedLocale) => {
    setLocale(locale);
    setLocaleKeyValue(localeKey(locale));
    changeLanguage(locale.webLanguageKey);
    logger.log(`onUpdateLocale: ${localeKey(locale)}`);
  };

  useEffect(() => {
    const regexSupportedLngs = new RegExp(`^/(${supportedWebLanguages.join("|")})`);
    const currentPath = location.pathname.replace(regexSupportedLngs, ""); // Remove current language
    const currentLng = currentPath.split("/")[1];
    const currentLngOrDefault = currentLng || "en";
    setLocale(getLocaleByWebLanguageKey(currentLngOrDefault));
    setLocaleKeyValue(localeKey(locale));
  }, [i18n.language]);

  return (
    <div>
      <LocaleDropDownComponent
        isSmall={isSmall}
        currentLocaleKey={localeKeyValue}
        locales={Object.values(SupportedLocales)
          .filter((locale) => supportedWebLanguages.includes(locale.webLanguageKey))
          .map((locale) => localeKey(locale))}
        onSelectLocale={(newlocaleKey) => {
          logger.log(`onSelectLocale: ${localeKey}`);
          const locale =
            Object.values(SupportedLocales).find((locale) => localeKey(locale) === newlocaleKey) || DEFAULT_LOCALE;
          onUpdateLocale(locale);
        }}
      />
    </div>
  );
};

export default LanguageSwitcher;
