import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import React from "react";

import {
  DEFAULT_LOCALE,
  getLocaleDisplayNameLanguage,
  getLocaleDisplayNameLanguageByKey,
  LocaleKey,
  localeKey,
  SupportedLocale,
  SupportedLocales,
} from "../../../utils/supported-locales";
import { classNames } from "../../../utils/utils-react";

/**
 * The props for the LocaleDropDown component.
 * @param className - The className to apply to the dropdown
 * @param currentLocale - The current locale
 * @param locales - The locales to display
 * @param onSelectLocale - The callback to call when a locale is selected
 */
interface LocaleDropdownProps {
  className?: string;
  currentLocaleKey: LocaleKey;
  locales: LocaleKey[];
  onSelectLocale: (locale: LocaleKey) => void;
  isSmall?: boolean;
}

export const LocaleDropDownComponent: React.FC<LocaleDropdownProps> = ({ className, currentLocaleKey, locales, onSelectLocale, isSmall = false }) => {
  const [richLocales, setRichLocales] = React.useState<SupportedLocale[]>(getInitialLocales(currentLocaleKey));
  const [currentLocale, setCurrentLocale] = React.useState<SupportedLocale>(
    Object.values(SupportedLocales).find((locale) => localeKey(locale) === currentLocaleKey) || DEFAULT_LOCALE
  );

  // Function to get initial locales excluding the current one
  function getInitialLocales(currentLocaleKey: LocaleKey): SupportedLocale[] {
    const localesWithoutCurrent = locales.filter((locale) => locale !== currentLocaleKey);
    return localesWithoutCurrent.map((locale) => Object.values(SupportedLocales).find((l) => localeKey(l) === locale)) as SupportedLocale[];
  }

  // When the currentLocaleKey changes, update the currentLocale and available locales
  React.useEffect(() => {
    const newCurrentLocale = Object.values(SupportedLocales).find((locale) => localeKey(locale) === currentLocaleKey) || DEFAULT_LOCALE;
    setCurrentLocale(newCurrentLocale);
    setRichLocales(getInitialLocales(currentLocaleKey));
  }, [currentLocaleKey, locales]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className={className}>
        {isSmall ? (
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md p-3 bg-gray-100 hover:bg-gray-200 items-center">
            <img
              key={currentLocale.iso3166Alpha2}
              className="h-4"
              src={`https://flagcdn.com/84x63/${currentLocale.iso3166Alpha2.toLowerCase()}.png`}
              alt={currentLocale.iso3166Alpha2.toLowerCase()}
            />
          </Menu.Button>
        ) : (
          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-4 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center gap-x-2">
            <img
              key={currentLocale.iso3166Alpha2}
              className="h-4"
              src={`https://flagcdn.com/84x63/${currentLocale.iso3166Alpha2.toLowerCase()}.png`}
              alt={currentLocale.iso3166Alpha2.toLowerCase()}
            />
            {getLocaleDisplayNameLanguageByKey(currentLocaleKey)}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Menu.Button>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-8 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[300px] overflow-y-auto lg:w-[500px]">
          <div className="py-1 divide-y divide-gray-100 lg:grid lg:grid-cols-2 lg:gap-x-4 ">
            {richLocales.map((locale) => (
              <Menu.Item key={localeKey(locale)}>
                {({ active }) => (
                  <a
                    onClick={() => onSelectLocale(localeKey(locale))}
                    className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm flex items-center gap-x-3 py-3 cursor-pointer")}
                  >
                    <img className="h-4" src={`https://flagcdn.com/84x63/${locale.iso3166Alpha2.toLowerCase()}.png`} alt={locale.webLanguageKey} />
                    {`${getLocaleDisplayNameLanguage(locale)} (${localeKey(locale)})`}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
