import { TvIcon } from '@heroicons/react/20/solid';

import { AspectRatio } from '../../../utils/constants';
import { classNames } from '../../../utils/utils-react';

type EmptyButtonStateProps = {
  icon: React.FC;
  text: string;
  description?: string;
  details?: string;
  onClick: () => void;
  ratio?: AspectRatio;
  visible?: boolean;
  error?: boolean;
};

function EmptyButtonStateComponent({
  icon,
  text,
  description,
  details,
  onClick: action,
  ratio,
  visible,
  error,
}: EmptyButtonStateProps) {
  if (visible == false) return null;
  const DynamicIcon = icon;
  return (
    <button
      type="button"
      className={classNames(
        error
          ? "bg-red-100 text-red-700 border"
          : "bg-gray-100 hover:bg-gray-200",
        ratio ? `aspect-[${ratio}]` : "",
        "transition-all ease-in-out duration-300 relative block w-full rounded-lg p-12 text-center"
      )}
      onClick={action}
    >
      <div className="mx-auto h-6 w-6 text-gray-500">{<DynamicIcon />}</div>
      <span className="mt-4 block text-md font-semibold text-gray-900">
        {text}
      </span>
      <span className="mt-1 block text-sm leading-6 font-normal text-gray-500">
        {description}
      </span>
      <span className="mt-1 block text-xs leading-6 font-normal text-gray-500">
        {details}
      </span>
    </button>
  );
}

export default EmptyButtonStateComponent;
