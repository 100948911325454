import { useTranslation } from "react-i18next";

import { removeLanguageCode } from "./CustomNavLink";

type LinkProps = {
  to: string;
  children?: React.ReactNode;
  className?: string;
  target?: string;
  rel?: string;
  isExternal?: boolean;
  onClick?: () => void;
};

const CustomLink: React.FC<LinkProps> = ({ to, children, className, target, rel, isExternal = false, onClick }) => {
  const isActive = removeLanguageCode(window.location.pathname) === removeLanguageCode(to.toString());
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language
  const languageAwareTo = currentLanguage === "en" ? to : `/${currentLanguage}${to}`;
  const actualTo = isExternal ? to : languageAwareTo;
  return (
    <a href={actualTo} className={className} target={target} rel={rel} onClick={onClick}>
      {children}
    </a>
  );
};

export default CustomLink;
