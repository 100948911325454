import React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Profile } from "../../models/Profile";
import { Template } from "../../models/Template";
import { getFeaturedTemplates, getFeaturedUsers } from "../../services/firestore-service";
import { FeatureTemplateItemComponent } from "./FeatureTemplateItem";
import { FeatureProfileItemComponent } from "./FeatureProfileItem";
import { useTranslation } from "react-i18next";

SwiperCore.use([Navigation, Pagination, Autoplay]);

/**
 * Route paths used in the application.
 */
export const FeatureSectionType = {
  TEMPLATES: "templates",
  USERS: "users",
  ALL: "all",
} as const;

type FeatureSectionParams = {
  type?: (typeof FeatureSectionType)[keyof typeof FeatureSectionType];
};

export const FeatureSection: React.FC<FeatureSectionParams> = ({ type }) => {
  const { t } = useTranslation();
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [users, setUsers] = React.useState<Profile[]>([]);

  React.useEffect(() => {
    const loadTemplates = async () => {
      const templates = await getFeaturedTemplates();
      if (templates.length > 5) {
        setTemplates(templates);
      }
    };
    const loadUsers = async () => {
      const users = await getFeaturedUsers();
      if (users.length > 5) {
        setUsers(users);
      }
    };
    if (!type) type = FeatureSectionType.ALL;
    switch (type) {
      case FeatureSectionType.TEMPLATES:
        loadTemplates();
        break;
      case FeatureSectionType.USERS:
        loadUsers();
        break;
      case FeatureSectionType.ALL:
        loadTemplates();
        loadUsers();
        break;
    }
  }, [type]);

  if (!templates && !users) return null;
  return (
    <>
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        {templates && templates.length > 0 && (
          <div>
            <h1 className="text-1xl font-bold tracking-tight text-gray-800">{t("featured_templates")}</h1>
            <Swiper
              effect="fade"
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 8000 }}
              aria-rowcount={2}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  grid: {
                    rows: 2,
                  },
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                  grid: {
                    rows: 2,
                  },
                },
              }}
            >
              {templates.map((template) => (
                <SwiperSlide key={template.id}>
                  <div className="m-1 p-4 max-w-xl">
                    <FeatureTemplateItemComponent template={template} itemKey={`feature_section-${template.id}`} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {users && users.length > 0 && (
          <div>
            <h1 className="text-1xl font-bold tracking-tight text-gray-800">{t("featured_creators")}</h1>
            <Swiper
              effect="fade"
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 8000 }}
              aria-rowcount={2}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  grid: {
                    rows: 2,
                  },
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                  grid: {
                    rows: 2,
                  },
                },
              }}
            >
              {users.map((user) => (
                <SwiperSlide key={user.id}>
                  <div className="m-1 p-4 max-w-xl">
                    <FeatureProfileItemComponent profile={user} itemKey={`feature_section-${user.id}`} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </>
  );
};
