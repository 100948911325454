import React from "react";
import { Trans, useTranslation } from "react-i18next";

import SEOComponent, { SEOPage } from "../../../../components/common/seo/SEOComponent";
import { Destination } from "../../../../utils/constants-navigation";
import { FAQ } from "./FAQ";
import { FAQGroupItem } from "./FAQGroupItem";

export default function FAQsNotionPage() {
  const { t } = useTranslation();
  const faqs: FAQ[] = t("faqs:page_faqs_notion.faqs", { returnObjects: true });
  const faqsChunked = faqs.map((_, i) => faqs.slice(i * (faqs.length / 2), (i + 1) * (faqs.length / 2)));

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("faqs:main_pages.page_faqs_notion.title") + t("faqs:tabs.title_suffix"),
            t("faqs:main_pages.page_faqs_notion.description")
          )
        }
      />
      <div className="mx-auto w-full">
        <div className="flex gap-x-16 items-center justify-center">
          <div>
            <h2 className="text-5xl font-bold tracking-tight text-gray-900 leading-snug">
              <Trans
                i18nKey="faqs:page_faqs_notion.title"
                components={{
                  span: <span className="text-indigo-600" />,
                }}
              />
            </h2>
            <p className="mt-6 text-lg leading-7 text-gray-600">
              <Trans
                i18nKey="faqs:page_faqs_notion.subtitle"
                components={{
                  url: <a href={Destination.CONTACT} className="font-semibold text-indigo-600 hover:text-indigo-500" />,
                }}
              />
            </p>
          </div>
          <embed src="/illustrations/notioly/community.svg" className="h-80" />
        </div>
        <FAQGroupItem faqsChunked={faqsChunked} />
      </div>
    </>
  );
}
