import _ from "lodash";
import PeriodId from "./utils-period";


export interface TimeOption {
  key: TimeKey;
  name: string;
  periodId: PeriodId;
  current: boolean;
  default?: boolean;
}

export enum TimeKey {
  TODAY = "today",
  THIS_WEEK = "week",
  THIS_MONTH = "month",
  THIS_YEAR = "year",
  ALL_TIME = "total",
}

export const defaultTimeOptions: TimeOption[] = [
  // {
  //   name: "Today",
  //   periodId: PeriodId.day(),
  //   current: false,
  //   key: TimeKey.TODAY,
  // },
  {
    name: "common:filters.week",
    periodId: PeriodId.week(),
    current: false,
    key: TimeKey.THIS_WEEK,
  },
  {
    name: "common:filters.month",
    periodId: PeriodId.month(),
    current: false,
    key: TimeKey.THIS_MONTH,
  },
  {
    name: "common:filters.year",
    periodId: PeriodId.year(),
    current: false,
    key: TimeKey.THIS_YEAR,
  },
  {
    name: "common:filters.all_time",
    periodId: PeriodId.total(),
    current: false,
    key: TimeKey.ALL_TIME,
    default: true,
  },
];

export interface SortOption {
  key: SortKey;
  name: string;
  current: boolean;
  default?: boolean;
}

export enum SortKey {
  LIKES = "likes",
  VIEWS = "views",
  DOWNLOADS = "downloads",
  LATEST = "latest",
}

export const defaultSortOptions: SortOption[] = [
  {
    name: "common:filters.most_likes",
    current: false,
    key: SortKey.LIKES,
    default: false,
  },
  {
    name: "common:filters.most_views",
    current: false,
    key: SortKey.VIEWS,
  },
  {
    name: "common:filters.most_downloads",
    current: false,
    key: SortKey.DOWNLOADS,
  },
  {
    name: "common:filters.newest",
    current: false,
    key: SortKey.LATEST,
  },
];

/**
 * Returns the default time options for templates.
 * @returns {TimeOption[]} The default time options for templates.
 */
export function getDefaultTemplatesSortOptions(): SortOption[] {
  const options = _.cloneDeep(defaultSortOptions)
  options[1].current = true
  return options;
}

/**
 * Returns the default time options for templates.
 * @returns {TimeOption[]} The default time options for templates.
 */
export function getDefaultTemplatesTimeOptions(): TimeOption[] {
  const options = _.cloneDeep(defaultTimeOptions)
  options[2].current = true
  return options;
}

/**
 * Returns the default time options for profiles.
 * @returns {TimeOption[]} The default time options for profiles.
 */
export function getDefaultProfilesSortOptions(): SortOption[] {
  const options = _.cloneDeep(defaultSortOptions)
  options[1].current = true
  return options;
}

/**
 * Returns the default time options for profiles.
 * @returns {TimeOption[]} The default time options for profiles.
 */
export function getDefaultProfilesTimeOptions(): TimeOption[] {
  const options = _.cloneDeep(defaultTimeOptions)
  options[2].current = true
  return options;
}