import { Action } from "redux";
import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import SettingsHeaderComponent from "../../../components/common/settings/SettingsHeaderComponent";
import SettingsSplitGroupComponent from "../../../components/common/settings/SettingsSplitGroupComponent";
import { Template } from "../../../models/Template";
import { LocaleKey } from "../../../utils/supported-locales";
import { ErrorState } from "./TemplatePage";
import { useTranslation } from "react-i18next";
import CustomLink from "../../../components/common/links/CustomLink";
import { setPathId, Destination } from "../../../utils/constants-navigation";
import { copyToClipboard } from "../../../utils/utils-clipboard";
import { getLanguagesPathBy } from "../../../languages/i18n";
import { Logger } from "../../../utils/utils-logging";
import { useLocation } from "react-router-dom";

type TemplateActionsSectionProps = {
  template: Template;
  hasChanges: boolean;
  localeErrors: Map<LocaleKey, ErrorState>;
  setOpenPublishConfirmationDialog: (open: boolean) => void;
  setOpenDeleteConfirmationDialog: (open: boolean) => void;
};

export const TemplateActionsSection = ({ template, hasChanges, localeErrors, setOpenPublishConfirmationDialog, setOpenDeleteConfirmationDialog }: TemplateActionsSectionProps) => {
  const logger = new Logger("TemplateActionsSection");
  const { t, i18n } = useTranslation();
  const origin = window.location.origin;

  const productUrlPath = setPathId(Destination.TEMPLATE_ID, template.id);
  const previewUrlPath = setPathId(Destination.TEMPLATE_PREVIEW_ID, template.id);

  const productUrlDisplay = origin + getLanguagesPathBy(productUrlPath, i18n.language);
  const previewUrlDisplay = origin + getLanguagesPathBy(previewUrlPath, i18n.language);

  const copyProductUrl = () => {
    logger.log("copyProductUrl", { productUrl: productUrlPath });
    copyToClipboard(productUrlDisplay);
  };

  return (
    <>
      <SettingsHeaderComponent
        title={t("page_account.page_template.actions.info_center.title")}
        description={t("page_account.page_template.actions.info_center.subtitle")}
        content={
          <>
            <SettingsSplitGroupComponent
              name="productLink"
              title={t("page_account.page_template.actions.product_link.title")}
              description={
                template.isDraft ? t("page_account.page_template.actions.product_link.subtitle_draft") : t("page_account.page_template.actions.product_link.subtitle_published")
              }
              postDescriptionChildren={
                <CustomLink className="mt-3 text-xs text-indigo-500" to={productUrlPath} target="_blank">
                  {productUrlDisplay}
                </CustomLink>
              }
              content={<ButtonComponent style="structural" onClick={() => copyProductUrl()} text={t("common:copy")} />}
            />

            {template.isDraft && (
              <SettingsSplitGroupComponent
                name="previewTemplate"
                title={t("page_account.page_template.actions.preview_template.title")}
                description={t("page_account.page_template.actions.preview_template.subtitle")}
                postDescriptionChildren={
                  <>
                    <CustomLink className="mt-3 text-xs text-indigo-500" to={previewUrlPath} target="_blank">
                      {previewUrlDisplay}
                    </CustomLink>
                  </>
                }
                content={
                  <>
                    <ButtonComponent style="structural" target="_blank" href={previewUrlPath} text={template.isDraft ? t("common:preview") : t("common:view")} />
                  </>
                }
              />
            )}
          </>
        }
      />

      <SettingsHeaderComponent
        title={t("page_account.page_template.actions.control_center.title")}
        description={t("page_account.page_template.actions.control_center.description")}
        content={
          <>
            {template.isDraft && !hasChanges && Array.from(localeErrors.values()).every((e) => !e) && (
              <SettingsSplitGroupComponent
                name="publishTemplate"
                title={t("page_account.page_template.actions.publish_template.title")}
                description={t("page_account.page_template.actions.publish_template.description")}
                content={<ButtonComponent style="colored" onClick={() => setOpenPublishConfirmationDialog(true)} text={t("common:publish")} />}
              />
            )}

            <SettingsSplitGroupComponent
              name="deleteTemplate"
              title={t("page_account.page_template.actions.delete_template.title")}
              description={t("page_account.page_template.actions.delete_template.description")}
              content={<ButtonComponent style="warning" onClick={() => setOpenDeleteConfirmationDialog(true)} text={t("common:delete")} />}
            />
          </>
        }
      />
    </>
  );
};
