import { template, delay } from "lodash";
import { Link } from "react-router-dom";
import Swiper, { EffectCreative, Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";
import HtmlShortTextComponent from "../../../../components/common/html/preview_short/HtmlShortTextComponent";
import TemplatesStatusltem from "../../../../components/template/TemplatesStatusltem";
import { CategoryType } from "../../../../models/Category";
import { setPathIdAndAppend, Destination, ARGS_PARAM_DISCOVER, setPathAndAppend, Args } from "../../../../utils/constants-navigation";
import { localizedValue } from "../../../../utils/supported-locales";
import { DiscoverCounterType } from "../../../../models/Discover";
import { useTranslation } from "react-i18next";
import CustomLink from "../../../../components/common/links/CustomLink";

interface TemplateCategoryItemProps {
  category: CategoryType;
}

const TemplateCategoryItem: React.FC<TemplateCategoryItemProps> = ({ category }) => {
  const { t } = useTranslation();
  return (
    <CustomLink
      to={setPathAndAppend(Destination.TEMPLATES, {
        [Args.CATEGORY]: encodeURIComponent(category.title.toLowerCase()),
        [Args.TIME]: "total",
        [Args.SORT]: DiscoverCounterType.VIEWS,
      })}
      key={category.key}
    >
      <div className="transition-all ease-in-out duration-300 group relative bg-white divide-y divide-solid divide-gray-200/50 shadow-sm rounded-lg border border-1 border-gray-200/50 hover:shadow-md hover:md:scale-[1.04]">
        <div className="overflow-hidden bg-indigo-500/[0.025] w-full max-h-40">
          <img
            src={category.image}
            className="max-h-40 object-center h-full w-full bg-white rounded-lg transition-all ease-in-out duration-300 rounded-none group-hover:rounded-md group-hover:drop-shadow group-hover:md:scale-[0.85]"
          />
        </div>
        <div>
          <div className="p-4">
            <div className="flex items-center">
              <p className="text-lg font-semibold text-gray-900 line-clamp-2">{t(category.title)}</p>
            </div>
            <p className="mt-1 text-sm text-gray-600 leading-6 line-clamp-3">{t(category.desc)}</p>
          </div>
        </div>
      </div>
    </CustomLink>
  );
};

export default TemplateCategoryItem;
