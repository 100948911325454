import React from "react";
import { signupToMailchimpList } from "../../services/functions-service";
import { Destination } from "../../utils/constants-navigation";
import TextFieldComponent from "../common/input/TextFieldComponent";
import ButtonComponent from "../common/buttons/ButtonComponent";
import { Trans, useTranslation } from "react-i18next";

export default function NewsletterComponent() {
  const { t } = useTranslation();
  const [sucess, setSucess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  /**
   * Handle form submit event.
   */
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setError(false);
    setSucess(false);
    try {
      await signupToMailchimpList(name, email);
      setError(false);
      setSucess(true);
    } catch (error) {
      console.error("Subscription failed", error);
      setError(true);
      setSucess(false);
    }
  };

  return (
    <div className="w-full transition-all ease-in-out duration-300 group relative bg-white rounded-lg border-t border-1 border-gray-200 px-8 py-12">
      <div className="mx-auto max-w-7xl ">
        <h2 className="text-3xl font-bold text-gray-900">
          {t("common:page_newsletter.title")}
        </h2>
        <p className="mt-4 text-md font-normal text-gray-600 max-w-4xl leading-7">
          {t("common:page_newsletter.subtitle")}
        </p>
        <div className="w-full max-w-lg mt-6" />
        {sucess ? (
          <p className="mt-6 text-md font-normal text-gray-600">
            <Trans
              i18nKey="common:page_newsletter.success"
              components={{
                span: <span className="text-indigo-500" />,
              }}
            />
          </p>
        ) : (
          <form className="mt-10 max-w-3xl" onSubmit={handleSubmit}>
            <div className="flex gap-x-4">
              <label htmlFor="name" className="sr-only">
                {t("common:page_newsletter.name")}
              </label>
              <TextFieldComponent
                id="name"
                name="name"
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t("common:page_newsletter.name")}
              />
              <label htmlFor="email-address" className="sr-only">
                {t("common:page_newsletter.email")}
              </label>
              <TextFieldComponent
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("common:page_newsletter.email")}
              />
              <ButtonComponent
                style="structural"
                type="submit"
                text={t("common:page_newsletter.subscribe")}
              />
            </div>
            {error && (
              <p className="mt-6 text-md font-normal text-red-500">
                {t("common:page_newsletter.error")}
              </p>
            )}

            <p className="mt-4 text-xs font-normal text-gray-500">
              <Trans
                i18nKey="common:page_newsletter.privacy_policy"
                components={{
                  a: (
                    <a
                      href={Destination.PRIVACY_POLICY}
                      className="text-indigo-600"
                    />
                  ),
                }}
              />
            </p>
          </form>
        )}
      </div>
    </div>
  );
}
