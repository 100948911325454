import React from "react";
import { Trans, useTranslation } from "react-i18next";

import SEOComponent, { SEOPage } from "../../../../components/common/seo/SEOComponent";
import { supportedPaymentPlatforms } from "../../../../models/data/PaymentPlatform";
import { Destination } from "../../../../utils/constants-navigation";
import { getLocaleDisplayName, SupportedLocales } from "../../../../utils/supported-locales";
import { FAQGroupItem } from "./FAQGroupItem";

export default function FAQsElcoviaPage() {
  const { t } = useTranslation();

  /**
   * Helper function to create a link
   * @param info - URL and text separated by a pipe (|)
   * @returns - HTML link
   */
  function link(info: string): string {
    const [url, text] = info.split("|");
    return `<i><a href='${url}' target='_blank' class='text-indigo-500' rel='noopener noreferrer'>${text}</a></i>`;
  }

  // Define your replacements
  const replacements = {
    link_login: link("/login|Login"),
    link_dashboard: link("/account|Dashboard"),
    supported_locales: Object.values(SupportedLocales)
      .map((locale) => getLocaleDisplayName(locale))
      .join(", "),
    supported_payment_platforms: Object.values(supportedPaymentPlatforms)
      .map((platform) => platform.name)
      .join(", "),
    link_stripe: link("https://stripe.com|Stripe"),
  };

  /**
   * Function to replace placeholders with actual values
   */
  const replacePlaceholders = (text: string, replacements: Record<string, string>): string => {
    return text.replace(/{{(.*?)}}/g, (_, key) => replacements[key] || "");
  };

  // Fetch FAQs and replace placeholders
  const faqs = (
    t("faqs:page_faqs_elcovia.faqs", { returnObjects: true }) as Array<{
      question: string;
      answer: string;
    }>
  ).map((faq) => ({
    ...faq,
    question: replacePlaceholders(faq.question, replacements),
    answer: replacePlaceholders(faq.answer, replacements),
  }));

  // Split intwo two columns
  const faqsChunked = faqs.map((_, i) => faqs.slice(i * (faqs.length / 2), (i + 1) * (faqs.length / 2)));

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(t("seo:main_pages.page_faqs_elcovia.title"), t("seo:main_pages.page_faqs_elcovia.description"))
        }
      />
      <section className="mt-12 py-12">
        <div className="mx-auto">
          <div className="flex gap-x-16 items-center justify-center">
            <div>
              <h2 className="text-5xl font-bold tracking-tight text-gray-900 leading-snug">
                <Trans
                  i18nKey="faqs:page_faqs_elcovia.title"
                  components={{
                    span: <span className="text-indigo-600" />,
                  }}
                />
              </h2>
              <p className="mt-6 text-lg leading-7 text-gray-600">
                <Trans
                  i18nKey="faqs:page_faqs_elcovia.subtitle"
                  components={{
                    url: (
                      <a href={Destination.CONTACT} className="font-semibold text-indigo-600 hover:text-indigo-500" />
                    ),
                  }}
                />
              </p>
            </div>
            <embed src="/illustrations/notioly/community.svg" className="h-80" />
          </div>
          <FAQGroupItem faqsChunked={faqsChunked} />
        </div>
      </section>
    </>
  );
}
