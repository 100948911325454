import {
  ArrowDownTrayIcon,
  FunnelIcon,
  MagnifyingGlassCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";

export default function AppsPage() {
  const { t } = useTranslation();
  const features = [
    {
      title: t("common:page_apps.features.discover_weekly.title"),
      description: t("common:page_apps.features.discover_weekly.description"),
      icon: SparklesIcon,
    },
    {
      title: t("common:page_apps.features.notion_templates.title"),
      description: t("common:page_apps.features.notion_templates.description"),
      icon: MagnifyingGlassCircleIcon,
    },
    {
      title: t("common:page_apps.features.free_paid_templates.title"),
      description: t("common:page_apps.features.free_paid_templates.description"),
      icon: FunnelIcon,
    },
    {
      title: t("common:page_apps.features.categories_galore.title"),
      description: t("common:page_apps.features.categories_galore.description"),
      icon: Square2StackIcon,
    },
    {
      title: t("common:page_apps.features.notion_enthusiasts.title"),
      description: t("common:page_apps.features.notion_enthusiasts.description"),
      icon: UserGroupIcon,
    },
    {
      title: t("common:page_apps.features.easy_checkout.title"),
      description: t("common:page_apps.features.easy_checkout.description"),
      icon: ArrowDownTrayIcon,
    },
  ];

  const DownloadButtons = () => {
    return (
      <div className="mt-8 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 p-4">
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.elcovia.android"
          title="Download on the Google Play"
          className="flex items-center space-x-2 bg-gray-900 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-gray-800 transition duration-200"
        >
          <svg
            aria-hidden="true"
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="m12.954 11.616 2.957-2.957L6.36 3.291c-.633-.342-1.226-.39-1.746-.016l8.34 8.341zm3.461 3.462 3.074-1.729c.6-.336.929-.812.929-1.34 0-.527-.329-1.004-.928-1.34l-2.783-1.563-3.133 3.132 2.841 2.84zM4.1 4.002c-.064.197-.1.417-.1.658v14.705c0 .381.084.709.236.97l8.097-8.098L4.1 4.002zm8.854 8.855L4.902 20.91c.154.059.32.09.495.09.312 0 .637-.092.968-.276l9.255-5.197-2.666-2.67z" />
          </svg>
          <div className="text-start">
            <span className="block text-xs font-light">{t("page_apps.download_title")}</span>
            <span className="block text-lg font-semibold">{t("page_apps.download_google_play")}</span>
          </div>
        </a>
      </div>
    );
  };

  const RatingComponent = () => {
    return (
      <div className="mt-3 flex flex-col items-center space-y-2 p-4">
        <div className="flex space-x-3">
          <div className="flex items-center space-x-1">
            {[...Array(5)].map((_, index) => (
              <svg
                key={index}
                className="w-5 h-5 text-yellow-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ))}
          </div>
          <span className="text-sm font-normal text-gray-600">5.0</span>
        </div>
        <p className="text-sm font-light text-gray-600">
          Rated Best Over <span className="font-bold text-gray-900">15.7k</span> Reviews
        </p>
      </div>
    );
  };

  const FeatureSection = () => {
    return (
      <section className="mt-12 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-800">{t("page_apps.features_title")}</h2>
            <p className="mt-3 mx-auto text-xl font-light leading-7 text-gray-600 max-w-2xl">
              {t("page_apps.features_description")}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {features.map((feature, index) => (
              <div
                key={index}
                className="transition-all ease-in-out duration-300 group relative bg-white  shadow-sm rounded-lg border border-1 border-gray-200/50 px-6 py-4"
              >
                <div className="flex items-center justify-center">
                  <feature.icon className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="mt-3 text-xl font-semibold text-gray-800 mb-2">{feature.title}</h3>
                <p className="text-md font-normal text-gray-500 leading-6">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };

  // Inspired by: https://flowbite.com/marketing-ui/demo/landing/mobile-application/
  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:main_pages.page_apps.title") + t("seo:tabs.title_suffix"),
            t("seo:main_pages.page_apps.description")
          )
        }
      />
      <div>
        <div className="mx-auto text-center py-12">
          <h1 className="text-6xl font-bold leading-10 tracking-tight text-gray-900">{t("page_apps.title")}</h1>
          <p className="mt-6 text-xl font-light leading-7 text-gray-600">{t("page_apps.description")}</p>
          <DownloadButtons />
          <div>
            <img
              src={process.env.PUBLIC_URL + "/imgs/android_app.png"}
              alt="Elcovia Apps"
              className="mt-12 w-full max-w-md mx-auto rounded-lg shadow-lg"
            />
            {/* <RatingComponent /> */}
          </div>
          <FeatureSection />
        </div>
      </div>
    </>
  );
}
