import { ArrowRightIcon } from "@heroicons/react/20/solid";
import React from "react";

import { classNames } from "../../../utils/utils-react";
import CustomLink from "../links/CustomLink";

export type ButtonStyle = "structural" | "structural-text" | "colored" | "standard" | "warning" | "redirect";

interface Props {
  onClick?: () => void;
  href?: string;
  target?: string;
  rel?: string;
  type?: "button" | "submit" | "reset";
  style: ButtonStyle;
  disabled?: boolean;
  loading?: boolean;
  parentClassNames?: string;
  baseClassNames?: string;
  text: string;
  leftIconChild?: React.ReactNode;
  rightIconChild?: React.ReactNode;
}

const ButtonComponent: React.FC<Props> = ({
  onClick,
  href,
  target,
  rel,
  type,
  style,
  disabled,
  loading,
  parentClassNames,
  baseClassNames,
  text,
  leftIconChild,
  rightIconChild,
}) => {
  const coreClassNames = classNames(
    style === "colored" ? "text-white hover:bg-indigo-700 bg-indigo-500 border border-indigo-300 fill-white" : "",
    style === "standard" ? "text-gray-800 hover:bg-gray-100 bg-white border border-gray-300 fill-gray-800" : "",
    style === "structural" ? "text-white hover:bg-gray-900 bg-black border border-gray-700 fill-white" : "",
    style === "structural-text" ? "text-black hover:bg-gray-100 bg-white border border-gray-300 fill-black" : "",
    style === "warning" ? "text-white hover:bg-red-700 bg-red-500 border border-red-300 fill-white" : "",
    style === "redirect" ? "text-indigo-500 hover:bg-indigo-100 bg-indigo-50 border border-indigo-300 fill-indigo-500" : "",
    "inline-flex transition-all ease-in-out duration-300 rounded shadow-sm py-2.5 px-4 text-sm font-medium outline-none justify-center items-center",
    baseClassNames || "",
    disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
  );

  const button = (
    <button onClick={onClick} disabled={disabled} type={type || "button"} className={coreClassNames}>
      {leftIconChild}
      {loading && (
        <svg className="animate-spin h-4 w-4 mr-2" height="48" viewBox="0 -960 960 960" width="48">
          <path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" />
        </svg>
      )}

      {style === "redirect" && <ArrowRightIcon className="h-4 w-4 mr-2" aria-hidden="true" />}

      {text}
      {rightIconChild}
    </button>
  );

  if (href)
    return (
      <div className={parentClassNames}>
        <CustomLink to={href || "#"} target={target || ""} rel={rel || ""}>
          {button}
        </CustomLink>
      </div>
    );

  return <div className={parentClassNames}>{button}</div>;
};

export default ButtonComponent;
