import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import { sendEmail } from "../../../services/mail-service";
import { Destination } from "../../../utils/constants-navigation";

export default function ContactPage() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  /**
   * Handle form submission
   * @param e Form event
   */
  async function handleSubmit(e: React.FormEvent<HTMLButtonElement>) {
    setError(false);
    e.preventDefault();
    try {
      await sendEmail(name, email, subject, message);
      setSuccess(true);
      setError(false);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:main_pages.page_contact.tab_suffix") + t("seo:tabs.title_suffix"),
            t("seo:main_pages.page_contact.description")
          )
        }
      />
      <div>
        <div>
          <div className="mx-auto text-center">
            <h2 className="text-4xl font-bold leading-10 tracking-tight text-gray-900">
              {t("common:page_contact:title")}
            </h2>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {t("common:page_contact:subtitle")}
              <a
                href={Destination.FAQS_ELCOVIA}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              ></a>{" "}
            </p>
          </div>
          {success ? (
            <p className="mt-6 text-indigo-500  text-center">
              Your message has been sent. We will get back to you as soon as we can.
            </p>
          ) : (
            <div className="mx-auto mt-16 max-w-xl sm:mt-20">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t("common:page_contact:name")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required={true}
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t("common:page_contact:email")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required={true}
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="subject" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t("common:page_contact:subject")}
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                      required={true}
                      type="text"
                      name="subject"
                      id="subject"
                      autoComplete="text"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t("common:page_contact:message")}
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      name="message"
                      id="message"
                      rows={4}
                      minLength={50}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              {error && (
                <p className="mt-6 text-red-500 text-center">
                  There was an error sending your message. Please try again later.
                </p>
              )}
              <div className="mt-10">
                <button
                  onClick={(e) => handleSubmit(e)}
                  type="button"
                  className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("common:page_contact:send")}
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
