import React, { ReactNode, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";

import { RootState } from "../store";
import { FIREBASE_ADMIN_UID } from "../utils/constants";
import { Destination } from "../utils/constants-navigation";
import AccountApp from "./AccountApp";
import AdminApp from "./AdminApp";
import PublicApp from "./PublicApp";
import Purchase from "./Purchase";
import { Logger } from "../utils/utils-logging";
import { getLanguagesPath, supportedWebLanguages } from "../languages/i18n";
import LanguageRedirect from "../components/core/common/LanguageRedirect";

export function getRoutes(path: string, element: React.ReactNode) {
  return (
    <>
      <Route path={path} element={element} />
      {supportedWebLanguages.map((lng) => (
        <Route key={lng} path={`/${lng}${path}`} element={element} />
      ))}
    </>
  );
}

const App = () => {
  const logger = new Logger("App");
  const profile = useSelector((state: RootState) => state.user.profile);
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsAdmin(profile?.id == FIREBASE_ADMIN_UID);
  }, [profile]);

  const AppContainer = ({ children }: { children: ReactNode }) => {
    return <div className="bg-white">{children}</div>;
  };

  return (
    <HelmetProvider>
      <BrowserRouter>
        <AppContainer>
          <LanguageRedirect />
          <Routes>
            {/* Language specific routes handled within components */}
            <Route path={Destination.APP_PUBLIC} element={<PublicApp />} />
            {getRoutes(Destination.PURCHASE, <Purchase />)}

            {/* Language specific routes handled before the nested components */}
            {getRoutes(Destination.APP_ACCOUNT, <AccountApp />)}
            {getRoutes(Destination.APP_ADMIN, <AdminApp />)}
          </Routes>
        </AppContainer>
      </BrowserRouter>
    </HelmetProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(App);
