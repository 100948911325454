import { CurrencyDollarIcon, GlobeAmericasIcon, SparklesIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import React, { Fragment, FunctionComponent, Profiler, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import LoadingScreenComponent from "../../../components/common/loading/LoadingScreenComponent";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import { ProfilePromotionConfig } from "../../../models/data/payment/ProfilePromotionConfig";
import { Profile } from "../../../models/Profile";
import { getUserByUserId } from "../../../services/firestore-service";
import {
  createPaypalProfilePromotionOrder,
  createStripeProfilePromotionOrder,
} from "../../../services/functions-service";
import { getProfilePromotionConfig } from "../../../services/remote-configs-service";
import { handleStripeResponse } from "../../../services/stripe-service";
import { RootState } from "../../../store";
import { durationKeyToLabel } from "../../../utils/utils-formatting";
import { Logger } from "../../../utils/utils-logging";
import { classNames } from "../../../utils/utils-react";
import PromotionComponent, { Benefit, Showcase } from "./PromotionComponent";

const logger = new Logger("ProfilePromotionScreen");

const ProfilePromotionScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [promotionable, setPromotionable] = React.useState<boolean>(true);
  const [onBlockPayButton, setOnBlockPayButton] = React.useState<boolean>(true);

  const [selectedProfilePromotionConfig, setSelectedProfilePromotionConfig] =
    React.useState<ProfilePromotionConfig | null>(null);

  const [profilePromotionConfig, setProfilePromotionConfig] = React.useState<ProfilePromotionConfig[] | null>(null);

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);

  const [profile, setProfile] = React.useState<Profile | null>(null);

  /**
   * Set promotionable state.
   */
  React.useEffect(() => {
    if (!isSignedIn || !profile) return;
    setPromotionable(profile.promotion == null);
  }, [profile]);

  /**
   * Load initial profile data.
   */
  React.useEffect(() => {
    if (profilePromotionConfig == null) {
      const fetchProfilePromotionConfig = async () => {
        try {
          const config = await getProfilePromotionConfig();
          setProfilePromotionConfig(config);
          setSelectedProfilePromotionConfig(config[1]);
          setOnBlockPayButton(false);
        } catch (e) {
          console.error(e);
        }
      };
      fetchProfilePromotionConfig();
    }

    if (profile == null) {
      const fetchProfile = async () => {
        try {
          const profile = await getUserByUserId(sessionProfile!!.id);
          setProfile(profile);
        } catch (e) {
          console.error(e);
        }
      };
      fetchProfile();
    }
  }, []);

  /**
   * Promote profile.
   * @param profile Profile to promote.
   */
  async function promoteProfile(profile: Profile) {
    try {
      setOnBlockPayButton(true);
      const purchaseKey = selectedProfilePromotionConfig!!.key;
      // promoteProfilePayPal(purchaseKey);
      promoteProfileStripe(purchaseKey);
    } catch (error) {
      console.log(error);
    }
    setOnBlockPayButton(false);
  }

  /**
   * Promote profile with Stripe.
   * @param purchaseKey Purchase key.
   */
  async function promoteProfileStripe(purchaseKey: string) {
    const response = await createStripeProfilePromotionOrder(purchaseKey);
    await handleStripeResponse(response.data);
  }

  /**
   * Promote profile with PayPal.
   * @param purchaseKey Purchase key.
   */
  async function promoteProfilePayPal(purchaseKey: string) {
    const result = await createPaypalProfilePromotionOrder(purchaseKey);
    const jsonResult = JSON.parse(result.data as string);
    const url = jsonResult.links[1].href;
    window.location.href = url;
  }

  const benefits: Benefit[] = [
    {
      name: "page_account.page_promotion_profile.benefits.increased_visibility.title",
      description: "page_account.page_promotion_profile.benefits.increased_visibility.description",
      Icon: GlobeAmericasIcon,
    },
    {
      name: "page_account.page_promotion_profile.benefits.enhanced_recognition.title",
      description: "page_account.page_promotion_profile.benefits.enhanced_recognition.description",
      Icon: SparklesIcon,
    },
    {
      name: "page_account.page_promotion_profile.benefits.attract_enthusiasts.title",
      description: "page_account.page_promotion_profile.benefits.attract_enthusiasts.description",
      Icon: UserGroupIcon,
    },
    {
      name: "page_account.page_promotion_profile.benefits.revenue_expansion.title",
      description: "page_account.page_promotion_profile.benefits.revenue_expansion.description",
      Icon: CurrencyDollarIcon,
    },
  ];

  const showcases: Showcase[] = [
    {
      image: process.env.PUBLIC_URL + "/imgs/showcase_profile_promotion.png",
      title: "page_account.page_promotion_profile.showcases.title",
      description: "page_account.page_promotion_profile.showcases.description",
    },
  ];

  if (profile == null || selectedProfilePromotionConfig == null || profilePromotionConfig == null) {
    return <LoadingScreenComponent />;
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:account_pages.page_account_profile_promotion.title") + t("seo:tabs.title_suffix"),
            t("seo:account_pages.page_account_profile_promotion.description")
          )
        }
      />

      <PromotionComponent
        title={t("page_account.page_promotion_profile.title")}
        promotionable={promotionable}
        price={selectedProfilePromotionConfig.price}
        currencyCode={selectedProfilePromotionConfig.currencyCode}
        preTitleChildren={
          <img
            key={profile.image}
            src={profile.image}
            alt={profile.image}
            className={classNames(
              true ? "lg:col-span-2 lg:row-span-2" : "hidden lg:block",
              "rounded-3xl shadow w-32 mx-auto mb-12"
            )}
          />
        }
        description={t("page_account.page_promotion_profile.subtitle")}
        postDescriptionChildren={
          <div>
            {promotionable ? (
              <>
                {/* Duration picker */}
                <div className="mt-12">
                  <div className="mt-1 flex items-center justify-between">
                    <h2 className="text-sm font-medium text-gray-900">{t("duration")}</h2>
                  </div>

                  <div className="mt-3">
                    <p className="sr-only">{t("choose_a_duration")}</p>
                    <div className="grid grid-cols-3 gap-4 sm:grid-cols-3">
                      {profilePromotionConfig.map((config) => (
                        <ButtonComponent
                          key={config.key}
                          baseClassNames="w-full"
                          text={durationKeyToLabel(t, config.duration)}
                          onClick={() => setSelectedProfilePromotionConfig(config)}
                          style={selectedProfilePromotionConfig == config ? "colored" : "standard"}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {/* Divider separator */}
                <div className="border-t border-gray-200 mt-8 pt-6" />

                {/* Buy button */}
                <div className="mt-6 grid ">
                  <ButtonComponent
                    text={t("promote")}
                    style="structural"
                    baseClassNames="w-64"
                    loading={onBlockPayButton}
                    onClick={() => promoteProfile(profile)}
                    disabled={onBlockPayButton}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mt-12 border-y py-12 border-gray-200">
                  <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                    <Trans
                      i18nKey="page_account.page_promotion_profile.active_promotion"
                      components={{ span: <span className="text-green-500" /> }}
                    />
                  </h3>
                  <p className="mt-4 text-md font-normal text-gray-500 leading-6">
                    <Trans
                      i18nKey="page_account.page_promotion_profile.active_promotion_subtitle"
                      components={{ span: <span className="font-semibold" /> }}
                    />
                  </p>
                </div>
              </>
            )}
          </div>
        }
        benefits={benefits}
        showcases={showcases}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(ProfilePromotionScreen);
