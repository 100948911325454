import React, { ReactNode, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Route, RouteObject, Routes, useLocation } from "react-router-dom";

import LanguageSwitcher from "../components/core/common/LanguageSwitcher";
import FooterComponent from "../components/core/footer/FooterComponent";
import FooterPoweredComponent from "../components/core/footer/FooterPoweredComponent";
import PublicHeaderComponent from "../components/core/header/PublicHeaderComponent";
import AffiliateGumroadGuideScreen from "../pages/account/misc/AffiliateGumroadGuideScreen";
import AppsScreen from "../pages/public/info/AppsPage";
import BetaTestersPage from "../pages/public/info/BetaTestersPage";
import ContactPage from "../pages/public/info/ContactPage";
import FAQsElcoviaPage from "../pages/public/info/faq/FAQsElcoviaPage";
import FAQsNotionPage from "../pages/public/info/faq/FAQsNotionPage";
import { PrivacyPolicyPage } from "../pages/public/legal/PrivacyPolicyPage";
import { TermsAndConditionsPage } from "../pages/public/legal/TermsAndConditionsPage";
import ProfileScreen from "../pages/public/main/creator/ProfileScreen";
import ProfilesPage from "../pages/public/main/creators/ProfilesPage";
import DiscoverPage from "../pages/public/main/discover/DiscoverPage";
import LoginPage from "../pages/public/main/LoginPage";
import MarketplaceTemplatePage from "../pages/public/main/MarketplaceTemplatePage";
import TemplatePage from "../pages/public/main/template/TemplatePage";
import TemplatesPage from "../pages/public/main/templates/TemplatesPage";
import TemplateCategoriesPage from "../pages/public/main/templates_categories/TemplateCategoriesPage";
import TemplatesLatestPage from "../pages/public/main/templates_latest/TemplatesLatestPage";
import { RootState } from "../store";
import { Args, Destination, URL_PARAM_DISCOVER_VALUE } from "../utils/constants-navigation";
import { Logger } from "../utils/utils-logging";
import { getLanguagesPath, getLanguagesPathBy, supportedWebLanguages } from "../languages/i18n";
import { omit } from "lodash";
import { getRoutes } from "./App";

const logger = new Logger("PublicApp");

const PublicApp = () => {
  const location = useLocation();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const [isHeaderVisible, setIsHeaderVisible] = React.useState(true);

  interface PageContainerProps {
    children: ReactNode;
    hasHeader: boolean;
  }
  const PageContainer = ({ children, hasHeader }: PageContainerProps) => {
    logger.log("PageContainer: hasHeader", hasHeader);
    return (
      <div className={`max-w-3xl lg:max-w-6xl xl:max-w-7xl min-h-screen mx-auto px-4 md:px-6 xl:px-8 ${hasHeader ? "mt-20 pt-12 md:pt-12 xl:pt-16" : "pt-8 md:pt-12 xl:pt-16"}`}>
        <div>{children}</div>
      </div>
    );
  };

  React.useEffect(() => {
    const specialPaths = [Destination.TEMPLATE_ID, Destination.TEMPLATE_ID_SHORT, Destination.TEMPLATE_PREVIEW_ID, Destination.USER_ID, Destination.USER_ID_SHORT];
    // Check if the URL contains the discover param
    const urlParams = new URLSearchParams(location.search);
    const pathContainsParam = urlParams.get(Args.LAYOUT) === URL_PARAM_DISCOVER_VALUE;

    // Remove last segment of the path
    const currentPath = location.pathname;
    const pathSegments = currentPath.split("/");
    const pathWithoutId = pathSegments.slice(0, -1).join("/");

    const currentPathSameAsDestination = specialPaths.some((path) => {
      const fixedPathWithoutId = path.replace("/:id", "");
      const samePath = pathWithoutId === fixedPathWithoutId;
      logger.log("currentPathSameAsDestination", samePath, {
        pathWithoutId,
        fixedPathWithoutId,
      });
      return samePath;
    });

    // Hide the header if the path contains the param and the current path is the same as the destination
    const isNotVisible = !pathContainsParam && currentPathSameAsDestination;

    logger.log("isNotVisible", isNotVisible, {
      pathContainsParam,
      currentPathSameAsDestination,
    });

    setIsHeaderVisible(!isNotVisible);
  }, [location]);

  return (
    <>
      {isHeaderVisible && <PublicHeaderComponent />}
      <PageContainer hasHeader={isHeaderVisible}>
        <Routes>
          {getRoutes(Destination.DISCOVER, <DiscoverPage />)}
          {getRoutes(Destination.LOGIN, <LoginPage />)}
          {getRoutes(Destination.TEMPLATES_LATEST, <TemplatesLatestPage />)}
          {getRoutes(Destination.TEMPLATES, <TemplatesPage />)}
          {getRoutes(Destination.TEMPLATES_CATEGORIES, <TemplateCategoriesPage />)}
          {getRoutes(Destination.TEMPLATE_ID, <TemplatePage isDiscover={isHeaderVisible} />)}
          {getRoutes(Destination.TEMPLATE_ID_SHORT, <TemplatePage isDiscover={isHeaderVisible} />)}
          {getRoutes(Destination.PURCHASE_MARKETPLACE_TEMPLATE_REFERENCE_ID, <MarketplaceTemplatePage />)}
          {getRoutes(Destination.CREATORS_ALTERNATIVE, <ProfilesPage />)}
          {getRoutes(Destination.CREATORS, <ProfilesPage />)}
          {getRoutes(Destination.USERS_ALTERNATIVE, <ProfilesPage />)}
          {getRoutes(Destination.USERS, <ProfilesPage />)}
          {getRoutes(Destination.USER_ID, <ProfileScreen discover={isHeaderVisible} />)}
          {getRoutes(Destination.USER_ID_SHORT_DEPRECIATED, <ProfileScreen discover={true} />)}
          {getRoutes(Destination.USER_ID_SHORT, <ProfileScreen discover={isHeaderVisible} />)}
          {getRoutes(Destination.BETA_TESTERS, <BetaTestersPage />)}
          {getRoutes(Destination.FAQS_ELCOVIA, <FAQsElcoviaPage />)}
          {getRoutes(Destination.FAQS_NOTION, <FAQsNotionPage />)}
          {getRoutes(Destination.CONTACT, <ContactPage />)}
          {getRoutes(Destination.APPS, <AppsScreen />)}
          {getRoutes(Destination.PRIVACY_POLICY, <PrivacyPolicyPage />)}
          {getRoutes(Destination.TERMS_AND_CONDITIONS, <TermsAndConditionsPage />)}
          {getRoutes(Destination.AFFILIATE_GUMROAD_GUIDE, <AffiliateGumroadGuideScreen />)}
          {getRoutes(Destination.TEMPLATE_PREVIEW_ID, <TemplatePage isDiscover={isHeaderVisible} />)}
        </Routes>
      </PageContainer>
      {isHeaderVisible ? <FooterComponent /> : <FooterPoweredComponent />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(PublicApp);
