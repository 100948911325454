import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, LanguageIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Record } from "immutable";
import _, { isArray, isEqual } from "lodash";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import { CategorySelectionDialog } from "../../../components/common/dialogs/CategorySelectionDialog";
import { ConfirmationDialogComponent } from "../../../components/common/dialogs/ConfirmationDialog";
import { ErrorDialog } from "../../../components/common/dialogs/ErrorDialog";
import { FeatureData } from "../../../components/common/dialogs/FeatureEditDialog";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import TabsComponent from "../../../components/common/tabs/TabsComponent";
import { Category, CategoryType } from "../../../models/Category";
import { supportedPaymentPlatforms } from "../../../models/data/PaymentPlatform";
import { Template } from "../../../models/Template";
import { TemplateFeature } from "../../../models/TemplateFeature";
import { UserSecret } from "../../../models/UserSecret";
import {
  deleteDraftTemplateById,
  deleteTemplateById,
  getDraftTemplateById,
  getTemplateById,
  getUserSecretById,
  updateDraftTemplate,
  updatePublicTemplate,
} from "../../../services/firestore-service";
import { publishDraftTemplate } from "../../../services/functions-service";
import { deleteFile, uploadFileToTemplate } from "../../../services/storage-service";
import {
  trackDeleteDraftTemplate,
  trackDeleteTemplate,
  trackDeleteTemplateError,
  trackPublishTemplate,
  trackPublishTemplateError,
  trackUpdateDraftTemplate,
  trackUpdateDraftTemplateError,
  trackUpdateTemplate,
  trackUpdateTemplateError,
} from "../../../services/tracking-service";
import { RootState } from "../../../store";
import {
  TEMPLATE_DESC_MAX_LENGTH,
  TEMPLATE_DESC_MIN_LENGTH,
  TEMPLATE_TITLE_MAX_LENGTH,
  TEMPLATE_TITLE_MIN_LENGTH,
} from "../../../utils/constants";
import { Destination } from "../../../utils/constants-navigation";
import {
  DEFAULT_LOCALE,
  fromKey,
  getLocaleDisplayName,
  LocaleKey,
  localeKey,
  localizedValue,
  localizedValueWithKey,
  localizedValueWithLocale,
  SupportedLocale,
  SupportedLocales,
} from "../../../utils/supported-locales";
import { Logger } from "../../../utils/utils-logging";
import { classNames } from "../../../utils/utils-react";
import { TemplateActionsSection } from "./TemplateActionsSection";
import TemplateFeaturesSection from "./TemplateFeaturesSection";
import TemplateGeneralSection from "./TemplateGeneralSection";
import TemplateMonetizationSection, {
  initMonetization,
  Monetization,
  MonetizationType,
  MonetizationTypes,
} from "./TemplateMonetizationSection";
import TemplateVisualsSections from "./TemplateVisualsSections";

const logger = new Logger("TemplateScreen");

export class ErrorState {
  title: boolean;
  desc: boolean;
  templateUrl: boolean;
  coverImages: boolean;
  thumbnailImage: boolean;
  price: boolean;
  priceSuggested: boolean;
  paidUrls: any[];
  [key: string]: any;

  constructor() {
    this.title = false;
    this.desc = false;
    this.templateUrl = false;
    this.coverImages = false;
    this.thumbnailImage = false;
    this.price = false;
    this.priceSuggested = false;
    this.paidUrls = Array(3).fill(false);
  }
}

/**
 * Hints that are displayed to the user, which indicate an error, but that be be applied and saved despite it.
 */
export enum Hint {
  NON_AFFILIATE = "non-affiliate",
}

interface TemplateScreenParams {
  id: string;
  [key: string]: any;
  onUpdateTitle: (title: string) => void;
  onUpdateTitleView: (view: React.ReactNode) => void;
}

const TabType = {
  GENERAL: "page_account.page_template.tabs.general",
  VISUALS: "page_account.page_template.tabs.visuals",
  FEATURES: "page_account.page_template.tabs.features",
  MONETIZATION: "page_account.page_template.tabs.monetization",
  ACTIONS: "page_account.page_template.tabs.actions",
};

type Tab = {
  name: (typeof TabType)[keyof typeof TabType];
  current: boolean;
};

const initTabs: Tab[] = [
  { name: TabType.GENERAL, current: true },
  { name: TabType.VISUALS, current: false },
  { name: TabType.FEATURES, current: false },
  { name: TabType.MONETIZATION, current: false },
  { name: TabType.ACTIONS, current: false },
];

const TemplatePage: React.FC<TemplateScreenParams> = ({ onUpdateTitle }) => {
  const navigate = useNavigate();
  const { id } = useParams<TemplateScreenParams>();
  const { t } = useTranslation();
  const [userSecret, setUserSecret] = React.useState<UserSecret | null>(null);
  const [template, setTemplate] = React.useState<Template | null>(null);
  const [templateOriginal, setTemplateOriginal] = React.useState<Template | null>(null);
  const [hasChanges, setHasChanges] = React.useState(false);

  const [currentLocale, setCurrentLocale] = React.useState<SupportedLocale>(DEFAULT_LOCALE);
  const [currentLocales, setCurrentLocales] = React.useState<SupportedLocale[]>([DEFAULT_LOCALE]);

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [tabs, setTabs] = React.useState<Tab[]>(initTabs);
  const [monetizations, setMonetizations] = React.useState<Monetization[]>(getInitMonetizations());
  const [activeMonetization, setActiveMonetization] = React.useState<Monetization | null>(null);

  const [title, setTitle] = React.useState<string>(getLocaleTitle());
  const [desc, setDesc] = React.useState<string>(getLocaleDesc());
  const [templateUrl, setTemplateUrl] = React.useState<string>(getLocaleTemplateUrl());
  const [paidUrls, setPaidUrls] = React.useState<string[]>(getLocalePaidUrls());
  const [features, setFeatures] = React.useState<TemplateFeature[] | null>(null);
  const [thumbnailImage, setThumbnailImage] = React.useState<string>(getThumbnailImage());
  const [coverImages, setCoverImages] = React.useState<string[]>(getCoverImages());

  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const [categories, setCategories] = React.useState<CategoryType[]>(getCategories());

  const [openCategorySelectionDialog, setOpenCategorySelectionDialog] = React.useState(false);

  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = React.useState(false);

  const [openPublishConfirmationDialog, setOpenPublishConfirmationDialog] = React.useState(false);

  const [localeErrors, setLocaleErrors] = React.useState<Map<LocaleKey, ErrorState>>(new Map());

  const [localeError, setLocaleError] = React.useState<Map<LocaleKey, Boolean>>(new Map());

  const [errors, setErrors] = React.useState<ErrorState>(new ErrorState());
  const [error, setError] = React.useState<boolean>(false);

  async function loadUserSecret() {
    try {
      if (!isSignedIn || !sessionProfile) return;
      const userSecret = await getUserSecretById(sessionProfile.id);
      setUserSecret(userSecret);
    } catch (error) {
      console.error(error);
    }
  }

  const loadTemplate = async () => {
    let template;
    try {
      template = await getTemplateById(id);
    } catch (e) {
      template = await getDraftTemplateById(sessionProfile!!.id, id);
    }
    const locales = getLocales(template);
    setCurrentLocale(locales[0] || DEFAULT_LOCALE);
    setCurrentLocales(locales || [DEFAULT_LOCALE]);
    setDataForLocale();
    setTemplate(template);
    setTemplateOriginal(_.cloneDeep(template));
    updateDashboardTitle(template);
    updateMonetizationType(template);
    checkValid();
  };

  /**
   * Load initial template data.
   */
  React.useEffect(() => {
    if (template == null) loadTemplate();
  }, [id]);

  React.useEffect(() => {
    if (template) {
      updateMonetizationType(template);
    }

    if (userSecret === null) loadUserSecret();
  }, []);

  /**
   * Handle monetization type change event.
   */
  React.useEffect(() => {
    const active = monetizations.find((m) => m.current) || null;
    setActiveMonetization(active);
  }, [monetizations]);

  /**
   * Setup the initial monetizations.
   */
  function getInitMonetizations(): Monetization[] {
    const result = initMonetization;
    result.forEach((m) => {
      if (m.type == MonetizationTypes.SINGLE_PAYMENT || m.type == MonetizationTypes.PAY_WHAT_YOU_WANT) {
        m.disabled = !userSecret?.stripeAccountId;
      }
    });
    return result;
  }

  /**
   * Update current locale error state.
   */
  React.useEffect(() => {
    const hasErrors = new Map(Array.from(localeErrors).map(([key, value]) => [key, isErrorStateInvalid(value)]));
    setLocaleError(hasErrors);
    const key = localeKey(currentLocale);
    const currentErrors = localeErrors.get(key);
    const errorState = currentErrors ? currentErrors : new ErrorState();
    setErrors(errorState);
  }, [localeErrors]);

  /**
   * Update current locale error state.
   */
  React.useEffect(() => {
    const hasError = isErrorStateInvalid(errors);
    setError(hasError);
  }, [errors]);

  /**
   * Handle monetization type change event.
   */
  React.useEffect(() => {
    const active = monetizations.find((m) => m.current) || null;
    setActiveMonetization(active);
  }, [monetizations]);

  /**
   * Check if error state is invalid.
   * @param errorState error state.
   * @returns true if error state is invalid.
   */
  function isErrorStateInvalid(errorState: ErrorState) {
    return (
      Object.values(errorState).some((value) => value === true) || errorState.paidUrls.some((value) => value === true)
    );
  }

  /**
   * Get categories.
   * @param categoryKeys current categories.
   */
  function getCategories(): CategoryType[] {
    return template
      ? (
          template?.categories?.map((key) => Object.values(Category).find((c) => c.key === key)) as CategoryType[]
        ).filter(Boolean) || []
      : [];
  }

  /**
   * Get localized title.
   * @returns localized title.
   */
  function getLocaleTitle() {
    return template ? localizedValueWithLocale(template.title, currentLocale) || "" : "";
  }

  /**
   * Get localized description.
   * @returns localized description.
   */
  function getLocaleDesc() {
    return template ? localizedValueWithLocale(template.desc, currentLocale) || "" : "";
  }

  /**
   * Get localized template url.
   * @returns localized template url.
   */
  function getLocaleTemplateUrl() {
    return template ? localizedValueWithLocale(template.templateUrl, currentLocale) || "" : "";
  }

  /**
   * Get localized paid urls.
   * @returns localized paid urls.
   */
  function getLocalePaidUrls(): string[] {
    let result: string[];

    if (!template || !template.paidUrls) {
      result = Array(3).fill("");
    } else {
      const localizedUrls = localizedValueWithLocale(template.paidUrls, currentLocale);

      result = localizedUrls ? Array.from({ length: 3 }, (_, index) => localizedUrls[index] || "") : Array(3).fill("");
    }

    return result;
  }

  /**
   * Get localized features.
   * @returns localized features.
   */
  function getLocaleFeatures(): TemplateFeature[] | null {
    return template ? localizedValueWithLocale(template.features, currentLocale) || null : null;
  }

  /**
   * Get localized thumbnail image.
   * @returns localized thumbnail image.
   */
  function getThumbnailImage() {
    return template ? localizedValueWithLocale(template.thumbnailImage, currentLocale) || "" : "";
  }

  /**
   * Get localized cover images.
   * @returns cover images.
   */
  function getCoverImages() {
    return template ? localizedValueWithLocale(template.coverImages, currentLocale) || [] : [];
  }

  function setDataForLocale() {
    // General section
    setTitle(getLocaleTitle());
    setDesc(getLocaleDesc());
    setTemplateUrl(getLocaleTemplateUrl());
    setCategories(getCategories());

    // Visual section
    setThumbnailImage(getThumbnailImage());
    setCoverImages(getCoverImages());

    // Features section
    setFeatures(getLocaleFeatures());

    // Commercial section
    setPaidUrls(getLocalePaidUrls());
  }

  React.useEffect(() => {
    setDataForLocale();
    setHasChanges(checkHasChanges());
    checkValid();
  }, [template, currentLocale]);

  function checkValid() {
    if (!template) return;
    const errors = getErrorStates(template);
    setLocaleErrors(errors);
  }

  function updateMonetizationType(template: Template) {
    if (!template) return;
    const type = getInitMonetizationType(template);
    const updatedMonetizations = monetizations.map((monetization) => ({
      ...monetization,
      current: monetization.type.name === type.name,
    }));
    setMonetizations(updatedMonetizations);
  }

  function getInitMonetizationType(template: Template): MonetizationType {
    if (template.priceSuggested && template.price) {
      return MonetizationTypes.PAY_WHAT_YOU_WANT;
    } else if (template.price) {
      return MonetizationTypes.SINGLE_PAYMENT;
    } else {
      return MonetizationTypes.LEAD_MAGNET;
    }
  }

  function handleMonetizationTypeChange(type: MonetizationType) {
    if (!template) return;

    // Update the monetization type.
    const updatedMonetizations = monetizations.map((monetization) => ({
      ...monetization,
      current: !monetization.disabled && monetization.type.name === type.name,
    }));

    // Reset the not neccessary properties based on the monetization type.
    const newTemplate = _.cloneDeep(template);
    switch (type) {
      case MonetizationTypes.SINGLE_PAYMENT:
        newTemplate.priceSuggested = null;
        newTemplate.paidUrls = {};
        break;
      case MonetizationTypes.PAY_WHAT_YOU_WANT:
        newTemplate.priceSuggested = null;
        newTemplate.paidUrls = {};
        break;
      case MonetizationTypes.LEAD_MAGNET:
        newTemplate.price = null;
        newTemplate.priceSuggested = null;
        newTemplate.paidUrls = {};
        break;
    }

    setTemplate(newTemplate);
    setMonetizations(updatedMonetizations);
  }

  /**
   * Get error states.
   * @param template template.
   * @returns error states.
   */
  function getErrorStates(template: Template): Map<LocaleKey, ErrorState> {
    return new Map<LocaleKey, ErrorState>(
      Object.values(template.locales).map((locale) => {
        const errorState = new ErrorState();
        Object.keys(errorState).forEach((key) => {
          const value = template[key];
          const type = typeof value;
          const isRecord = type === "object" && !isArray(value);
          if (isRecord) {
            logger.log("Localized property", "key", key, "value", value);
            const localeValue = localizedValueWithKey(value, locale);
            const error = isPropertyInvalid(key, localeValue);
            logger.log("Localized property", "key", key, "error", error, "value", value);

            errorState[key] = error;
          } else {
            logger.log("Normal property", "key", key, "value", value);
            const error = isPropertyInvalid(key, value);
            logger.log("Normal property", "key", key, "error", error, "value", value);
            errorState[key] = error;
          }
        });
        return [locale, errorState];
      })
    );
  }

  function checkHasChanges() {
    if (!template || !templateOriginal) {
      return false;
    }
    const keys = Object.keys(template);
    for (const key of keys) {
      const newValue = template[key];
      const originalValue = templateOriginal[key];
      if (!isEqual(newValue, originalValue)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Validate input value for given name. Returns true if invalid. Otherwise false.
   * @param name name of input.
   * @param value value of input.
   * @return true if invalid. Otherwise false.
   */
  function isPropertyInvalid(name: any, value: any) {
    switch (name) {
      case "title":
        return (
          value == undefined || value.length < TEMPLATE_TITLE_MIN_LENGTH || value.length > TEMPLATE_TITLE_MAX_LENGTH
        );
      case "desc":
        return value == undefined || value.length < TEMPLATE_DESC_MIN_LENGTH || value.length > TEMPLATE_DESC_MAX_LENGTH;
      case "thumbnailImage":
        const thumbnailImage = value as any;
        return thumbnailImage == undefined || thumbnailImage == null || thumbnailImage.length == 0;
      case "coverImages":
        const coverImagesValue = value as any[];
        return coverImagesValue == undefined || coverImagesValue.length == 0;
      case "templateUrl":
        return value == undefined || value.length == 0 ? false : !/^https:\/\/.*\.notion\.site\/.*$/.test(value);

      // Monetization specific property validation
      case "price":
        const isMonetizationTypeSinglePaymentOrPayWhatYouWant =
          monetizations.find((m) => m.current)?.type === MonetizationTypes.SINGLE_PAYMENT ||
          monetizations.find((m) => m.current)?.type === MonetizationTypes.PAY_WHAT_YOU_WANT;

        if (!isMonetizationTypeSinglePaymentOrPayWhatYouWant) {
          logger.log("isPropertyInvalid", "price", "return false");
          return false;
        }

        const isPriceGreaterThanZero = value > 0;
        const priceValid = isPriceGreaterThanZero;
        logger.log("isPropertyInvalid", "price", {
          priceValid,
          isMonetizationTypeSinglePaymentOrPayWhatYouWant,
          isPriceGreaterThanZero,
        });
        // If price is not valid, return true = show error
        return !priceValid;

      // Monetization specific property validation
      case "priceSuggested":
        const isMonetizationTypePayWhatYouWant =
          monetizations.find((m) => m.current)?.type === MonetizationTypes.PAY_WHAT_YOU_WANT;
        if (!isMonetizationTypePayWhatYouWant) {
          logger.log("isPropertyInvalid", "priceSuggested", "return false");
          return false;
        }

        const isPriceSuggestedGreaterThanZero = value > 0;
        const isValueMoreThanTemplatePrice = value > (template?.price || 0.01);
        const priceSuggestValid = isValueMoreThanTemplatePrice && isPriceSuggestedGreaterThanZero;
        logger.log("isPropertyInvalid", "priceSuggested", {
          priceSuggestValid,
          isMonetizationTypePayWhatYouWant,
          isValueMoreThanTemplatePrice,
          isPriceSuggestedGreaterThanZero,
        });
        // If suggested price is not valid, return true = show error
        return !priceSuggestValid;

      // Monetization specific property validation
      case "paidUrls":
        const list = value as any[];
        const result = list?.map((link) => {
          if (link === undefined || link.length === 0) {
            return false;
          }

          const isValidProductUrl = supportedPaymentPlatforms.some((platform) =>
            platform.pattern.some((pattern) => pattern.test(link))
          );

          const isValidAffiliateProductUrl = supportedPaymentPlatforms.some((platform) =>
            platform.patternAffiliate?.some((patternAffiliate) => patternAffiliate.test(link))
          );

          if (isValidAffiliateProductUrl) {
            return false;
          }

          if (!isValidAffiliateProductUrl && isValidProductUrl) {
            return Hint.NON_AFFILIATE;
          }

          if (!isValidProductUrl) {
            return true;
          }
        }) || [false];
        return isArray(result) ? result : [result];
      default:
        return false;
    }
  }

  /**
   * Handle input change events.
   */
  function handleInputChangeEvents(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = event.target;
    handleLocalizedInputChange(name, value);
  }

  /**
   * Handle input change event.
   */
  function handleInputChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    handleLocalizedInputChange(name, value);
  }

  /**
   * Handle cover images order change.
   * @param coverImages Cover images in new order.
   */
  const handleCoverImagesOrderChange = (coverImages: string[]) => {
    handleLocalizedInputChange("coverImages", coverImages);
  };

  /**
   * Handle cover image delete.
   * @param index Index of cover image to delete.
   */
  const handleCoverImageDelete = (index: number) => {
    const coverImagesCopy = _.cloneDeep(coverImages);
    coverImagesCopy.splice(index, 1);
    handleLocalizedInputChange("coverImages", coverImagesCopy);
  };

  const fileInputRef = React.useRef<HTMLInputElement>(null!);
  const fileInputCoverImagesRef = React.useRef<HTMLInputElement>(null!);

  /**
   * Handle thumbnail image upload.
   */
  const handleLocalizedThumbnailImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file && file.type.includes("image")) {
      const thumbnailImage = new Image();
      thumbnailImage.onload = async () => {
        const targetWidth = 500;
        const targetHeight = 500;
        const targetRatio = targetWidth / targetHeight;
        const targetSize = 1 * 1024 * 1024; // 1 MB
        const ratio = thumbnailImage.width / thumbnailImage.height;
        if (ratio != targetRatio) {
          setUploadError("Image ratio does not meet the minimum requirements.");
        } else if (file.size > targetSize) {
          setUploadError("File size exceeds the maximum limit.");
        } else if (thumbnailImage.width < targetWidth || thumbnailImage.height < targetHeight) {
          setUploadError("Image resolution does not meet the minimum requirements.");
        } else {
          const templateId = template?.id || "";
          const result = await uploadFileToTemplate(sessionProfile?.id!!, file, templateId, true, true);
          if (result) {
            const thumbnailImageUrl = result as string;
            handleLocalizedInputChange("thumbnailImage", thumbnailImageUrl);
          } else {
            setUploadError("Failed to upload file.");
          }
        }
      };
      thumbnailImage.src = URL.createObjectURL(file);
    }
    fileInputRef.current.value = "";
  };

  /**
   * Handle the deletion of a cover image. This action is irreversible and should only be called for items that have been marked for deletion prior to saving.
   * @param coverImage Cover image to delete.
   */
  const deleteCoverImage = async (coverImage: string) => {
    try {
      const result = await deleteFile(coverImage);
    } catch (error) {}
  };

  /**
   * Handle cover image upload.
   */
  const handleLocalizedCoverImageUpload = (event: any) => {
    const files = event.target.files as FileList;
    const fileList = Array.from(files);
    fileList.forEach((file: File) => {
      if (file && file.type.includes("image")) {
        const coverImage = new Image();
        coverImage.onload = async () => {
          const targetWidth = 1280;
          const targetHeight = 720;
          const targetRatio = targetWidth / targetHeight;
          const targetSize = 3 * 1024 * 1024; // 3 MB
          const ratio = coverImage.width / coverImage.height;
          if (ratio != targetRatio) {
            setUploadError("Image ratio does not meet the minimum requirements.");
          } else if (file.size > targetSize) {
            setUploadError("File size exceeds the maximum limit.");
          } else if (coverImage.width < targetWidth || coverImage.height < targetHeight) {
            setUploadError("Image resolution does not meet the minimum requirements.");
          } else {
            const templateId = template?.id || "";
            const result = await uploadFileToTemplate(sessionProfile?.id!!, file, templateId, true, true);
            if (result) {
              const coverImageUrl = result as string;
              const coverImagesCopy = _.cloneDeep(coverImages);
              coverImagesCopy.push(coverImageUrl);
              handleLocalizedInputChange("coverImages", coverImagesCopy);
            } else {
              setUploadError("Failed to upload file.");
            }
          }
        };
        coverImage.src = URL.createObjectURL(file);
      }
    });
    fileInputCoverImagesRef.current.value = "";
  };

  /**
   * Handle feature save.
   * @param feature Feature to save.
   */
  function handleSaveFeature(data: FeatureData) {
    const newFeatures = _.cloneDeep(features || []);
    logger.log("handleSaveFeature", "data", data);
    if (data.feature) {
      if (data.index !== undefined && data.index !== null && data.index <= newFeatures.length) {
        logger.log("handleSaveFeature", "replace", data.feature);
        newFeatures.splice(data.index, 1, data.feature);
      } else {
        logger.log("handleSaveFeature", "push", data.feature);
        newFeatures.push(data.feature);
      }
      handleLocalizedInputChange("features", newFeatures);
    }
  }

  /**
   * Handle feature delete.
   * @param data Feature data.
   */
  function handleDeleteFeature(data: FeatureData) {
    const newFeatures = _.cloneDeep(features || []);
    if (data.index !== undefined && data.index !== null) {
      newFeatures.splice(data.index, 1);
    }
    handleLocalizedInputChange("features", newFeatures);
  }

  /**
   * Handle input change.
   * @param name input name.
   * @param value input value.
   */
  const handleLocalizedInputChange = (name: string, value: any) => {
    if (!template) return;

    const key = localeKey(currentLocale);
    (template[name] as Record<string, string[]>)[key] = value;
    template.locales = getLocaleKeys(template);

    const locales = getLocales(template);
    setCurrentLocales(locales);

    if (name === "title") {
      setTitle(value);
      onUpdateTitle(localizedValue(template.title) || "");
    } else if (name === "desc") {
      setDesc(value);
    } else if (name === "templateUrl") {
      setTemplateUrl(value);
    } else if (name === "paidUrls") {
      setPaidUrls(value);
    } else if (name === "thumbnailImage") {
      setThumbnailImage(value);
    } else if (name === "coverImages") {
      setCoverImages(value);
    } else if (name === "features") {
      setFeatures(value);
    }

    setTemplate(_.cloneDeep(template));

    const currentErrors = {
      ...errors,
      [name]: isPropertyInvalid(name, value),
    };
    localeErrors.set(key, currentErrors);
    setLocaleErrors(_.cloneDeep(localeErrors));
  };

  /**
   * Handle input change.
   * @param name input name.
   * @param value input value.
   */
  const handleInputChange = (name: string, value: any) => {
    if (!template) return;
    console.log("handleInputChange", name, value);
    template[name] = value;
    const currentErrors = {
      ...errors,
      [name]: isPropertyInvalid(name, value),
    };
    const key = localeKey(currentLocale);
    localeErrors.set(key, currentErrors);
    setLocaleErrors(_.cloneDeep(localeErrors));
    setTemplate(_.cloneDeep(template));
  };

  /**
   * Handle delete locale action.
   * @param locale Locale to delete.
   */
  function handleDeleteLocale(locale: SupportedLocale) {
    if (!template || currentLocales.length == 1) return;
    const key = localeKey(locale);
    const templateCopy = _.cloneDeep(template);
    delete templateCopy.title[key];
    delete templateCopy.desc[key];
    delete templateCopy.templateUrl[key];

    if (templateCopy.paidUrls) {
      delete templateCopy.paidUrls[key];
    }

    delete templateCopy.thumbnailImage[key];
    delete templateCopy.coverImages[key];
    templateCopy.locales = getLocaleKeys(templateCopy);
    const locales = getLocales(templateCopy);
    if (currentLocale == locale) {
      setCurrentLocale(locales[0]);
    }
    setCurrentLocales(locales);
    setTemplate(templateCopy);
    updateDashboardTitle(templateCopy);
  }

  /**
   * Handle add locale action.
   * @param categories
   */
  function handleAddLocale(locale: SupportedLocale) {
    if (!template) return;
    const templateCopy = _.cloneDeep(template);
    const key = localeKey(locale);
    templateCopy.title[key] = "";
    templateCopy.desc[key] = "";
    templateCopy.templateUrl[key] = "";
    if (templateCopy.paidUrls) {
      templateCopy.paidUrls[key] = ["", "", ""];
    }
    templateCopy.thumbnailImage[key] = "";
    templateCopy.coverImages[key] = [];
    template.locales = getLocaleKeys(template);
    const locales = getLocales(templateCopy);
    setCurrentLocale(locale);
    setCurrentLocales(locales);
    setTemplate(templateCopy);
    updateDashboardTitle(templateCopy);
  }

  const handleChangeCategories = (categories: string[]) => {
    if (!template) return;
    template.categories = categories;
    const templateCopy = _.cloneDeep(template);
    setTemplate(templateCopy);
    setCategories(getCategories());
  };

  const handleChangeUrl = React.useCallback(
    (index: number, url: string) => {
      const updatedUrls = [...paidUrls];
      updatedUrls[index] = url;
      handleLocalizedInputChange("paidUrls", updatedUrls);
    },
    [paidUrls, handleLocalizedInputChange]
  );

  const handleRemoveUrl = React.useCallback(
    (index: number) => {
      const newUrls = [...paidUrls];
      newUrls[index] = "";
      handleLocalizedInputChange("paidUrls", newUrls);
    },
    [paidUrls, handleLocalizedInputChange]
  );

  /**
   * Handle tab click event.
   */
  function handleTabClick(tab: Tab) {
    const newTabs = tabs.map((t) => ({
      ...t,
      current: t.name === tab.name,
    }));
    setTabs(newTabs);
  }

  function handleTranslationError() {
    const localeErrorEntries = Array.from(localeError.entries());
    const localeErrorObject = localeErrorEntries.find(([, value]) => value);
    if (localeErrorObject) {
      const [key] = localeErrorObject;
      const locale = fromKey(key);
      if (locale) setCurrentLocale(locale);
    }
  }

  /**
   * Update dashboard title.
   */
  function updateDashboardTitle(template: Template) {
    if (template) {
      const title = localizedValue(template.title) || "";
      onUpdateTitle(title);
    }
  }

  /**
   * Cancel template changes.
   */
  const cancelTemplate = () => {
    if (!templateOriginal) return;
    setTemplate(templateOriginal);
    setTemplateOriginal(_.cloneDeep(templateOriginal));
    const locales = getLocales(templateOriginal);
    setCurrentLocale(locales[0] || DEFAULT_LOCALE);
    setCurrentLocales(locales || [DEFAULT_LOCALE]);
    setDataForLocale();
    updateDashboardTitle(templateOriginal);
    updateMonetizationType(templateOriginal);
    setHasChanges(false);
  };

  /**
   * Save template changes.
   */
  const saveTemplate = async () => {
    if (!template) return;
    template.locales = getLocaleKeys(template);
    template.paidUrls = getCleanPaidUrls(template);
    if (template.isPublished) {
      try {
        await updatePublicTemplate(template);
        processNewTemplate(template);
        trackUpdateTemplate();
      } catch (error) {
        trackUpdateTemplateError();
        console.log(error);
      }
    } else {
      try {
        const uid = sessionProfile?.id!!;
        await updateDraftTemplate(uid, template);
        processNewTemplate(template);
        trackUpdateDraftTemplate();
      } catch (error) {
        trackUpdateDraftTemplateError();
        console.log(error);
      }
    }
  };

  /**
   * Process new template.
   * @param template template to process.
   */
  const processNewTemplate = async (template: Template) => {
    setTemplate(template);
    setTemplateOriginal(_.cloneDeep(template));
    updateDashboardTitle(template);
    setHasChanges(false);
  };

  /**
   * Publish template.
   */
  const publishTemplate = async () => {
    if (!template || template.isPublished) return;
    try {
      await publishDraftTemplate(template.id);
      navigate(Destination.ACCOUNT_TEMPLATES);
      trackPublishTemplate();
    } catch (error) {
      trackPublishTemplateError();
      console.log(error);
    }
  };

  /**
   * Delete template.
   */
  const deleteTemplate = async () => {
    if (!template) return;
    if (template.isPublished) {
      try {
        await deleteTemplateById(template.id);
        trackDeleteTemplate();
      } catch (error) {
        trackDeleteTemplateError();
        console.log(error);
      }
    } else {
      const uid = sessionProfile?.id!!;
      try {
        await deleteDraftTemplateById(uid, template.id);
      } catch (error) {
        trackDeleteDraftTemplate();
        console.log(error);
      }
    }
    navigate(Destination.ACCOUNT_TEMPLATES);
  };

  /**
   * Get locales from the given template.
   * @param template template to get locales from.
   * @returns array of locales.
   */
  function getLocaleKeys(template: Template) {
    const supportedLocales = collectLocales(
      template.title,
      template.desc,
      template.templateUrl,
      template.paidUrls || {},
      template.thumbnailImage,
      template.coverImages
    );
    return supportedLocales;
  }

  /**
   * Get locales from the given template.
   * @param template template to get locales from.
   */
  function getLocales(template: Template): SupportedLocale[] {
    const keys = getLocaleKeys(template);
    return keys.map((k) => Object.values(SupportedLocales).find((l) => localeKey(l) === k)) as SupportedLocale[];
  }

  /**
   * Get clean paid urls from the given template. Removes empty urls.
   * @param template template to get paid urls from.
   * @returns object of paid urls.
   */
  function getCleanPaidUrls(template: Template): { [key: string]: string[] } {
    const filteredPaidUrls: { [key: string]: string[] } = {};

    // Populate filteredPaidUrls with non-empty/non-null URLs
    if (template.paidUrls) {
      Object.entries(template.paidUrls).forEach(([key, urls]) => {
        const filteredUrls = urls.filter((url) => url !== "" && url !== null);
        if (filteredUrls.length > 0) filteredPaidUrls[key] = filteredUrls;
      });
    }

    // Iterate over filteredPaidUrls and remove keys with no value or an empty list
    Object.keys(filteredPaidUrls).forEach((key) => {
      console.log(filteredPaidUrls[key]);
      if (!filteredPaidUrls[key] || filteredPaidUrls[key].length === 0 || filteredPaidUrls[key] === null) {
        delete filteredPaidUrls[key];
      }
    });

    return filteredPaidUrls;
  }

  /**
   * Collect all locales from the given objects.
   * @param objects objects to collect locales from.
   * @returns array of locales.
   */
  function collectLocales(...objects: Record<LocaleKey, any>[]): string[] {
    const uniqueKeys = new Set<string>();
    for (const obj of objects) {
      const keys = Object.keys(obj);
      for (const key of keys) {
        uniqueKeys.add(key);
      }
    }
    return Array.from(uniqueKeys);
  }

  if (!isSignedIn || !template) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            You are currently not signed-in.
          </span>
        </div>
      </div>
    );
  }

  const LocaleOptions = () => {
    return (
      <>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-5 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {`${getLocaleDisplayName(currentLocale)} (${localeKey(currentLocale)})`}
              <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="w-72 h-84 overflow-scroll absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <div className="border-b border-gray-100 mt-6 mb-2">
                  <p className="px-4 mb-2 font-semibold text-sm">{t("language")}</p>
                </div>

                {Object.values(currentLocales).map((locale) => (
                  <Menu.Item key={localeKey(locale)}>
                    <a
                      onClick={() => setCurrentLocale(locale)}
                      className={
                        Array.from(localeError).find(([key, value]) => key === localeKey(locale) && value == true)
                          ? classNames(
                              locale == currentLocale ? "bg-red-50 text-red-500 font-medium" : "text-red-500",
                              "cursor-pointer block px-4 py-2 text-sm"
                            )
                          : classNames(
                              locale == currentLocale ? "bg-indigo-50 text-indigo-500 font-medium" : "text-gray-700",
                              "cursor-pointer block px-4 py-2 text-sm"
                            )
                      }
                    >
                      {`${getLocaleDisplayName(locale)} (${localeKey(locale)})`}
                    </a>
                  </Menu.Item>
                ))}

                <div className="border-b border-gray-100 mt-6 mb-2">
                  <p className="px-4 mb-2 font-semibold text-sm">{t("more_languages")}</p>
                </div>

                {Object.values(SupportedLocales)
                  .filter((item) => !currentLocales.includes(item))
                  .map((locale) => (
                    <Menu.Item key={localeKey(locale)}>
                      <a
                        onClick={() => handleAddLocale(locale)}
                        className={classNames(
                          locale == currentLocale ? "bg-indigo-50 text-indigo-500 font-medium" : "text-gray-700",
                          "cursor-pointer block px-4 py-2 text-sm"
                        )}
                      >
                        {`${getLocaleDisplayName(locale)} (${localeKey(locale)})`}
                      </a>
                    </Menu.Item>
                  ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  };

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            localizedValue(template.title)
              ? t("seo:tabs.edit") + localizedValue(template.title)
              : t("seo:account_pages.page_account_template.title") + t("seo:tabs.title_suffix"),
            localizedValue(template.desc) || ""
          )
        }
      />

      {uploadError && <ErrorDialog content={uploadError} />}

      <CategorySelectionDialog
        current={template.categories}
        show={openCategorySelectionDialog}
        onSave={handleChangeCategories}
        onClose={() => setOpenCategorySelectionDialog(false)}
      />

      <ConfirmationDialogComponent
        show={openDeleteConfirmationDialog}
        title={t("page_account.page_template.actions.delete_template_confirmation.title")}
        positiveButtonText={t("common:delete")}
        description={t("page_account.page_template.actions.delete_template_confirmation.description")}
        onNegative={() => setOpenDeleteConfirmationDialog(false)}
        onPositive={() => {
          deleteTemplate();
          setOpenDeleteConfirmationDialog(false);
        }}
      />

      <div>
        <ConfirmationDialogComponent
          show={openPublishConfirmationDialog}
          color="indigo"
          title={t("page_account.page_template.actions.publish_template_confirmation.title")}
          positiveButtonText={t("common:publish")}
          description={t("page_account.page_template.actions.publish_template_confirmation.description")}
          onNegative={() => setOpenPublishConfirmationDialog(false)}
          onPositive={() => {
            publishTemplate();
            setOpenPublishConfirmationDialog(false);
          }}
        />
        <div className="inline items-center">
          <div className="w-full flex items-center">
            {LocaleOptions()}
            {(Array.from(localeError.values()).filter(Boolean).length > 0 || error) && (
              <button
                onClick={() => handleTranslationError()}
                type="button"
                className="ml-4 bg-red-100 rounded-md border border-transparent py-2.5 px-4 text-sm font-medium text-red-600 focus:outline-none"
              >
                <div className="flex items-center">
                  <ExclamationCircleIcon className="h-4 w-4 flex-none mr-2" aria-hidden="true" />
                  <div>
                    <p>
                      {Array.from(localeError.values()).filter(Boolean).length > 1
                        ? t("page_account.page_template.errors_found_multiple")
                        : t("page_account.page_template.errors_found")}
                    </p>
                  </div>
                </div>
              </button>
            )}

            {currentLocales.length > 1 && (
              <button
                onClick={() => handleDeleteLocale(currentLocale)}
                type="button"
                className="ml-4 rounded-full border border-transparent py-2 px-4 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none"
              >
                <div className="flex items-center">
                  <LanguageIcon className="h-4 w-4 flex-none text-gray-800 mr-2" aria-hidden="true" />
                  {t("common:delete")} ({localeKey(currentLocale)})
                </div>
              </button>
            )}

            <div className="ml-auto space-x-4 flex">
              <Transition
                as={Fragment}
                show={hasChanges}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Transition.Child>
                  <ButtonComponent style="structural-text" onClick={() => cancelTemplate()} text={t("common:cancel")} />
                </Transition.Child>
              </Transition>

              <Transition
                as={Fragment}
                show={hasChanges && (template.isDraft || Array.from(localeError.values()).filter(Boolean).length == 0)}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Transition.Child>
                  <ButtonComponent style="structural" onClick={() => saveTemplate()} text={t("common:save")} />
                </Transition.Child>
              </Transition>
            </div>
          </div>

          <div className="mt-8">
            <TabsComponent
              tabs={tabs}
              title={template.isDraft ? t("common:draft") : t("common:published")}
              handleTabClick={handleTabClick}
            />
          </div>

          {tabs.map((tab) => (
            <div key={tab.name}>
              {tab.current && (
                <div className="mt-6 space-y-8">
                  {tab.name === TabType.GENERAL && (
                    <TemplateGeneralSection
                      title={title}
                      desc={desc}
                      templateUrl={templateUrl}
                      categories={categories}
                      errors={errors}
                      handleInputChangeEvent={handleInputChangeEvent}
                      handleLocalizedInputChange={handleLocalizedInputChange}
                      setOpenCategorySelectionDialog={setOpenCategorySelectionDialog}
                    />
                  )}
                  {tab.name === TabType.VISUALS && (
                    <TemplateVisualsSections
                      handleLocalizedCoverImageUpload={handleLocalizedCoverImageUpload}
                      handleLocalizedThumbnailImageUpload={handleLocalizedThumbnailImageUpload}
                      handleLocalizedCoverImagesUpload={handleLocalizedCoverImageUpload}
                      handleCoverImagesOrderChange={handleCoverImagesOrderChange}
                      handleCoverImageDelete={handleCoverImageDelete}
                      thumbnailImage={thumbnailImage}
                      coverImages={coverImages}
                      errors={errors}
                      fileInputRef={fileInputRef}
                      fileInputCoverImagesRef={fileInputCoverImagesRef}
                      handleInputChangeEvent={handleInputChangeEvent}
                      handleLocalizedInputChange={handleLocalizedInputChange}
                    />
                  )}
                  {tab.name === TabType.FEATURES && (
                    <TemplateFeaturesSection
                      templateId={template.id}
                      userId={template.userId}
                      features={features}
                      handleSaveFeature={handleSaveFeature}
                      handleDeleteFeature={handleDeleteFeature}
                    />
                  )}
                  {tab.name === TabType.MONETIZATION && (
                    <TemplateMonetizationSection
                      template={template}
                      monetizations={monetizations}
                      paidUrls={paidUrls}
                      userSecret={userSecret}
                      errors={errors}
                      activeMonetization={activeMonetization}
                      handleInputChange={handleInputChange}
                      handleMonetizationTypeChange={handleMonetizationTypeChange}
                      handleChangeUrl={handleChangeUrl}
                      handleRemoveUrl={handleRemoveUrl}
                    />
                  )}

                  {tab.name === TabType.ACTIONS && (
                    <TemplateActionsSection
                      template={template}
                      hasChanges={hasChanges}
                      localeErrors={localeErrors}
                      setOpenPublishConfirmationDialog={setOpenPublishConfirmationDialog}
                      setOpenDeleteConfirmationDialog={setOpenDeleteConfirmationDialog}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(TemplatePage);
