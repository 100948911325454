import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../../utils/utils-react";
import { Monetization, MonetizationType } from "../TemplateMonetizationSection";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface MonetizationItemProps {
  monetization: Monetization;
  handleMonetizationTypeChange: (type: MonetizationType) => void;
}

const MonetizationItem: React.FC<MonetizationItemProps> = ({ monetization, handleMonetizationTypeChange }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        onClick={() => {
          if (monetization.disabled) return;
          handleMonetizationTypeChange(monetization.type);
        }}
        key={monetization.type.name}
        className={classNames(
          monetization.current ? "bg-white text-indigo-500 cursor-pointer" : "bg-gray-100 cursor-pointer",
          monetization.disabled ? "cursor-not-allowed" : "border border-gray-200 hover:shadow hover:bg-white",
          "transition-all duration-900 flex items-center py-4 px-4 rounded-lg"
        )}
      >
        <monetization.type.icon className={classNames(monetization.current ? "text-indigo-500" : "text-gray-600", "w-6 h-6 inline")} />
        <div className="ml-4">
          <h3 className="font-semibold text-sm text-gray-900">{t(monetization.type.name)}</h3>
          <p className="mt-1 font-normal text-xs text-gray-600">{t(monetization.type.value)}</p>
        </div>
      </div>

      {monetization.disabled && (
        <div className="mt-3 mb-3 py-2 px-2 rounded-md flex items-center bg-red-50">
          <InformationCircleIcon className="h-4 w-4 text-red-500" />
          <p className="ml-2 font-normal text-xs text-red-500">Setup payment profile to enable</p>
        </div>
      )}
    </div>
  );
};

export default MonetizationItem;
