import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPayPalOrderAndPromoteProfile, checkPayPalOrderAndPromoteTemplate } from "../services/functions-service";
import { RootState } from "../store";
import {
  PATH_PURCHASE_PAYPAL_PROFILE_PROMOTION_CANCEL,
  PATH_PURCHASE_PAYPAL_PROFILE_PROMOTION_SUCCESS,
  PATH_PURCHASE_PAYPAL_TEMPLATE_PROMOTION_CANCEL,
  PATH_PURCHASE_PAYPAL_TEMPLATE_PROMOTION_SUCCESS,
  PATH_PURCHASE_STRIPE_MARKETPLACE_TEMPALTE_CANCEL,
  PATH_PURCHASE_STRIPE_MARKETPLACE_TEMPALTE_SUCCESS,
  PATH_PURCHASE_STRIPE_PROFILE_PROMOTION_CANCEL,
  PATH_PURCHASE_STRIPE_PROFILE_PROMOTION_SUCCESS,
  PATH_PURCHASE_STRIPE_TEMPLATE_PROMOTION_CANCEL,
  PATH_PURCHASE_STRIPE_TEMPLATE_PROMOTION_SUCCESS,
} from "../utils/constants";
import { Destination, setPathId } from "../utils/constants-navigation";
import ButtonComponent from "../components/common/buttons/ButtonComponent";

type ProcessTypeState = {
  title: string;
  description?: string;
};

type ProcessResultStateProps = {
  loading: ProcessTypeState;
  success: ProcessTypeState;
  failure: ProcessTypeState;
};

const MarketPlaceTemplatePurchaseStates: ProcessResultStateProps = {
  loading: {
    title: "Your payment is being processed",
    description: "Please wait while we process your payment. Do not close this screen.",
  },
  success: {
    title: "Your purchase was successful",
    description: "You will be redirected to your purchased Notion Template.",
  },
  failure: {
    title: "Your payment was unsuccessful",
    description: "Something went wrong. Please try again.",
  },
};

const PromotionProfilePurchaseStates: ProcessResultStateProps = {
  loading: {
    title: "Your payment is being processed",
    description: "Please wait while we process your payment. Do not close this screen.",
  },
  success: {
    title: "Your purchase was successful",
    description: "Your profile has been promoted. You will be redirected.",
  },
  failure: {
    title: "Your payment was unsuccessful",
    description: "Something went wrong. Please try again.",
  },
};

const PromotionTemplatePurchaseStates: ProcessResultStateProps = {
  loading: {
    title: "Your payment is being processed",
    description: "Please wait while we process your payment. Do not close this screen.",
  },
  success: {
    title: "Your purchase was successful",
    description: "Your profile has been promoted. You will be redirected to your Template promotion.",
  },
  failure: {
    title: "Your payment was unsuccessful",
    description: "Something went wrong. Please try again.",
  },
};

const Purchase = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [processTypeState, setProcessTypeState] = React.useState<ProcessTypeState | null>(null);

  useEffect(() => {
    if (!isSignedIn) {
      navigate(Destination.LOGIN, { replace: true });
    }
  }, [isSignedIn, navigate]);

  function waitProcessing(processingCallback: () => void) {
    setIsProcessing(true);
    const timer = setTimeout(() => {
      processingCallback();
      setIsProcessing(false);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }

  /**
   * This function is called when the user returns from PayPal
   * @param templateId The ID of the template to promote.
   * @param orderId The ID of the order. This is used to verify the payment.
   */
  async function processPayPalTemplatePromotionSuccess() {
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    const templateId = referenceId?.split("-")[1];

    if (!referenceId) {
      // TODO: Handle failure
      return;
    }

    try {
      await checkPayPalOrderAndPromoteTemplate(referenceId);
    } catch (error) {
      console.log(error);
    }
    navigate(setPathId(Destination.ACCOUNT_TEMPLATE_PROMOTION_ID, templateId!!));
  }

  /**
   * This function is called when the user cancels the payment.
   * @param templateId The ID of the template to promote.
   * @param orderId The ID of the order. This is used to verify the payment.
   */
  function processPayPalTemplatePromotionFailure() {
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    const templateId = referenceId?.split("-")[1];
    navigate(setPathId(Destination.ACCOUNT_TEMPLATE_PROMOTION_ID, templateId!!));
  }

  /**
   * This function is called when the user returns from PayPal
   * @param templateId The ID of the template to promote.
   * @param orderId The ID of the order. This is used to verify the payment.
   */
  async function processPayPalProfilePromotionSuccess() {
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    if (!referenceId) {
      console.log("Missing referenceId");
      // TODO: Handle failure
      return;
    }

    try {
      await checkPayPalOrderAndPromoteProfile(referenceId);
    } catch (error) {
      alert("Error promoting template");
      console.log(error);
    }
    navigate(Destination.ACCOUNT_PROFILE_PROMOTION);
  }

  function processPayPalProfilePromotionFailure() {
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    navigate(Destination.ACCOUNT_PROFILE_PROMOTION);
  }

  function processStripeTemplatePromotionSuccess() {
    setProcessTypeState(PromotionTemplatePurchaseStates.loading);
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    const templateId = referenceId?.split("-")[1];
    console.log("processTemplatePromotionFailure: ", referenceId);
    waitProcessing(() => {
      setProcessTypeState(PromotionTemplatePurchaseStates.success);
      try {
        let path;
        if (templateId) {
          path = setPathId(Destination.ACCOUNT_TEMPLATE_PROMOTION_ID, templateId);
        } else {
          navigate(Destination.ACCOUNT_DASHBOARD, { replace: true });
          return;
        }
        navigate(path, { replace: true });
      } catch (error) {
        navigate(Destination.ACCOUNT_DASHBOARD, { replace: true });
      }
    });
  }

  function processStripeTemplatePromotionFailure() {
    setProcessTypeState(PromotionTemplatePurchaseStates.failure);
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    const templateId = referenceId?.split("-")[1];
    waitProcessing(() => {
      setProcessTypeState(PromotionTemplatePurchaseStates.success);
      try {
        let path;
        if (templateId) {
          path = setPathId(Destination.ACCOUNT_TEMPLATE_PROMOTION_ID, templateId);
        } else {
          navigate(Destination.ACCOUNT_DASHBOARD, { replace: true });
          return;
        }
        navigate(path, { replace: true });
      } catch (error) {
        navigate(Destination.ACCOUNT_DASHBOARD, { replace: true });
      }
    });
  }

  function processStripeProfilePromotionSuccess() {
    setProcessTypeState(PromotionProfilePurchaseStates.loading);
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    waitProcessing(() => {
      setProcessTypeState(PromotionProfilePurchaseStates.success);
      navigate(Destination.ACCOUNT_DASHBOARD, { replace: true });
    });
  }

  function processStripeProfilePromotionFailure() {
    setProcessTypeState(PromotionProfilePurchaseStates.failure);
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    waitProcessing(() => {
      navigate(Destination.ACCOUNT_DASHBOARD, { replace: true });
    });
  }

  function processStripeMarketplaceTemplateSuccess() {
    setProcessTypeState(MarketPlaceTemplatePurchaseStates.loading);
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    waitProcessing(() => {
      setProcessTypeState(MarketPlaceTemplatePurchaseStates.success);
      try {
        let path;
        if (referenceId) {
          path = setPathId(Destination.PURCHASE_MARKETPLACE_TEMPLATE_REFERENCE_ID, referenceId);
        } else {
          navigate(Destination.DISCOVER, { replace: true });
          return;
        }
        navigate(path, { replace: true });
      } catch (error) {
        navigate(Destination.DISCOVER, { replace: true });
      }
    });
  }

  function processStripeMarketplaceTemplateFailure() {
    setProcessTypeState(MarketPlaceTemplatePurchaseStates.failure);
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    const templateId = referenceId?.split("-")[1];
    waitProcessing(() => {
      try {
        let path;
        if (templateId) {
          path = setPathId(Destination.TEMPLATE_ID_SHORT, templateId);
        } else {
          navigate(Destination.DISCOVER, { replace: true });
          return;
        }
        navigate(path, { replace: true });
      } catch (error) {
        navigate(Destination.DISCOVER, { replace: true });
      }
    });
  }

  React.useEffect(() => {
    const path = location.pathname;
    const pathCases: { [key: string]: () => void } = {
      [PATH_PURCHASE_PAYPAL_TEMPLATE_PROMOTION_SUCCESS]: processPayPalTemplatePromotionSuccess,
      [PATH_PURCHASE_PAYPAL_TEMPLATE_PROMOTION_CANCEL]: processPayPalTemplatePromotionFailure,
      [PATH_PURCHASE_PAYPAL_PROFILE_PROMOTION_SUCCESS]: processPayPalProfilePromotionSuccess,
      [PATH_PURCHASE_PAYPAL_PROFILE_PROMOTION_CANCEL]: processPayPalProfilePromotionFailure,
      [PATH_PURCHASE_STRIPE_TEMPLATE_PROMOTION_SUCCESS]: processStripeTemplatePromotionSuccess,
      [PATH_PURCHASE_STRIPE_TEMPLATE_PROMOTION_CANCEL]: processStripeTemplatePromotionFailure,
      [PATH_PURCHASE_STRIPE_PROFILE_PROMOTION_SUCCESS]: processStripeProfilePromotionSuccess,
      [PATH_PURCHASE_STRIPE_PROFILE_PROMOTION_CANCEL]: processStripeProfilePromotionFailure,
      [PATH_PURCHASE_STRIPE_MARKETPLACE_TEMPALTE_SUCCESS]: processStripeMarketplaceTemplateSuccess,
      [PATH_PURCHASE_STRIPE_MARKETPLACE_TEMPALTE_CANCEL]: processStripeMarketplaceTemplateFailure,
    };

    const matchingPath = Object.keys(pathCases).find((key) => path.includes(key));

    if (matchingPath) {
      pathCases[matchingPath]();
    } else {
      console.log("Unknown path", path);
    }
  }, [location]);

  // http://localhost:3001/purchase/stripe/marketplace-template/success

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-75 z-50">
      <div className="bg-white p-8 rounded-lg max-w-2xl shadow-lg text-center">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 w-96">Your payment is being processed</h2>
        {isProcessing && (
          <>
            <p className="text-gray-600 mb-8 w-full">
              Please wait while we process your payment.
              <br />
              Do not close this screen.
            </p>
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-indigo-500 border-solid mx-auto mb-4"></div>
          </>
        )}
        {!isProcessing && (
          <div>
            <p className="text-gray-600 mb-8 w-full">If you are not redirected, please click the button below.</p>
            <ButtonComponent style="redirect" text="Go to purchased item" target="_blank" onClick={() => {}} rel="noopener noreferrer" />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(Purchase);
