import { useTranslation } from "react-i18next";

export default function StatsComponent() {
  const { t } = useTranslation();
  const statistics = [
    {
      id: 3,
      name: "common:page_statistics.templates_available",
      value: "300+",
    },
    {
      id: 1,
      name: "common:page_statistics.monthly_users",
      value: "800+",
    },
    {
      id: 2,
      name: "common:page_statistics.creators_supported",
      value: "35+",
    },
  ];
  return (
    <div className="py-16 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {t("common:page_statistics.title")}
            </h2>
            <p className="mt-4 text-md leading-8 text-gray-600 max-w-2xl mx-auto">
              {t("common:page_statistics.subtitle")}
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-4 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-3">
            {statistics.map((stat) => (
              <div
                key={stat.id}
                className="flex flex-col bg-gray-50 rounded-lg p-4 sm:p-5"
              >
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  {t(stat.name)}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
