import {
  BookmarkSquareIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  HeartIcon,
  HomeIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { connect, useSelector } from "react-redux";

import { RootState } from "../../store";
import { Destination, setPathId } from "../../utils/constants-navigation";
import { getProfileId } from "../../utils/utils-profile";
import DashboardComponent, { NavigationGroup, NavigationItem } from "../../components/common/common/DashboardComponent";
import { useTranslation } from "react-i18next";

interface AccountPageProps {
  title?: string;
  titleView?: React.ReactNode;
  children?: React.ReactNode;
}

const AccountPage: React.FC<AccountPageProps> = ({ title, titleView, children }) => {
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const navigation: NavigationItem[] = [
    {
      name: "common:dashboard",
      href: Destination.ACCOUNT_DASHBOARD,
      icon: HomeIcon,
    },
    {
      name: "common:profile",
      href: Destination.ACCOUNT_PROFILE,
      icon: UserCircleIcon,
    },
    {
      name: "common:templates",
      href: Destination.ACCOUNT_TEMPLATES,
      icon: RectangleGroupIcon,
    },
    {
      name: "common:audience",
      href: Destination.ACCOUNT_AUDIENCE,
      icon: UserGroupIcon,
    },
    // {
    //   name: "Analytics",
    //   href: Destination.ACCOUNT_ANALYTICS,
    //   icon: ChartBarIcon,
    // },
  ];

  const bottomNavigation: NavigationItem[] = [
    {
      name: "common:settings",
      href: Destination.ACCOUNT_SETTINGS,
      icon: Cog6ToothIcon,
    },
  ];

  const navigationLists: NavigationItem[] = [
    {
      name: "common:wishlist",
      href: Destination.ACCOUNT_WISHLIST,
      icon: HeartIcon,
    },
    {
      name: "common:library",
      href: Destination.ACCOUNT_LIBRARY,
      icon: BookmarkSquareIcon,
    },
  ];

  const navigationExternal: NavigationItem[] = [
    {
      name: "common:public_profile",
      href: setPathId(Destination.USER_ID_SHORT, getProfileId(userProfile!!)),
      icon: UserCircleIcon,
      external: true,
    },
    {
      name: "common:discover",
      href: Destination.DISCOVER,
      icon: RectangleStackIcon,
      external: true,
    },
    {
      name: "common:templates",
      href: Destination.TEMPLATES,
      icon: RectangleGroupIcon,
      external: true,
    },
    {
      name: "common:creators",
      href: Destination.USERS,
      icon: UsersIcon,
      external: true,
    },
  ];

  const navigationGroups: NavigationGroup[] = [
    {
      name: "common:account",
      items: navigation,
    },
    {
      name: "common:lists",
      items: navigationLists,
    },
    {
      name: "common:elcovia",
      items: navigationExternal,
    },
  ];

  return <DashboardComponent key="account" title={title} titleView={titleView} navigationGroups={navigationGroups} bottomNavigation={bottomNavigation} children={children} />;
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(AccountPage);
