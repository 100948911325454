import { template } from "lodash";
import React, { Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { LikeButtonComponent } from "../../../../components/common/buttons/LikeButtonComponent";
import SocialButtonComponent from "../../../../components/common/buttons/SocialButtonComponent";
import HtmlTemplateTextComponent from "../../../../components/common/html/preview/HtmlTemplateTextComponent";
import LoadingScreenComponent from "../../../../components/common/loading/LoadingScreenComponent";
import SEOComponent, {
  SEOTemplatePage,
} from "../../../../components/common/seo/SEOComponent";
import ProfileMentionComponent from "../../../../components/profile/ProfileMentionComponent";
import { Profile } from "../../../../models/Profile";
import { Template } from "../../../../models/Template";
import {
  likeTemplate,
  likeTemplateUndo,
} from "../../../../services/action-service";
import {
  getDraftTemplateById,
  getTemplateById,
  getUser,
} from "../../../../services/firestore-service";
import { updateTemplateViews } from "../../../../services/functions-service";
import { RootState } from "../../../../store";
import { Args } from "../../../../utils/constants-navigation";
import {
  currentLocaleKey,
  LocaleKey,
  localizedValueWithKey,
} from "../../../../utils/supported-locales";
import { getNotionTemplateId } from "../../../../utils/urls/utils-urls-template";
import { Logger } from "../../../../utils/utils-logging";
import GalleryViewer, { GalleryViewData } from "./GalleryViewer";
import TemplateCoverImageSection from "./TemplateCoverImageSection";
import { TemplateFeaturesSection } from "./TemplateFeaturesSection";
import TemplateFooterSection from "./TemplateFooterSection";
import TemplateHeaderSection from "./TemplateHeaderSection";
import TemplatePurchaseSection from "./TemplatePurchaseSection";
import { useTranslation } from "react-i18next";

const logger = new Logger("TemplateScreen");
interface TemplatePageParams {
  id: string;
  [key: string]: string;
}

interface TemplatePageProps {
  isDiscover: boolean;
}

const TemplatePage = ({ isDiscover }: TemplatePageProps) => {
  const { id } = useParams<TemplatePageParams>();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [locale, setLocale] = React.useState<LocaleKey>(currentLocaleKey());
  const [template, setTemplate] = React.useState<Template | null>(null);
  const [creatorProfile, setCreatorProfile] = React.useState<Profile | null>(
    null
  );
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const sessionProfileLikes = useSelector(
    (state: RootState) => state.user.likes
  );

  const [likeable, setLikeable] = React.useState<boolean>(false);
  const [liked, setLiked] = React.useState<boolean>(true);
  const [showGallery, setShowGallery] = React.useState<GalleryViewData>({
    visible: false,
    images: [],
  });

  React.useEffect(() => {
    if (!template || !isSignedIn) {
      setLikeable(false);
      setLiked(false);
      logger.log("Template or user not signed in");
      return;
    }

    const isLiked = sessionProfileLikes?.includes(template.id) || false;
    logger.log("Template or user signed in", {
      template,
      isSignedIn,
      isLiked,
    });
    setLikeable(true);
    setLiked(isLiked);
  }, [isSignedIn, sessionProfileLikes, template]);

  /**
   * Update current like state.
   */
  function onClickLike() {
    if (!template || !isSignedIn) {
      setLikeable(false);
      setLiked(false);
      logger.log("Template or user not signed in");
      return;
    }
    if (liked) {
      try {
        logger.log("Undo like", template.id);
        likeTemplateUndo(dispatch, sessionProfileLikes || [], template.id);
      } catch (e) {
        logger.logError("Undo like", e);
      }
    } else {
      try {
        logger.log("Like", template.id);
        likeTemplate(dispatch, sessionProfileLikes || [], template.id);
      } catch (e) {
        logger.logError("Like", e);
      }
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [id, location]);

  React.useEffect(() => {
    updateUrl();
  }, [locale]);

  function updateUrl() {
    // Get first params of current URL and replace it with the new ones
    const params = new URLSearchParams(window.location.search);
    params.set(Args.LANG, locale);

    // Update URL without reloading the page
    const neUrl = `${window.location.pathname}?${params.toString()}`;
    const queryString = params.toString();
    const newUrl = queryString
      ? `${window.location.pathname}?${queryString}`
      : window.location.pathname;
    window.history.replaceState({ path: newUrl }, "", neUrl);
  }

  /**
   * Update the locale from the URL
   */
  function updateLocaleFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get(Args.LANG);
    setLocale(lang ? lang : currentLocaleKey());
  }

  async function loadTemplate() {
    let actualId = getNotionTemplateId(id);
    logger.log("loadTemplate: Loading template", {
      id,
      actualId,
    });
    if (!actualId) {
      logger.log("loadTemplate: No template ID found");
      return;
    }
    try {
      const template = await getTemplateById(actualId);
      logger.log("Template", template);
      const key =
        template.locales.find((l) => l === currentLocaleKey()) ||
        template.locales[0];
      setLocale(key);
      setTemplate(template);
      updateTemplateViews(template.id);
    } catch (e) {
      const paths = location.pathname.split("/");
      if (isSignedIn && paths.includes("preview")) {
        const uid = sessionProfile!!.id;
        const result = await getDraftTemplateById(uid, actualId);
        logger.log("Draft template", result);
        const key =
          result.locales.find((l) => l === currentLocaleKey()) ||
          result.locales[0];
        setLocale(key);
        setTemplate(result);
      }
    }
  }

  React.useEffect(() => {
    if (template === null) {
      loadTemplate();
      updateLocaleFromUrl();
    }
  }, [id]);

  React.useEffect(() => {
    if (template !== null && creatorProfile === null) {
      const fetchCreatorProfile = async (creatorId: string) => {
        const result = await getUser(creatorId);
        setCreatorProfile(result);
      };

      fetchCreatorProfile(template.userId);
    }
  }, [id, template]);

  if (!template || !creatorProfile) {
    return <LoadingScreenComponent />;
  }

  interface ActionPanelProps {
    inGrid: boolean;
  }

  const ActionPanel = ({ inGrid }: ActionPanelProps) => {
    return (
      <div className="">
        <TemplatePurchaseSection
          template={template}
          inGrid={inGrid}
          locale={locale}
        />

        <h2 className="mb-3 mt-8 text-md font-semibold leading-6 text-gray-900">
          {t("common:creator")}
        </h2>
        <ProfileMentionComponent
          isDiscover={isDiscover}
          profile={creatorProfile}
        />

        {creatorProfile.links.length > 0 && (
          <div>
            <h2 className="mt-8 text-md font-semibold leading-6 text-gray-900">
              {t("common:links")}
            </h2>
            <div className="space-y-3">
              {creatorProfile.links?.map((url) => (
                <SocialButtonComponent
                  parentClassNames="inline-block mr-3"
                  target="_blank"
                  key={url}
                  rel="noopener noreferrer"
                  href={url}
                />
              ))}
            </div>
          </div>
        )}
        {likeable && (
          <div className="mt-8 space-y-3">
            <h2 className="text-md font-semibold leading-6 text-gray-900">
              {t("common:your_account")}
            </h2>
            <div>
              <LikeButtonComponent
                className="w-full"
                active={!liked}
                onClick={() => onClickLike()}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <SEOComponent
        seo={new SEOTemplatePage(template, creatorProfile, locale)}
      />
      <div className="mx-auto max-w-2xl lg:max-w-6xl pb-32">
        <div>
          <GalleryViewer
            data={showGallery}
            onClose={() =>
              setShowGallery({
                visible: false,
                images: [],
              })
            }
          />

          {/* Product info */}
          <div>
            <TemplateCoverImageSection
              coverImages={template.coverImages}
              title={template.title}
              locale={locale}
              onViewImages={(images) => {
                setShowGallery({
                  visible: true,
                  images: images,
                });
              }}
            />

            {/* Product details */}
            <div className="mt-12 mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-12 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                <TemplateHeaderSection template={template} locale={locale} />
                <div className="mt-6" />
                <HtmlTemplateTextComponent
                  html={localizedValueWithKey(template.desc, locale) || ""}
                />
              </div>
              <div className="hidden lg:block rounded-md bg-gray-50 border border-gray-200 py-8 px-8 lg:col-start-3 lg:row-end-1 max-w-lg w-full">
                <ActionPanel inGrid={false} />
              </div>
            </div>

            <TemplateFeaturesSection
              locale={locale}
              features={template.features}
            />
            <div className="block lg:hidden mt-16">
              <ActionPanel inGrid={true} />
            </div>

            <div className="mt-16" />
            <TemplateFooterSection
              isDiscover={isDiscover}
              template={template}
              locale={locale}
              onUpdateLocale={setLocale}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(TemplatePage);
