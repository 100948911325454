import { XMarkIcon } from "@heroicons/react/20/solid";
import SocialButtonComponent from "../../../../components/common/buttons/SocialButtonComponent";
import TextFieldComponent from "../../../../components/common/input/TextFieldComponent";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Hint } from "../TemplatePage";
import { useTranslation } from "react-i18next";

type PaidUrlInputProps = {
  url: string;
  onChangeUrl: (url: string) => void;
  onRemoveUrl: () => void;
  error: any;
};

const PaidUrlInput: React.FC<PaidUrlInputProps> = React.memo(({ url, onChangeUrl, onRemoveUrl, error }) => {
  const { t } = useTranslation();
  const inputRef = React.useRef(null);
  const [key, setKey] = React.useState(uuidv4());
  const [localUrl, setLocalUrl] = React.useState(url);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value;
    setLocalUrl(newUrl);
    // onChangeUrl(newUrl);
  };

  return (
    <>
      <div>
        <div className="flex">
          <div className="w-96">
            <TextFieldComponent
              ref={inputRef}
              id={`url-input-${key}-${localUrl}`}
              type="text"
              onMouseLeave={() => {
                onChangeUrl(localUrl);
              }}
              value={localUrl}
              onChange={(e) => handleInputChange(e)}
              additionalClassName="w-96"
              placeholder={localUrl || "https://..."}
            />
          </div>
          {localUrl && localUrl.length > 0 && (
            <>
              <div className="min-w-96">
                <SocialButtonComponent prefix={"common:buy_on_x"} parentClassNames="ml-4 block" target="_blank" rel="noopener noreferrer" href={localUrl} />
              </div>
              <button
                onClick={() => onRemoveUrl()}
                type="button"
                className="ml-4 rounded-full border border-transparent py-2 px-4 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none"
              >
                <div className="flex items-center">
                  <XMarkIcon className="h-4 w-4 flex-none text-gray-800 mr-2" aria-hidden="true" />
                  {t("common:remove")}
                </div>
              </button>
            </>
          )}
        </div>
        {error == Hint.NON_AFFILIATE && <p className="mt-2 text-xs text-red-500">{t("page_account.page_template.monetization.external.link_error_non_affiliate")}</p>}
        {error == true && <p className="mt-2 text-xs text-red-500">{t("page_account.page_template.monetization.external.link_error_invalid")}</p>}
      </div>
    </>
  );
});

export default PaidUrlInput;
