// FooterLegalComponent.tsx
import React from "react";
import { Destination } from "../../../utils/constants-navigation";
import { classNames } from "../../../utils/utils-react";
import { useTranslation } from "react-i18next";
import CustomLink from "../../common/links/CustomLink";

interface LegalLink {
  href: string;
  text: string;
}

type FooterLegalComponentProps = {
  classNames?: string;
};

const FooterLegalComponent: React.FC<FooterLegalComponentProps> = ({ classNames }) => {
  const { t } = useTranslation();
  const legalLinks: LegalLink[] = [
    {
      href: Destination.TERMS_AND_CONDITIONS,
      text: t("common:footer_legal.terms_and_conditions"),
    },
    {
      href: Destination.PRIVACY_POLICY,
      text: t("common:footer_legal.privacy_policy"),
    },
  ];

  return (
    <div className={classNames || "pt-16 pb-4"}>
      <div className="sm:flex sm:justify-between">
        <p className="text-xs font-light text-gray-600">&copy; 2024 {t("common:footer_legal.copyright")}</p>

        <nav className="mt-8 sm:mt-0">
          <ul className="flex flex-wrap justify-start gap-4 lg:justify-end">
            {legalLinks.map((link, index) => (
              <li key={index}>
                <CustomLink to={link.href} className="text-xs font-light text-gray-600 hover:opacity-75">
                  {link.text}
                </CustomLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FooterLegalComponent;
