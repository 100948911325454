import "swiper/css";

import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { BanknotesIcon, HandRaisedIcon, SparklesIcon, Square2StackIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import SearchButtonComponent from "../../../../components/common/buttons/SearchButtonComponent";
import SelectionButtonComponent from "../../../../components/common/buttons/SelectionButtonComponent";
import LoadingScreenComponent from "../../../../components/common/loading/LoadingScreenComponent";
import HeaderH1Item from "../../../../components/common/page/HeaderH1Item";
import SEOComponent, { SEOPage } from "../../../../components/common/seo/SEOComponent";
import { FeatureSection, FeatureSectionType } from "../../../../components/promotion/FeatureSection";
import TemplateItemComponent from "../../../../components/template/TemplateItem";
import { Category } from "../../../../models/Category";
import { Template } from "../../../../models/Template";
import {
  getDownloadsTemplates,
  getLatestTemplates,
  getLikesTemplates,
  getViewsTemplates,
} from "../../../../services/firestore-service";
import { RootState } from "../../../../store";
import { TEMPLATES_AMOUNT } from "../../../../utils/constants";
import {
  getDefaultTemplatesSortOptions,
  getDefaultTemplatesTimeOptions,
  SortKey,
  SortOption,
  TimeOption,
} from "../../../../utils/utils-filter";
import { Logger } from "../../../../utils/utils-logging";
import PeriodId from "../../../../utils/utils-period";
import { Headline } from "./headline/Headline";
import { getTemplatesHeadline } from "./headline/templates-headline-utils";
import {
  updateFiltersFromUrl,
  updateSearchFromUrl,
  updateSortOptionsFromUrl,
  updateTimeOptionsFromUrl,
  updateUrl,
} from "./templates-page-urls";
import { Filter } from "./Types";

const logger = new Logger("TemplatesPage");

let initialSearch: string | null = null;
let initialTimeOptions: TimeOption[] = getDefaultTemplatesTimeOptions();
let initialSortOptions: SortOption[] = getDefaultTemplatesSortOptions();
let initialFilters: Filter[] = [
  {
    id: "type",
    name: "Type",
    options: [
      { value: "all", label: "common:filters:template_types.all", checked: true, icon: Square2StackIcon },
      { value: "free", label: "common:filters:template_types.free", checked: false, icon: HandRaisedIcon },
      { value: "paid", label: "common:filters:template_types.paid", checked: false, icon: BanknotesIcon },
      {
        value: "featured",
        label: "common:filters:template_types.featured",
        checked: false,
        icon: SparklesIcon,
      },
    ],
  },
  {
    id: "category",
    name: "Category",
    options: [
      { value: "", label: "common:filters:no_category", checked: true, ignore: true },
      ...Object.values(Category).map((category) => ({
        value: category.key,
        label: category.title,
        checked: false,
        image: category.image,
      })),
    ],
  },
];

/**
 * Update filters, sort options, time options, and search from URL.
 * This only happens on page load.
 */
function initializeFromUrl() {
  initialFilters = updateFiltersFromUrl(initialFilters);
  initialSortOptions = updateSortOptionsFromUrl(initialSortOptions);
  initialTimeOptions = updateTimeOptionsFromUrl(initialTimeOptions);
  initialSearch = updateSearchFromUrl(initialSearch);
}

const TemplatesPage = () => {
  const { t } = useTranslation();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<Filter[]>(initialFilters);
  const [sortOptions, setSortOptions] = useState<SortOption[]>(initialSortOptions);
  const [currentSort, setCurrentSort] = useState<SortOption | null>(
    initialSortOptions.find((option) => option.current) || null
  );
  const [timeOptions, setTimeOptions] = useState<TimeOption[]>(initialTimeOptions);
  const [currentTime, setCurrentTime] = useState<TimeOption | null>(
    initialTimeOptions.find((option) => option.current) || null
  );
  const [searchValue, setSearchValue] = useState<string | null>(initialSearch);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [headline, setHeadline] = useState<Headline | null>(
    getTemplatesHeadline(
      t,
      sortOptions.find((option) => option.current)!!,
      timeOptions.find((option) => option.current)!!,
      filters.find((filter) => filter.id === "type")?.options.find((option) => option.checked)?.value || "all",
      filters
        .find((filter) => filter.id === "category")
        ?.options.find((option) => option.checked && option.ignore != true)?.label || null
    )
  );
  const [loading, setLoading] = useState(true);
  const [looadingPage, setLoadingPage] = useState(true);

  React.useEffect(() => {
    /**
     * Load templates from the API and update the filtered templates.
     */
    const loadTemplates = async () => {
      setLoading(true);

      const sortOption = Object.values(sortOptions).find((option) => option.current);
      var templates: Template[] = [];

      // Load templates by period id
      const periodId = timeOptions.find((option) => option.current)?.periodId || PeriodId.total();

      // Load templates by stort type (likes, views, downloads)
      const sortKey = sortOption?.key || SortKey.VIEWS;
      switch (sortKey) {
        case SortKey.LIKES:
          templates = await getLikesTemplates(periodId, TEMPLATES_AMOUNT);
          break;
        case SortKey.DOWNLOADS:
          templates = await getDownloadsTemplates(periodId, TEMPLATES_AMOUNT);
          console.log(templates);
          break;
        case SortKey.VIEWS:
          templates = await getViewsTemplates(periodId, TEMPLATES_AMOUNT);
          break;
        case SortKey.LATEST:
          templates = await getLatestTemplates(TEMPLATES_AMOUNT);
          break;
        default:
          templates = await getLikesTemplates(periodId, TEMPLATES_AMOUNT);
          break;
      }

      try {
        // Filter templates by search
        if (searchValue) {
          templates = templates.filter((template) =>
            JSON.stringify(template.title).toLowerCase().includes(searchValue.toLowerCase())
          );
        }

        // Filter templates by type (free, paid, featured)
        const typeFilter = filters.find((filter) => filter.id === "type");
        if (typeFilter) {
          const typeFilterOption = typeFilter.options.find((option) => option.checked);
          if (typeFilterOption) {
            switch (typeFilterOption.value) {
              case "free":
                templates = templates.filter((template) => !template.isPaid);
                break;
              case "paid":
                templates = templates.filter((template) => template.isPaid);
                break;
              case "featured":
                templates = templates.filter((template) => template.promotion != null);
                break;
              default:
                templates = templates;
                break;
            }
          }
        }

        // Filter templates by category
        const categoryFilter = filters.find((filter) => filter.id === "category");
        if (categoryFilter) {
          logger.log("updateUrl() - categoryFilter", categoryFilter);
          const selectedCategory = categoryFilter.options.find(
            (option) =>
              option.checked && option.value !== "" && Object.values(Category).find((c) => c.title === option.label)
          );
          logger.log("updateUrl() - selectedCategory", selectedCategory);
          if (selectedCategory) {
            templates = templates.filter((template) => template.categories.includes(selectedCategory.value));
          }
        }
      } catch (error) {
        console.error("Error loading templates", error);
      }

      const headline = getTemplatesHeadline(
        t,
        sortOptions.find((option) => option.current)!!,
        timeOptions.find((option) => option.current)!!,
        filters.find((filter) => filter.id === "type")?.options.find((option) => option.checked)?.value || "all",
        filters
          .find((filter) => filter.id === "category")
          ?.options.find((option) => option.checked && option.ignore != true)?.label || null
      );
      setHeadline(headline);
      setFilteredTemplates(templates);
      setLoading(false);
      setLoadingPage(false);
    };

    if (looadingPage) {
      initializeFromUrl();
    }

    loadTemplates();
    updateUrl(filters, sortOptions, timeOptions, searchValue);
  }, [filters, timeOptions, sortOptions, searchValue, t]);

  /**
   * Handle filter change event. This is called when a filter is changed.
   * @param id - The ID of the filter that was changed
   * @param value - The value of the filter that was changed
   */
  function handleFilterChange(id: string, value: string) {
    console.log("handleFilterChange", id, value);
    const newFilters = [...filters];
    const filter = newFilters.find((filter) => filter.id === id);
    if (filter) {
      filter.options = filter.options.map((option) => ({
        ...option,
        checked: option.value === value ? !option.checked : false,
      }));
    }
    setFilters(newFilters);
  }

  /**
   * Handle sorting option change event. This is called when a sorting option is changed.
   * @param key - The key of the sorting option that was changed
   */
  function handleSortingOptionChange(newOption: SortOption) {
    console.log("handleSortingOptionChange", newOption);
    const newSortOptions = [...sortOptions];
    newSortOptions.forEach((option) => {
      option.current = option.key === newOption.key || false;
    });
    setCurrentSort(newSortOptions.find((option) => option.current) || null);
    setSortOptions(newSortOptions);
  }

  /**
   * Handle time option change event. This is called when a time option is changed.
   * @param newOption - The key of the time option that was changed
   */
  function handleTimeOptionChange(newOption: TimeOption) {
    console.log("handleTimeOptionChange", newOption);
    const newTimeOptions = [...timeOptions];
    newTimeOptions.forEach((option) => {
      option.current = option.key === newOption.key || false;
    });
    setCurrentTime(newTimeOptions.find((option) => option.current) || null);
    setTimeOptions(newTimeOptions);
  }

  /**
   * Handle search input change event. This is called when the search input is changed.
   * @param event - The event that triggered the change
   */
  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setSearchValue(value);
  }

  if (looadingPage) {
    return <LoadingScreenComponent />;
  } else
    return (
      <>
        <SEOComponent
          seo={
            new SEOPage(
              t("seo:main_pages.page_templates.title") + t("seo:tabs.title_suffix"),
              t("seo:main_pages.page_templates.description")
            )
          }
        />
        <div>
          <div>
            {/* Mobile filter dialog */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
              <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">{t("common:filters")}</h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setMobileFiltersOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      {/* Filters */}
                      <form className="mt-4 border-t border-gray-200">
                        <h3 className="sr-only">Categories</h3>

                        {filters.map((section) => (
                          <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                  <span className="font-medium text-gray-900">{section.name}</span>
                                  <span className="ml-6 flex items-center"></span>
                                </Disclosure.Button>
                              </h3>
                              <div className="pt-6">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center">
                                      <input
                                        id={`filter-mobile-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        defaultChecked={option.checked}
                                        type="radio"
                                        className={
                                          "h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:outline-none"
                                        }
                                      />
                                      <label
                                        htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                        className="ml-3 min-w-0 flex-1 text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          </Disclosure>
                        ))}
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <main>
              <div>
                <div>
                  {headline && <HeaderH1Item title={headline.title} desc={headline.description} />}
                  <div className="mt-6 grid grid-cols-1 items-center md:flex md:gap-x-4 space-x-4 space-y-4 md:space-x-0 md:space-y-0">
                    <div className="flex flex-grow md:flex md:items-center gap-x-4">
                      <SelectionButtonComponent
                        options={sortOptions}
                        onClick={(option, index) => handleSortingOptionChange(sortOptions[index])}
                      />
                      <SelectionButtonComponent
                        options={timeOptions}
                        onClick={(option, index) => handleTimeOptionChange(timeOptions[index])}
                      />
                      {filters.map((filter) => (
                        <SelectionButtonComponent
                          key={filter.id}
                          options={filter.options.map((option) => ({
                            name: t(option.label),
                            current: option.checked,
                            icon: option.icon || undefined,
                            image: option.image || undefined,
                          }))}
                          onClick={(option, index) => {
                            handleFilterChange(filter.id, filter?.options[index].value || "");
                          }}
                        />
                      ))}
                    </div>
                    <SearchButtonComponent
                      baseClassName="lg:ml-auto"
                      value={searchValue || ""}
                      placeholder={t("common:search")}
                      onInputChange={setSearchValue}
                    />
                  </div>
                </div>
              </div>

              <section aria-labelledby="products-heading" className="pb-24 pt-6">
                <h2 id="products-heading" className="sr-only">
                  {t("common:templates")}
                </h2>

                {/* Product grid */}
                <Transition
                  show={filteredTemplates.length > 0}
                  enter="transition ease-out duration-300 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in duration-200 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="mt-6 grid grid-cols-2
                   gap-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4"
                  >
                    {filteredTemplates.map((template: Template) => (
                      <TemplateItemComponent
                        discover={true}
                        detailedBar={false}
                        itemKey="templates-screen"
                        template={template}
                      />
                    ))}
                  </div>
                </Transition>
              </section>
            </main>
          </div>
        </div>
        <FeatureSection type={FeatureSectionType.USERS} />
      </>
    );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(TemplatesPage);
