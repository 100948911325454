import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import PriceTextFieldComponent from "../../../components/common/input/PriceTextFieldComponent";
import SettingsGroupComponent from "../../../components/common/settings/SettingsGroupComponent";
import { getSupportedPaymentPlatformNames } from "../../../models/data/PaymentPlatform";
import { AFFILIATE_EMAIL } from "../../../utils/constants";
import { Destination } from "../../../utils/constants-navigation";
import { getFormattedPrice } from "../../../utils/utils-formatting";
import { UsersIcon } from "@heroicons/react/24/outline";
import { CurrencyDollarIcon } from "@heroicons/react/20/solid";
import React from "react";
import MonetizationItem from "./items/MonetizationItem";
import { UserSecret } from "../../../models/UserSecret";
import { ErrorState } from "./TemplatePage";
import PaidUrlInput from "./items/PaidUrlInput";
import { Template } from "../../../models/Template";
import { Trans, useTranslation } from "react-i18next";

export type MonetizationType = {
  name: string;
  value: string;
  icon: any;
};

export type Monetization = {
  type: MonetizationType;
  current: boolean;
  disabled: boolean;
};

export const MonetizationTypes: { [key: string]: MonetizationType } = {
  SINGLE_PAYMENT: {
    name: "page_account.page_template.monetization.types.single_payment.title",
    value: "page_account.page_template.monetization.types.single_payment.description",
    icon: CurrencyDollarIcon,
  },
  PAY_WHAT_YOU_WANT: {
    name: "page_account.page_template.monetization.types.pay_what_you_want.title",
    value: "page_account.page_template.monetization.types.pay_what_you_want.description",
    icon: CurrencyDollarIcon,
  },
  LEAD_MAGNET: {
    name: "page_account.page_template.monetization.types.lead_magnet.title",
    value: "page_account.page_template.monetization.types.lead_magnet.description",
    icon: UsersIcon,
  },
};

export const initMonetization: Monetization[] = [
  {
    type: MonetizationTypes.SINGLE_PAYMENT,
    current: false,
    disabled: false,
  },
  {
    type: MonetizationTypes.PAY_WHAT_YOU_WANT,
    current: false,
    disabled: false,
  },
  {
    type: MonetizationTypes.LEAD_MAGNET,
    current: false,
    disabled: false,
  },
];

export type TemplateMonetizationSectionProps = {
  template: Template;
  monetizations: Monetization[];
  activeMonetization: Monetization | null;
  paidUrls: string[];
  userSecret: UserSecret | null;
  errors: ErrorState;
  handleMonetizationTypeChange: (type: MonetizationType) => void;
  handleChangeUrl: (index: number, url: string) => void;
  handleRemoveUrl: (index: number) => void;
  handleInputChange: (name: string, value: any) => void;
};

const TemplateMonetizationSection = ({
  template,
  monetizations,
  activeMonetization,
  paidUrls,
  userSecret,
  errors,
  handleMonetizationTypeChange,
  handleChangeUrl,
  handleRemoveUrl,
  handleInputChange,
}: TemplateMonetizationSectionProps) => {
  const { t } = useTranslation();
  const priceRef = React.useRef<HTMLInputElement>(null);
  return (
    <>
      <SettingsGroupComponent
        name="monetization"
        title={t("page_account.page_template.monetization.title")}
        description={t("page_account.page_template.monetization.subtitle")}
        content={
          <>
            <div className="mt-8 grid gap-4 grid-cols-2 max-w-2xl">
              {monetizations.map((monetization) => (
                <MonetizationItem monetization={monetization} handleMonetizationTypeChange={(type) => handleMonetizationTypeChange(type)} />
              ))}
            </div>

            {userSecret?.valid != true && (
              <ButtonComponent
                parentClassNames="mt-6"
                onClick={() => {
                  window.open(Destination.ACCOUNT_SETTINGS, "_blank");
                }}
                text={t("page_account.page_template.monetization.setup_payment_profile")}
                style="redirect"
              />
            )}
            {activeMonetization?.type === MonetizationTypes.SINGLE_PAYMENT && (
              <>
                <div className="mt-12 w-96">
                  <label htmlFor="price" className="block text-sm font-semibold leading-6 text-gray-900">
                    {t("page_account.page_template.monetization.price")}
                  </label>
                  <div className="relative mt-3 w-64">
                    <PriceTextFieldComponent
                      name="price"
                      initialValue={getFormattedPrice(template.price) || ""}
                      onChange={(price) => {
                        handleInputChange("price", price);
                      }}
                      ref={priceRef}
                    />
                  </div>
                  {errors.price && <p className="mt-2 text-xs text-red-500">{t("page_account.page_template.monetization.price_error")}</p>}
                </div>
              </>
            )}

            {activeMonetization?.type === MonetizationTypes.PAY_WHAT_YOU_WANT && (
              <>
                <div className="mt-12 flex gap-6">
                  <div>
                    <label htmlFor="price" className="block text-sm font-semibold leading-6 text-gray-900">
                      {t("page_account.page_template.monetization.minimum_price")}
                    </label>
                    <div className="relative mt-3">
                      <PriceTextFieldComponent
                        name="price"
                        initialValue={getFormattedPrice(template.price) || ""}
                        onChange={(price) => {
                          handleInputChange("price", price);
                        }}
                        ref={priceRef}
                      />
                      {errors.price && <p className="mt-2 text-xs text-red-500">{t("page_account.page_template.monetization.price_error")}</p>}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="priceSuggested" className="block text-sm font-semibold leading-6 text-gray-900">
                      {t("page_account.page_template.monetization.price_suggested")}
                    </label>
                    <div className="relative mt-3">
                      <PriceTextFieldComponent
                        name="priceSuggested"
                        initialValue={getFormattedPrice(template.priceSuggested) || ""}
                        onChange={(price) => {
                          handleInputChange("priceSuggested", price);
                        }}
                        ref={priceRef}
                      />
                      {errors.priceSuggested && <p className="mt-2 text-xs text-red-500">{t("page_account.page_template.monetization.price_suggested_error")}</p>}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        }
      />

      <SettingsGroupComponent
        name="external"
        title={t("page_account.page_template.monetization.external.title")}
        description={t("page_account.page_template.monetization.external.subtitle")}
        info={t("page_account.page_template.monetization.external.info", { platforms: getSupportedPaymentPlatformNames() })}
        content={
          <div className="mt-4 grid gap-y-4 grid-cols-1">
            {paidUrls.map((url, index) => (
              <PaidUrlInput error={errors.paidUrls[index]} url={url} onChangeUrl={(url) => handleChangeUrl(index, url)} onRemoveUrl={() => handleRemoveUrl(index)} />
            ))}
          </div>
        }
      />

      <SettingsGroupComponent
        name="affiliate"
        title={t("page_account.page_template.monetization.affiliate.title")}
        description={t("page_account.page_template.monetization.affiliate.subtitle", { email: AFFILIATE_EMAIL, fee: "10%" })}
        content={
          <>
            <ButtonComponent
              target="_blank"
              style="redirect"
              rel="noopener noreferrer"
              href={Destination.AFFILIATE_GUMROAD_GUIDE}
              parentClassNames="mt-4"
              text={t("page_affiliate_gumroad.title")}
            />
            <p className="mt-4 font-normal leading-6 text-xs bg-red-50 rounded-md text-red-500 px-6 py-3 max-w-xl">
              {t("page_account.page_template.monetization.affiliate.warning")}
            </p>
          </>
        }
      />
    </>
  );
};

export default TemplateMonetizationSection;
