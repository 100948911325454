import { Popover, Transition } from "@headlessui/react";
import { ArrowLongRightIcon, ChevronDownIcon, UserCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import React, { Fragment, MouseEventHandler, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RootState } from "../../../../store";
import { Destination } from "../../../../utils/constants-navigation";
import { classNames } from "../../../../utils/utils-react";
import { useTranslation } from "react-i18next";
import CustomLink from "../../../common/links/CustomLink";

interface HeaderProfileComponentProps {
  linkSectionItems?: LinkSectionItem[];
  actionSectionItems?: ActionSectionItem[];
}

export type LinkSectionItem = {
  name: string;
  description?: string;
  onClick?: () => void;
  href?: string;
  icon: any;
};

export type ActionSectionItem = {
  name: string;
  onClick?: () => void;
  href?: string;
  icon: any;
};

const HeaderProfileComponent: React.FC<HeaderProfileComponentProps> = ({ linkSectionItems, actionSectionItems }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const [isPopupOpened, setPopupOpened] = useState(false);

  if (isSignedIn) {
    return (
      <Popover.Group className="hidden md:flex md:gap-x-12 ">
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button onClick={() => setPopupOpened(true)} className="flex items-center gap-x-1 focus:outline-none bg-gray-200/50 rounded-full">
                <img src={userProfile?.image} alt={userProfile?.name || ""} className="w-8 h-8 rounded-full m-1.5" />
                <h3 className="ml-1 mr-2 text-md font-medium text-gray-800 text-md font-medium text-gray-800">{userProfile?.name}</h3>
                <ChevronDownIcon className={classNames(isPopupOpened ? "rotate-180" : "", "transition-all duration-300 h-5 w-5 mr-3 flex-none text-gray-600")} aria-hidden="true" />
              </Popover.Button>

              <Transition
                show={isPopupOpened}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  onMouseLeave={() => setPopupOpened(false)}
                  className="absolute -right-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                >
                  <div className="p-4">
                    {linkSectionItems &&
                      linkSectionItems.map((item) => (
                        <CustomLink key={item.name} to={item.href || ""} onClick={item.onClick || undefined} className="font-semibold text-sm text-gray-900">
                          <div className="group relative flex items-center gap-x-6 rounded-2xl p-4 leading-6 hover:bg-gray-100/50">
                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-xl bg-gray-100/50 group-hover:bg-transparent">
                              <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                            </div>
                            <div className="flex-auto">
                              <p className="font-semibold text-sm text-gray-900">{t(item.name)}</p>
                              <p className="font-normal text-sm text-gray-500">{item.description && t(item.description)}</p>
                            </div>
                          </div>
                        </CustomLink>
                      ))}
                  </div>
                  {actionSectionItems && (
                    <div className="grid grid-cols-2 border-t border-1 border-gray-100 px-3 py-3 gap-2">
                      {actionSectionItems.map((item) => (
                        <div className="justify-center">
                          <CustomLink
                            key={item.name}
                            to={item.href || ""}
                            target={item.href && item.href.startsWith("http") ? "_blank" : "_self"}
                            onClick={() => setPopupOpened(false)}
                            rel={item.href && item.href.startsWith("http") ? "noopener noreferrer" : ""}
                            className="flex items-center gap-x-3.5 p-3 rounded-full hover:bg-gray-100/50"
                          >
                            <item.icon className="ml-3 h-5 w-5 flex-none text-gray-600" aria-hidden="true" />
                            <p className="font-semibold text-sm text-gray-900">{t(item.name)}</p>
                          </CustomLink>
                        </div>
                      ))}
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </Popover.Group>
    );
  } else if (!isSignedIn && location.pathname != Destination.LOGIN)
    return (
      <CustomLink to={Destination.LOGIN} className="flex items-center gap-x-1 text-sm font-semibold text-gray-800 focus:outline-none bg-gray-200/50 rounded-full">
        <UserCircleIcon className={classNames(isPopupOpened ? "rotate-180" : "", "transition-all duration-300 h-5 w-5 ml-4 flex-none text-gray-600")} aria-hidden="true" />
        <h3 className="mr-6 ml-2 my-3">{t("login")}</h3>
      </CustomLink>
    );
  else return null;
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(HeaderProfileComponent);
