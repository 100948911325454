import React from "react";

import { Category, CategoryType } from "../../../models/Category";
import { classNames } from "../../../utils/utils-react";
import { DialogComponent } from "./DialogComponent";
import { useTranslation } from "react-i18next";

interface CategorySelectionDialogParams {
  show: boolean;
  current?: string[];
  onSave: (selected: string[]) => void;
  onClose: () => void;
}

export const CategorySelectionDialog: React.FC<CategorySelectionDialogParams> = ({ show, current, onSave, onClose }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = React.useState<CategoryType[]>(Object.values(Category));
  const [selectedCategories, setSelectedCategories] = React.useState<CategoryType[]>(getInitSelectedCategories(current));

  /**
   * The categories that are currently selected
   * @returns {CategoryType[]} The categories that are currently selected
   */
  function getInitSelectedCategories(current: string[] | undefined): CategoryType[] {
    return (current?.map((key) => Object.values(Category).find((category) => category.key === key)) as CategoryType[]).filter(Boolean) || [];
  }

  /**
   * Handles the selection of a category
   */
  function handleSelection(category: CategoryType) {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((category) => category !== category));
    } else {
      if (selectedCategories.length < 3) {
        setSelectedCategories([...selectedCategories, category]);
      }
    }
  }

  /**
   * Saves the selection and closes the dialog
   */
  function saveSelection() {
    onSave(selectedCategories.map((category) => category.key));
    onClose();
  }

  interface CategoryItemProps {
    category: CategoryType;
    isSelected: boolean;
    handleSelection: (category: CategoryType) => void;
  }

  const CategoryItem: React.FC<CategoryItemProps> = ({ category, isSelected, handleSelection }) => {
    const { t } = useTranslation();
    return (
      <a
        key={category.key}
        onClick={() => handleSelection(category)}
        className={classNames(isSelected ? "bg-indigo-50" : "border border-gray-200", "cursor-pointer group flex items-center px-5 py-3 rounded-lg")}
      >
        {category.image && <div className={classNames(isSelected ? "text-indigo-500" : "text-gray-500", "mr-5 h-5 w-5")}>{/* <category.icon /> */}</div>}
        <div className="text-left">
          <p className={classNames(isSelected ? "text-indigo-500" : "text-gray-900", "text-sm font-semibold")}>{t(category.title)}</p>
          <p className={classNames(isSelected ? "text-indigo-400" : "text-gray-600", "mt-0.5 text-xs font-small")}>{t(category.desc)}</p>
        </div>
      </a>
    );
  };

  return (
    <DialogComponent
      title={t("page_account.page_template.general.category_selection_dialog.title")}
      description={t("page_account.page_template.general.category_selection_dialog.subtitle")}
      content={
        <div className="grid grid-cols-2 gap-4">
          {categories.map((category) => (
            <CategoryItem key={category.key} category={category} isSelected={selectedCategories.includes(category)} handleSelection={handleSelection} />
          ))}
        </div>
      }
      buttons={[
        {
          visible: true,
          text: t("common:cancel"),
          style: "structural-text",
          onClick: () => onClose(),
        },
        {
          visible: true,
          text:
            selectedCategories.length > 0
              ? t("page_account.page_template.general.category_selection_dialog.select_x_categories", { count: selectedCategories.length })
              : t("common:select"),
          style: "structural",
          onClick: () => saveSelection(),
        },
      ]}
      show={show}
      onClose={onClose}
    />
  );
};
