import { ArrowLeftIcon, ArrowLeftOnRectangleIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { Fragment, MouseEventHandler, useState } from "react";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { LinkProps, useLocation, useNavigate } from "react-router-dom";

import { logout } from "../../../../services/action-service";
import { RootState } from "../../../../store";
import { Destination } from "../../../../utils/constants-navigation";
import HeaderProfileComponent, { LinkSectionItem } from "../../header/profile_state/HeaderProfileComponent";
import CustomLink from "../../../common/links/CustomLink";
import { useTranslation } from "react-i18next";

interface DashboardHeaderProps {
  title?: string;
  titleView?: React.ReactNode;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ title, titleView, setSidebarOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  /**
   * Handle logout button click.
   */
  const handleLogout = () => {
    logout(dispatch);
    navigate(Destination.DISCOVER);
  };

  const linkSectionItems: LinkSectionItem[] = [
    {
      name: t("common:page_account.header.elcovia.title"),
      description: t("common:page_account.header.elcovia.description"),
      href: Destination.DISCOVER,
      icon: ArrowLeftIcon,
    },
    {
      name: t("common:page_account.header.logout.title"),
      description: t("common:page_account.header.logout.description"),
      onClick: handleLogout,
      icon: ArrowLeftOnRectangleIcon,
    },
  ];

  return (
    <div className="transition-all ease-in-out duration-300 bg-white fixed top-0 left-0 right-0 z-40 flex h-16 shrink-0 items-center px-r-4 px-6 border-b border-1 border-gray-200">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

      <CustomLink to={Destination.DISCOVER} className="h-full ml-4 border-0">
        <div className="hidden z-20 lg:flex h-16 absolute shrink-0 items-center gap-x-4">
          <img className="h-6 w-6" src={process.env.PUBLIC_URL + "/logo.png"} alt="Elcovia Logo" />
          <img className="h-6 w-auto" src={process.env.PUBLIC_URL + "/text-logo.png"} alt="Elcovia Text Logo" />
        </div>
      </CustomLink>
      <div className="lg:pl-48 flex flex-1 gap-x-4 self-stretch lg:gap-x-6 ">
        <h1 className="px-6 sm:px-16 md:px-16 lg:px-16 xl:px-24 3xl-px-32 flex items-center text-xl font-semibold tracking-tight text-gray-800">
          {title} {titleView}
        </h1>

        <div className="ml-auto flex items-center gap-x-4 lg:gap-x-6">
          <HeaderProfileComponent linkSectionItems={linkSectionItems} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(DashboardHeader);
