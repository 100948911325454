import React from "react";
import { classNames } from "../../../utils/utils-react";
import { useTranslation } from "react-i18next";

type Tab<T extends string> = {
  name: T;
  current: boolean;
};

interface TabComponentProps<T extends string> {
  tabs: Tab<T>[];
  handleTabClick: (tab: Tab<T>) => void;
  title?: string;
}

function TabComponent<T extends string>({ tabs, handleTabClick, title }: TabComponentProps<T>) {
  const { t } = useTranslation();
  const renderTabs = () => (
    <nav className="-mb-px flex space-x-8">
      {tabs.map((tab) => (
        <a
          key={tab.name}
          onClick={() => handleTabClick(tab)}
          className={classNames(
            tab.current ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "cursor-default whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
          )}
          aria-current={tab.current ? "page" : undefined}
        >
          {t(tab.name)}
        </a>
      ))}
    </nav>
  );

  if (title) {
    return (
      <div className="border-b border-gray-200">
        <div className="sm:flex sm:items-baseline">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{t(title)}</h3>
          <div className="mt-4 sm:ml-10 sm:mt-0">{renderTabs()}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-0">
      <div className="border-b border-gray-200">
        <div className="sm:flex sm:items-baseline">
          <div className="mt-4 sm:mt-0">{renderTabs()}</div>
        </div>
      </div>
    </div>
  );
}

export default TabComponent;
