import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";

import { Category, CategoryType } from "../../../models/Category";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import ButtonComponent from "../buttons/ButtonComponent";
import { useTranslation } from "react-i18next";

interface ConfirmationDialogParams {
  show: boolean;
  title: string;
  description: string;
  positiveButtonText?: string;
  negativeButtonText?: string;
  noButtons?: boolean;
  onPositive?: () => void;
  onNegative?: () => void;
  color?: string;
}

export const ConfirmationDialogComponent: React.FC<ConfirmationDialogParams> = ({
  show,
  title,
  description,
  positiveButtonText,
  negativeButtonText,
  noButtons,
  onPositive,
  onNegative,
  color,
}) => {
  const { t } = useTranslation();
  const cancelButtonRef = React.useRef(null);
  const actualColor = color ? color : "red";

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={() => onNegative && onNegative()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                {/* Dialog Header Component */}
                <div className="flex items-center py-8 px-6 gap-x-3">
                  <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${actualColor}-100 sm:mx-0 sm:h-10 sm:w-10`}>
                    <ExclamationTriangleIcon className={`h-6 w-6 text-${actualColor}-600`} aria-hidden="true" />
                  </div>
                  <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <p className="mt-1 text-sm text-gray-600">{description}</p>
                  </div>
                </div>
                {/* Footer Component */}
                {!noButtons && (
                  <div className="justify-end flex border-t border-gray-200 p-4 gap-x-4">
                    <ButtonComponent onClick={() => onNegative && onNegative()} type="button" style="structural-text" text={negativeButtonText || t("common:cancel")} />
                    <ButtonComponent onClick={() => onPositive && onPositive()} type="button" style="structural" text={positiveButtonText || t("common:ok")} />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
