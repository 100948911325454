import { AcademicCapIcon, CheckBadgeIcon, SparklesIcon } from "@heroicons/react/24/outline";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import BadgeComponent from "../../../../components/common/BadgeComponent";
import ButtonComponent from "../../../../components/common/buttons/ButtonComponent";
import SocialButtonComponent from "../../../../components/common/buttons/SocialButtonComponent";
import HeaderSectionsItemComponent from "../../../../components/common/HeaderSection";
import SEOComponent, { SEOProfilePage } from "../../../../components/common/seo/SEOComponent";
import TemplateItemComponent from "../../../../components/template/TemplateItem";
import { Profile } from "../../../../models/Profile";
import { Template } from "../../../../models/Template";
import { getUser, getUserPublicTemplates } from "../../../../services/firestore-service";
import { updateProfileViews } from "../../../../services/functions-service";
import { RootState } from "../../../../store";
import { Destination } from "../../../../utils/constants-navigation";
import { useTranslation } from "react-i18next";

interface ProfileProps {
  discover?: boolean;
}

interface ProfileScreenParams {
  id: string;
  [key: string]: string;
}

const ProfileScreen = ({ discover }: ProfileProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams<ProfileScreenParams>();
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [profile, setProfile] = React.useState<Profile | null>(null);
  const [templates, setTemplates] = React.useState<Template[]>([]);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [id, location]);

  React.useEffect(() => {
    if (profile != null && templates.length === 0) {
      const fetchTemplates = async () => {
        if (!profile.id) return;
        const result = await getUserPublicTemplates(profile.id);
        setTemplates(result);
      };
      fetchTemplates();
    }

    if (profile == null) {
      const fetchUser = async () => {
        if (!id) return;
        const profile = await getUser(id);
        setProfile(profile);
        if (profile && profile.id != sessionProfile?.id) {
          updateProfileViews(profile.id);
        }
      };
      fetchUser();
    }
  }, [id, profile]);

  if (!profile) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOComponent seo={new SEOProfilePage(profile)} />
      <div className="mx-auto max-w-2xl lg:max-w-7xl min-h-screen">
        {/* Product info */}
        <div>
          <div className="mt-8 lg:pr-8 mb-32">
            <div className="flex items-center">
              <img src={profile.image} alt={profile.name || ""} className="aspect-[1/1] w-14 h-14 md:w-16 md:h-16 lg:w-24 lg:h-24  rounded-full drop-shadow max" />
              <div className="ml-8">
                <div className="flex flex-inline items-center">
                  <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{profile.name}</h1>
                </div>
                <p className="mt-1 text-sm text-gray-600">{profile.profileId ? "@" + profile.profileId : ""}</p>
              </div>
              {sessionProfile?.id == profile.id && (
                <ButtonComponent style="structural" parentClassNames="ml-auto mt-1 inline-block" href={Destination.ACCOUNT_PROFILE} text="Edit profile" />
              )}
            </div>
            {/* <div className="mt-6">
              <ProfileStatisticItemComponent
                className="mt-2"
                profile={profile}
              />
            </div> */}
            <div className="mt-6 flex gap-4">
              {profile.verified == true && <BadgeComponent text={t("common:verified")} isActive={profile.verified} icon={<CheckBadgeIcon />} />}
              {profile.notionCertified == true && <BadgeComponent text={t("common:notion_certified")} isActive={profile.notionCertified} icon={<AcademicCapIcon />} />}
              {profile.promotion != null && <BadgeComponent text={t("common:featured")} isActive={profile.promotion} icon={<SparklesIcon />} />}
            </div>
            <div className="mt-6">
              <div>
                <div className="space-y-6">
                  <p className="text-base text-gray-900 leading-7 max-w-3xl">{profile.bio}</p>
                </div>
              </div>
            </div>

            <div className="mt-3 space-y-3">
              {profile.links?.map((url) => (
                <SocialButtonComponent parentClassNames="inline-block mr-3" key={url} target="_blank" rel="noopener noreferrer" href={url} />
              ))}
            </div>

            {templates.length > 0 ? (
              <div>
                <HeaderSectionsItemComponent baseClassName="mt-12" title={t("common:templates")} key={"all-templates"} />
                <div
                  className="mt-6 grid grid-span-3 grid-cols-2
                   gap-6 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3"
                >
                  {templates.map((template) => (
                    <TemplateItemComponent detailedBar={false} discover={discover} itemKey={profile.id!!} template={template} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(ProfileScreen);
