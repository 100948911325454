import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../components/common/buttons/ButtonComponent';
import { DashboardH1, DashboardH2 } from '../../../components/common/common/DashboardHeaderComponents';
import { ConfirmationDialogComponent } from '../../../components/common/dialogs/ConfirmationDialog';
import TextFieldComponent from '../../../components/common/input/TextFieldComponent';
import SEOComponent, { SEOPage } from '../../../components/common/seo/SEOComponent';
import SettingsGroupComponent from '../../../components/common/settings/SettingsGroupComponent';
import SettingsHeaderComponent from '../../../components/common/settings/SettingsHeaderComponent';
import SettingsSplitGroupComponent from '../../../components/common/settings/SettingsSplitGroupComponent';
import TabsComponent from '../../../components/common/tabs/TabsComponent';
import { OrderDetails } from '../../../models/data/payment/Order';
import { getLabelOfOrderType, OrderType } from '../../../models/data/payment/OrderType';
import { UserSecret } from '../../../models/UserSecret';
import { setSignedIn, setUserProfile } from '../../../redux/UserActions';
import { deleteFirebaseAccount, getUserOrders, getUserSecretById } from '../../../services/firestore-service';
import { deleteAccountData, getStripeOnboardingLink } from '../../../services/functions-service';
import { RootState } from '../../../store';
import { Destination, setPathId } from '../../../utils/constants-navigation';
import { durationKeyToLabel, timestampToLocalDate } from '../../../utils/utils-formatting';
import { classNames } from '../../../utils/utils-react';

const TabType = {
  STRIPE: "Stripe",
  // DRAFTS: "Drafts",
};

type Tab = {
  name: (typeof TabType)[keyof typeof TabType];
  current: boolean;
};

const initTabs: Tab[] = [
  { name: TabType.STRIPE, current: true },
  // { name: TabType.DRAFTS, current: false },
];

interface SettingsPageProps {}

const SettingsPage: React.FC<SettingsPageProps> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = React.useState(false);

  const [orders, setOrders] = React.useState<OrderDetails[] | null>(null);
  const [userSecret, setUserSecret] = React.useState<UserSecret | null>(null);
  const [tabs, setTabs] = React.useState<Tab[]>(initTabs);

  React.useEffect(() => {
    async function loadUserSecret() {
      try {
        if (!isSignedIn || !sessionProfile) return;
        const userSecret = await getUserSecretById(sessionProfile.id);
        setUserSecret(userSecret);
      } catch (error) {
        console.error(error);
      }
    }
    if (userSecret === null) loadUserSecret();

    /**
     * This function loads the user's orders from the database.
     */
    async function loadOrders() {
      try {
        if (!isSignedIn || !sessionProfile) return;
        const orders = await getUserOrders(sessionProfile.id);
        const completedOrders = orders
          .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
          .filter((order) => order.isCompleted());
        setOrders(completedOrders);
      } catch (error) {
        console.error(error);
      }
    }
    if (orders === null) loadOrders();
  }, []);

  /**
   * Handle tab click event.
   */
  function handleTabClick(tab: Tab) {
    const newTabs = tabs.map((t) => ({
      ...t,
      current: t.name === tab.name,
    }));
    setTabs(newTabs);
  }

  async function openStripeOnboarding() {
    if (!sessionProfile) return;
    const response = await getStripeOnboardingLink();
    window.open(response.data.url);
  }

  /**
   * Deletes the user's account and the account data.
   */
  async function deleteAccount() {
    if (!sessionProfile) return;
    try {
      await deleteAccountData();
      await deleteFirebaseAccount();
      dispatch(setSignedIn(false));
      dispatch(setUserProfile(null));
      navigate(Destination.DISCOVER);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:account_pages.page_account_settings.title") + t("seo:tabs.title_suffix"),
            t("seo:account_pages.page_account_settings.description")
          )
        }
      />
      {/* This `div` block is the main container for the screen. */}
      <ConfirmationDialogComponent
        show={openDeleteConfirmationDialog}
        title="Delete Account"
        positiveButtonText="Delete"
        description="Are you sure you want to delete your account? This includes all your data and cannot be undone."
        onNegative={() => setOpenDeleteConfirmationDialog(false)}
        onPositive={() => {
          deleteAccount();
          setOpenDeleteConfirmationDialog(false);
        }}
      />
      <DashboardH1 title={t("page_account.page_settings.title")} />
      <div className="space-y-12">
        <section>
          <DashboardH2
            title={t("page_account.page_settings.payment_profile.title")}
            subtitle={t("page_account.page_settings.payment_profile.subtitle")}
          />
          <>
            <div className="w-full flex items-top">
              <TabsComponent
                title={t("page_account.page_settings.tabs_title")}
                handleTabClick={handleTabClick}
                tabs={tabs}
              />
            </div>
            {tabs.map((tab) => (
              <div key={tab.name}>
                {tab.current && (
                  <div>
                    {tab.name === TabType.STRIPE && (
                      <div className="mt-8">
                        <div className="bg-white shadow-sm rounded-md border border-gray-200">
                          <div className="px-4 py-5 sm:p-6">
                            <SettingsHeaderComponent
                              withoutBottomPadding={true}
                              title="Stripe"
                              description={t("page_account.page_settings.stripe.subtitle")}
                              content={
                                <>
                                  {userSecret != null && (userSecret?.valid == null || userSecret?.valid == false) && (
                                    <div className="max-w-3xl p-4 bg-red-100 rounded-md mt-3 text-xs text-red-500 leading-5">
                                      {t("page_account.page_settings.stripe.error")}
                                    </div>
                                  )}
                                  {userSecret?.stripeAccountId && (
                                    <SettingsGroupComponent
                                      title={t("page_account.page_settings.stripe.stripe_account_id")}
                                      description={t("page_account.page_settings.stripe.subtitle")}
                                      content={
                                        <>
                                          <TextFieldComponent
                                            id="stripe-account-id"
                                            name="stripe-account-id"
                                            type="text"
                                            required
                                            additionalClassName="mt-2 max-w-xs"
                                            value={userSecret?.stripeAccountId}
                                            placeholder={t("page_account.page_settings.stripe.stripe_account_id")}
                                            disabled
                                          />
                                        </>
                                      }
                                    />
                                  )}
                                  <div className="mt-5">
                                    <ButtonComponent
                                      text={
                                        userSecret == null
                                          ? t("page_account.page_settings.stripe.connect_stripe")
                                          : t("page_account.page_settings.stripe.manage_stripe")
                                      }
                                      onClick={() => openStripeOnboarding()}
                                      style="structural"
                                    />
                                  </div>
                                </>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        </section>
        <section>
          <DashboardH2
            title={t("page_account.page_settings.order_history.title")}
            subtitle={t("page_account.page_settings.order_history.subtitle")}
          />
          <>
            {orders && orders.length > 0 && (
              <div className="max-w-screen-xl">
                <h2 className="text-base font-semibold leading-7 text-gray-900">{t("order")}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500"></p>
                <dl className="mt-3 space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  {orders?.map((order) => (
                    <div className="pt-3 sm:flex items-center">
                      <dt className="sm:w-64 sm:flex-none sm:pr-6">
                        <div className="grid items-start">
                          <p className="text-md font-medium text-gray-900">{getLabelOfOrderType(order.type)}</p>
                          <div className="text-xs font-normal text-gray-500">
                            {timestampToLocalDate(order.timestamp)}
                          </div>
                        </div>
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        {order.durationKey && (
                          <div className="grid items-start">
                            <p className="text-xs font-semibold text-gray-900">
                              {order.price} {order.currency}
                            </p>
                            <div className="text-xs font-normal text-gray-500">
                              / {durationKeyToLabel(t, order.durationKey)}
                            </div>
                          </div>
                        )}
                        <div className="grid items-start w-auto items-center">
                          {order.type == OrderType.TemplatePromotion && (
                            <a
                              href={setPathId(Destination.TEMPLATE_ID, order.referenceId.split("-")[1])}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs font-normal text-gray-500 hover:text-gray-900"
                            >
                              View Template
                            </a>
                          )}
                          {order.type == OrderType.MarketplaceTemplate && (
                            <a
                              href={setPathId(
                                Destination.PURCHASE_MARKETPLACE_TEMPLATE_REFERENCE_ID,
                                order.referenceId
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs font-normal text-gray-500 hover:text-gray-900"
                            >
                              View Purchase
                            </a>
                          )}
                          {order.type == OrderType.ProfilePromotion && (
                            <a
                              href={setPathId(Destination.USER_ID, order.referenceId.split("-")[0])}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs font-normal text-gray-500 hover:text-gray-900"
                            >
                              View Profile
                            </a>
                          )}
                        </div>

                        <div className="grid items-start">
                          <p
                            className={classNames(
                              order.isCompleted() ? "text-green-600" : "text-red-600",
                              "font-semibold text-xs"
                            )}
                          >
                            {order.isCompleted() ? "Success" : order.paypalResponse?.status}
                          </p>
                          <p className="ml-2 font-semibold text-xs">{order.getSource()}</p>
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            )}
          </>
        </section>
        <section>
          <DashboardH2
            title={t("page_account.page_settings.account_settings.title")}
            subtitle={t("page_account.page_settings.account_settings.subtitle")}
          />
          <>
            <SettingsSplitGroupComponent
              name="deleteAccount"
              title={t("page_account.page_settings.account_settings.delete_account.title")}
              description={t("page_account.page_settings.account_settings.delete_account.description")}
              content={
                <ButtonComponent
                  style="structural"
                  onClick={() => setOpenDeleteConfirmationDialog(true)}
                  text={t("delete")}
                />
              }
            />
          </>
        </section>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(SettingsPage);
