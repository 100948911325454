import { PlusCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { CategoryLabel } from "../../../components/common/CategoryLabel";
import HtmlEditorComponent, { HtmlInputType } from "../../../components/common/html/input/HtmlEditorComponent";
import TextFieldComponent from "../../../components/common/input/TextFieldComponent";
import SettingsGroupComponent from "../../../components/common/settings/SettingsGroupComponent";
import { TEMPLATE_DESC_MAX_LENGTH, TEMPLATE_DESC_MIN_LENGTH, TEMPLATE_TITLE_MAX_LENGTH, TEMPLATE_TITLE_MIN_LENGTH } from "../../../utils/constants";
import { ErrorState } from "./TemplatePage";
import { Editor } from "react-draft-wysiwyg";
import { CategoryType } from "../../../models/Category";

type TemplateGeneralSectionProps = {
  title: string;
  desc: string;
  templateUrl: string;
  categories: CategoryType[];
  errors: ErrorState;
  handleInputChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLocalizedInputChange: (key: string, value: string) => void;
  setOpenCategorySelectionDialog: (open: boolean) => void;
};

const TemplateGeneralSection = ({
  title,
  desc,
  templateUrl,
  categories,
  errors,
  handleInputChangeEvent,
  handleLocalizedInputChange,
  setOpenCategorySelectionDialog,
}: TemplateGeneralSectionProps) => {
  const { t } = useTranslation();
  const titleRef = React.useRef<HTMLInputElement>(null);
  const editorRef = React.useRef<Editor>(null);
  return (
    <>
      <SettingsGroupComponent
        name="title"
        title={t("page_account.page_template.general.title")}
        description={t("page_account.page_template.general.title_subtitle")}
        content={
          <>
            <div className="w-96">
              <TextFieldComponent
                type="text"
                name="title"
                count={true}
                onChange={handleInputChangeEvent}
                value={title}
                minLength={TEMPLATE_TITLE_MIN_LENGTH}
                maxLength={TEMPLATE_TITLE_MAX_LENGTH}
                ref={titleRef}
              />
            </div>
            {errors.title && (
              <p className="mt-2 text-xs text-red-500">
                {t("page_account.page_template.general.title_error", {
                  min: TEMPLATE_TITLE_MIN_LENGTH,
                  max: TEMPLATE_TITLE_MAX_LENGTH,
                })}
              </p>
            )}
          </>
        }
      />
      <SettingsGroupComponent
        name="desc"
        title={t("page_account.page_template.general.description")}
        description={t("page_account.page_template.general.description_subtitle")}
        content={
          <>
            <div className="-mt-4">
              <HtmlEditorComponent
                ref={editorRef}
                value={desc}
                inputType={HtmlInputType.RICH}
                onChange={(value) => {
                  handleLocalizedInputChange("desc", value);
                }}
              />
            </div>
            {errors.desc && (
              <p className="mt-2 text-xs text-red-500">
                {t("page_account.page_template.general.description_error", {
                  min: TEMPLATE_DESC_MIN_LENGTH,
                  max: TEMPLATE_DESC_MAX_LENGTH,
                })}
              </p>
            )}
          </>
        }
      />
      <SettingsGroupComponent
        name="templateUrl"
        title={t("page_account.page_template.general.template_url")}
        description={t("page_account.page_template.general.template_url_subtitle")}
        content={
          <>
            <div>
              <TextFieldComponent type="text" name="templateUrl" onChange={handleInputChangeEvent} value={templateUrl} placeholder={templateUrl} />
            </div>
            {errors.templateUrl && <p className="mt-2 text-xs text-red-500">{t("page_account.page_template.template_url_error")}</p>}
          </>
        }
      />
      <SettingsGroupComponent
        name="categories"
        title={t("page_account.page_template.general.categories")}
        description={t("page_account.page_template.general.categories_subtitle")}
        content={
          <>
            {!categories ||
              (categories.length == 0 && (
                <button
                  onClick={() => setOpenCategorySelectionDialog(true)}
                  type="button"
                  className="mt-3 rounded-full border border-transparent py-2 px-4 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none"
                >
                  <div className="flex items-center">
                    <PlusCircleIcon className="h-4 w-4 flex-none text-gray-800 mr-2" aria-hidden="true" />
                    {t("page_account.page_template.general.add_category")}
                  </div>
                </button>
              ))}
            {categories && (
              <div className="mt-3 space-x-2 space-y-2">
                {categories.map((category) => (
                  <CategoryLabel key={category.key} content={category} onClick={() => setOpenCategorySelectionDialog(true)} />
                ))}
              </div>
            )}
          </>
        }
      />
    </>
  );
};

export default TemplateGeneralSection;
