import React from "react";
import { useTranslation } from "react-i18next";

export type Stats = {
  name: string;
  value: string;
  rawValue: number;
  Icon: React.ElementType;
  style: string;
};

const StatisticItem: React.FC<Stats> = ({ name, value, Icon, style }) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-lg flex items-center space-x-8 transition-all ease-in-out duration-300 bg-white rounded-lg border border-gray-200/50">
      <div className="divide-y w-full divide-1 divide-gray-200/50">
        <dd className="text-xl font-semibold text-gray-900 inline-flex items-center px-3 py-3">
          <span className={`rounded-lg p-2 ${style}`}>
            <Icon className="h-6 w-6" aria-hidden="true" />
          </span>
          <p className="ml-4">{value}</p>
          <p>
            <dt className="truncate text-sm font-normal text-gray-500 line-clamp-1 px-3 py-2">{t(name)}</dt>
          </p>
        </dd>
      </div>
    </div>
  );
};

type StatsComponentProps = {
  stats: Stats[];
};

const HomeStatisticsSection: React.FC<StatsComponentProps> = ({ stats }) => {
  const sortedStats = stats.sort((a, b) => {
    return b.rawValue >= a.rawValue ? 1 : -1;
  });
  if (stats.length === 0) return null;
  return (
    <div>
      <dl className="flex flex-wrap gap-4">
        {sortedStats.map((item) => (
          <StatisticItem key={item.name} {...item} />
        ))}
      </dl>
    </div>
  );
};

export default HomeStatisticsSection;
