import React from "react";
import { Trans, useTranslation } from "react-i18next";

const FooterPoweredComponent = () => {
  const { t } = useTranslation();
  return (
    <footer aria-label="Site Footer">
      <div className="py-8 sm:px-6 lg:px-8 bg-gray-50">
        <div className="flex items-center gap-x-3">
          <svg
            className="h-5 w-5 fill-current text-gray-900"
            viewBox="0 0 192 192"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M45.1816 76.5692C43.2617 75.5492 42.0017 73.5693 42.0017 71.2893V41.2898C42.0017 39.0098 43.2617 37.0298 45.1816 36.0098L92.5809 9.37027C93.5409 8.65028 94.7409 8.29028 96.0008 8.29028C97.2608 8.29028 98.4608 8.65028 99.4208 9.37027L146.82 36.0098C148.74 37.0298 150 39.0098 150 41.2898V71.2893C150 73.5693 148.74 75.5492 146.82 76.5692L133.795 83.8897L146.818 91.2092C148.738 92.2292 149.998 94.2091 149.998 96.4891V150.488C149.998 152.768 148.738 154.748 146.818 155.768L99.4191 182.408C98.4591 183.128 97.2591 183.488 95.9992 183.488C94.7392 183.488 93.5392 183.128 92.5792 182.408L45.1799 155.768C43.26 154.748 42 152.768 42 150.488V96.4891C42 94.2091 43.26 92.2292 45.1799 91.2092L58.2051 83.8887L45.1816 76.5692ZM70.4317 90.7604L60.2397 96.4891L95.9992 116.589L131.759 96.4891L121.568 90.7613L99.4208 103.209C98.4608 103.929 97.2608 104.289 96.0008 104.289C94.7409 104.289 93.5409 103.929 92.5809 103.209L70.4317 90.7604ZM131.76 41.2898L96.0008 21.1901L60.2414 41.2898L96.0008 61.3895L131.76 41.2898ZM54.0015 51.5496V67.7494L90.0009 88.029V71.7693L54.0015 51.5496ZM137.998 146.948V106.749L101.999 126.969V167.228L137.998 146.948Z"
            />
          </svg>

          <p className="text-sm text-gray-600 font-light">
            <Trans
              i18nKey="common:footer_powered.powered_by"
              components={{
                span: <span className="font-medium text-gray-900" />,
              }}
            />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterPoweredComponent;
