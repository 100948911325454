import { PlusCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DashboardH1, DashboardH2 } from "../../../components/common/common/DashboardHeaderComponents";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import EmptyButtonStateComponent from "../../../components/common/state/EmptyButtonState";
import DashboardTemplateItem from "../../../components/template/account/DashboardTemplateItem";
import { Template } from "../../../models/Template";
import {
  createDraftTemplate,
  getUserDraftTemplates,
  getUserPublicTemplates,
} from "../../../services/firestore-service";
import { trackCreateTemplate, trackCreateTemplateError } from "../../../services/tracking-service";
import { RootState } from "../../../store";
import { Destination, setPathId } from "../../../utils/constants-navigation";

interface TemplatesPageProps {
  onUpdateTitle: (title: string) => void;
}
const TemplatesPage: React.FC<TemplatesPageProps> = ({ onUpdateTitle }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [draftTemplates, setDraftTemplates] = React.useState<Template[]>([]);
  const [publicTemplates, setPublicTemplates] = React.useState<Template[]>([]);

  /**
   * Handle create template event.
   */
  async function createTemplate() {
    try {
      const id = await createDraftTemplate(sessionProfile!!.id);
      navigate(setPathId(Destination.ACCOUNT_TEMPLATES_ID, id));
      trackCreateTemplate();
    } catch (error) {
      trackCreateTemplateError();
      console.error(error);
    }
  }

  React.useEffect(() => {
    const fetchTemplates = async () => {
      const publicTemplates = await getUserPublicTemplates(sessionProfile!!.id);
      const sortedPublicTemplates = publicTemplates.sort((a, b) => b.published!.seconds - a.published!.seconds);
      setPublicTemplates(sortedPublicTemplates);
      const draftTemplates = await getUserDraftTemplates(sessionProfile!!.id);
      const sortedDraftTemplates = draftTemplates.sort((a, b) => b.created!!.seconds - a.created!!.seconds);
      setDraftTemplates(sortedDraftTemplates);
    };
    fetchTemplates();
  }, []);

  const gridClassName =
    "grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-7";

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:account_pages.page_account_templates.title") + t("seo:tabs.title_suffix"),
            t("seo:account_pages.page_account_templates.description")
          )
        }
      />
      <DashboardH1
        title={t("page_account.page_templates.title")}
        subtitle={t("page_account.page_templates.subtitle")}
      />
      <section className="mt-12 space-y-16">
        <section>
          <DashboardH2
            title={t("page_account.page_templates.drafts.title")}
            subtitle={t("page_account.page_templates.drafts.subtitle")}
          />
          <div className={gridClassName}>
            <EmptyButtonStateComponent
              icon={PlusCircleIcon}
              onClick={() => createTemplate()}
              visible={true}
              text={t("page_account.page_templates.create_new")}
            />

            {draftTemplates.map((template) => (
              <DashboardTemplateItem itemKey={template.id!!} template={template} />
            ))}
          </div>
        </section>
        {publicTemplates.length > 0 && (
          <section>
            <DashboardH2
              title={t("page_account.page_templates.published.title")}
              subtitle={t("page_account.page_templates.published.subtitle")}
            />
            <div className={gridClassName}>
              {publicTemplates.map((template) => (
                <DashboardTemplateItem itemKey={template.id!!} template={template} />
              ))}
            </div>
          </section>
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(TemplatesPage);
