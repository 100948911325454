import {
  ArrowDownCircleIcon,
  EyeIcon,
  FaceSmileIcon,
  HeartIcon,
  IdentificationIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { profile } from "console";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DashboardH1, DashboardH2 } from "../../../components/common/common/DashboardHeaderComponents";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import { Profile } from "../../../models/Profile";
import { Template } from "../../../models/Template";
import { getUserByUserId, getUserDraftTemplates, getUserPublicTemplates } from "../../../services/firestore-service";
import { RootState } from "../../../store";
import { Destination } from "../../../utils/constants-navigation";
import { Logger } from "../../../utils/utils-logging";
import PeriodId from "../../../utils/utils-period";
import HomeActionsSection, { Action } from "./HomeActionsSection";
import HomeActivePromotionsItem from "./HomeActivePromotionsSection";
import DraftTemplatesComponent, { DraftTemplate } from "./HomeDraftTemplatesSection";
import HomeProfilePromotionsSection from "./HomeProfilePromotionsSection";
import HomeStatisticsSection, { Stats } from "./HomeStatsticsSection";
import HomeTemplatePromotionsSection, { TemplatePromotion } from "./HomeTemplatePromotionsSection";

interface HomePageProps {}

type ChangeType = "increase" | "decrease" | "neutral";

type TemplatePromotionSuggestionStat = {
  name: string;
  value: string;
  change: string;
  changeType: ChangeType;
};

const HomePage: React.FC<HomePageProps> = ({}) => {
  const logger = new Logger("HomePage");
  const { t } = useTranslation();
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const navigate = useNavigate();
  const [templates, setTemplates] = React.useState<Map<string, Template[]> | null>(null);

  const [actions, setActions] = React.useState<Action[]>([]);
  const [stats, setStats] = React.useState<Stats[]>([]);

  const [profilePromotionSuggestion, setProfilePromotionSuggestion] = React.useState<Profile | null>(null);

  const [templatePromotions, setTemplatePromotionSuggestions] = React.useState<TemplatePromotion[]>([]);

  const [activeTemplatePromotions, setActiveTemplatePromotions] = React.useState<TemplatePromotion[]>([]);

  const [activeProfilePromotion, setActiveProfilePromotion] = React.useState<Profile | null>(null);

  const [draftTemplates, setDraftFinishSuggestions] = React.useState<DraftTemplate[]>([]);

  React.useEffect(() => {
    const fetchTemplates = async () => {
      const publicTemplates = await getUserPublicTemplates(sessionProfile!!.id);
      const sortedPublicTemplates = publicTemplates.sort((a, b) => b.published!.seconds - a.published!.seconds);
      const draftTemplates = await getUserDraftTemplates(sessionProfile!!.id);
      const sortedDraftTemplates = draftTemplates.sort((a, b) => b.created!!.seconds - a.created!!.seconds);
      setTemplates(
        new Map([
          ["Public", sortedPublicTemplates],
          ["Drafts", sortedDraftTemplates],
        ])
      );
    };
    fetchTemplates();
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    updateActions();
    updateStats();
    updateDraftFinishSuggestions();
    updatePromotions();
  }, [templates]);

  /**
   * Updates the actions to be displayed on the home screen.
   */
  function updateActions() {
    const actions = getActions(sessionProfile, templates);
    setActions(actions);
  }

  /**
   * Updates the stats to be displayed on the home screen.
   */
  function updateStats() {
    const stats = getStats(sessionProfile, templates);
    setStats(stats);
  }

  /**
   * Updates the draft finish suggestions to be displayed on the home screen.
   */
  function updateDraftFinishSuggestions() {
    const draftFinishSuggestions = getDraftFinishSuggestions(sessionProfile, templates);
    setDraftFinishSuggestions(draftFinishSuggestions);
  }

  /**
   * Updates the template promotions to be displayed on the home screen. Either as active or suggested.
   */
  async function updatePromotions() {
    const user = await getUserByUserId(sessionProfile!!.id);
    const [suggestions, active] = getTemplatePromotions(user, templates);

    logger.log("updatePromotions", {
      user: user,
      suggestions: suggestions,
      active: active,
      userPromotion: user?.promotion,
      profilePromotionSuggestion: user?.promotion,
    });

    setTemplatePromotionSuggestions(suggestions);
    setProfilePromotionSuggestion(user?.promotion == null ? user : null);
    setActiveTemplatePromotions(active);
    setActiveProfilePromotion(user?.promotion != null ? user : null);
  }

  /**
   * Returns a list of actions to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {Action[]} actions to be displayed on the home screen.
   */
  function getActions(profile: Profile | null, templates: Map<string, Template[]> | null): Action[] {
    const actions = Array<Action>();
    if (!profile || !templates) return actions;

    const switchAllowAll = false;

    if (switchAllowAll || profile.links.length == 0 || profile.bio == null || profile.bio == "") {
      actions.push({
        title: "common:page_account.page_home.actions.setup_profile.title",
        description: "common:page_account.page_home.actions.setup_profile.description",
        href: Destination.ACCOUNT_PROFILE,
        Icon: FaceSmileIcon,
        style: "text-teal-500 bg-teal-50",
      });
    }

    if (switchAllowAll || profile.profileId == null || profile.profileId == "") {
      actions.push({
        title: "common:page_account.page_home.actions.setup_username.title",
        description: "common:page_account.page_home.actions.setup_username.description",
        href: Destination.ACCOUNT_PROFILE,
        Icon: IdentificationIcon,
        style: "text-green-500 bg-green-50",
      });
    }

    const publicTemplates = templates.get("Public") || [];
    const draftTemplates = templates.get("Drafts") || [];

    if (switchAllowAll || (publicTemplates.length == 0 && draftTemplates.length == 0)) {
      actions.push({
        title: "common:page_account.page_home.actions.create_template.title",
        description: "common:page_account.page_home.actions.create_template.description",
        href: Destination.ACCOUNT_TEMPLATES,
        Icon: RectangleGroupIcon,
        style: "text-purple-500 bg-purple-50",
      });
    }

    if (switchAllowAll || (draftTemplates.length == 0 && publicTemplates.length > 2)) {
      actions.push({
        title: "common:page_account.page_home.actions.create_template.title",
        description: "common:page_account.page_home.actions.create_template.description",
        href: Destination.ACCOUNT_TEMPLATES,
        Icon: RectangleGroupIcon,
        style: "text-purple-500 bg-purple-50",
      });
    }

    if (publicTemplates.length > 0) {
      const views = publicTemplates.reduce((total, template) => total + (template.views["total"] || 0), 0);
      const likes = publicTemplates.reduce((total, template) => total + (template.likes["total"] || 0), 0);

      const downloads = publicTemplates.reduce((total, template) => total + (template.downloads["total"] || 0), 0);

      if (switchAllowAll || likes == 0) {
        actions.push({
          title: "common:page_account.page_home.actions.get_your_first_likes.title",
          description: "common:page_account.page_home.actions.get_your_first_likes.description",
          href: Destination.ACCOUNT_TEMPLATES,
          Icon: HeartIcon,
          style: "text-red-500 bg-red-50",
        });
      }
      if (switchAllowAll || views == 0) {
        actions.push({
          title: "common:page_account.page_home.actions.get_your_first_downloads.title",
          description: "common:page_account.page_home.actions.get_your_first_downloads.description",
          href: Destination.ACCOUNT_TEMPLATES,
          Icon: ArrowDownCircleIcon,
          style: "text-yellow-500 bg-yellow-50",
        });
      }
      if (switchAllowAll || downloads == 0) {
        actions.push({
          title: "common:page_account.page_home.actions.get_your_first_views.title",
          description: "common:page_account.page_home.actions.get_your_first_views.description",
          href: Destination.ACCOUNT_TEMPLATES,
          Icon: EyeIcon,
          style: "text-sky-500 bg-sky-50",
        });
      }
    }
    return actions;
  }

  /**
   * Returns a list of stats to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {Stats[]} stats to be displayed on the home screen.
   */
  function getStats(profile: Profile | null, templates: Map<string, Template[]> | null): Stats[] {
    const stats = Array<Stats>();
    if (!profile || !templates) return stats;

    const formatter = new Intl.NumberFormat();

    const publicTemplates = templates.get("Public") || [];
    const draftTemplates = templates.get("Drafts") || [];

    const views = publicTemplates.reduce((total, template) => total + (template.views["total"] || 0), 0);
    const likes = publicTemplates.reduce((total, template) => total + (template.likes["total"] || 0), 0);

    const downloads = publicTemplates.reduce((total, template) => total + (template.downloads["total"] || 0), 0);

    stats.push({
      name: "common:likes",
      value: `${formatter.format(likes)}`,
      rawValue: likes,
      Icon: HeartIcon,
      style: "text-red-500 bg-red-50",
    });

    stats.push({
      name: "common:downloads",
      value: `${formatter.format(downloads)}`,
      rawValue: downloads,
      Icon: ArrowDownCircleIcon,
      style: "text-yellow-500 bg-yellow-50",
    });

    stats.push({
      name: "common:views",
      value: `${formatter.format(views)}`,
      rawValue: views,
      Icon: EyeIcon,
      style: "text-sky-500 bg-sky-50",
    });

    stats.push({
      name: "common:published",
      value: `${formatter.format(publicTemplates.length)}`,
      rawValue: publicTemplates.length,
      Icon: RectangleGroupIcon,
      style: "text-green-500 bg-green-50",
    });

    stats.push({
      name: "common:drafts",
      value: `${formatter.format(draftTemplates.length)}`,
      rawValue: draftTemplates.length,
      Icon: RectangleGroupIcon,
      style: "text-gray-500 bg-gray-50",
    });

    return stats;
  }

  const calculatePercentChange = (thisMonth: number, lastMonth: number): number => {
    if (isNaN(lastMonth) || isNaN(thisMonth)) {
      return 0;
    }
    if (lastMonth < 0) lastMonth = 0;

    if (lastMonth === 0) {
      if (thisMonth === 0) return 0;
      else return 1;
    }
    if (thisMonth === 0) {
      thisMonth = 1;
    }

    return (thisMonth - lastMonth) / Math.abs(lastMonth);
  };

  /**
   * Returns a list of template promotion suggestions to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {TemplatePromotion[]} Template promotion suggestions to be displayed on the home screen.
   */
  function getTemplatePromotions(profile: Profile | null, templates: Map<string, Template[]> | null): any[] {
    const suggestions: TemplatePromotion[] = [];
    const active: TemplatePromotion[] = [];

    if (!templates) return [suggestions, active];

    const publicTemplates = templates.get("Public") || [];

    const formatter = new Intl.NumberFormat();
    const percentageFormatter = new Intl.NumberFormat(undefined, {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    for (let template of publicTemplates) {
      const stats = Array<TemplatePromotionSuggestionStat>();

      const thisMonthkey = PeriodId.month().getKey();
      const thisMonthViews = template.views[thisMonthkey] || 0;
      const thisMonthLikes = template.likes[thisMonthkey] || 0;
      const thisMonthDownloads = template.downloads[thisMonthkey] || 0;

      const lastMonthkey = PeriodId.lastMonth().getKey();
      const lastMonthViews = template.views[lastMonthkey] || 0;
      const lastMonthLikes = template.likes[lastMonthkey] || 0;
      const lastMonthDownloads = template.downloads[lastMonthkey] || 0;

      const changeInViewsPercent = calculatePercentChange(thisMonthViews, lastMonthViews);
      const changeInLikesPercent = calculatePercentChange(thisMonthLikes, lastMonthLikes);
      const changeInDownloadsPercent = calculatePercentChange(thisMonthDownloads, lastMonthDownloads);

      stats.push({
        name: "common:likes",
        value: `${formatter.format(lastMonthLikes)} ‣ ${formatter.format(thisMonthLikes)}`,
        change: percentageFormatter.format(changeInLikesPercent),
        changeType: changeInLikesPercent > 0 ? "increase" : changeInLikesPercent < 0 ? "decrease" : "neutral",
      });

      stats.push({
        name: "common:downloads",
        value: `${formatter.format(lastMonthDownloads)} ‣ ${formatter.format(thisMonthDownloads)}`,
        change: percentageFormatter.format(changeInDownloadsPercent),
        changeType: changeInDownloadsPercent > 0 ? "increase" : changeInDownloadsPercent < 0 ? "decrease" : "neutral",
      });

      stats.push({
        name: "Views",
        value: `${formatter.format(lastMonthViews)} ‣ ${formatter.format(thisMonthViews)}`,
        change: percentageFormatter.format(changeInViewsPercent),
        changeType: changeInViewsPercent > 0 ? "increase" : changeInViewsPercent < 0 ? "decrease" : "neutral",
      });

      if (!template.promotion) {
        suggestions.push({
          template: template,
          stats: stats,
        });
      } else {
        active.push({
          template: template,
          stats: stats,
        });
      }
    }

    return [suggestions, active];
  }

  /**
   * Returns a list of draft finish suggestions to be displayed on the home screen.
   * @param profile Profile of the user.
   * @param templates Map of templates to be displayed on the home screen.
   * @returns {DraftTemplate[]} Template promotion suggestions to be displayed on the home screen.
   */
  function getDraftFinishSuggestions(
    profile: Profile | null,
    templates: Map<string, Template[]> | null
  ): DraftTemplate[] {
    const suggestions = Array<DraftTemplate>();
    if (!templates) return suggestions;
    const draftTemplates = templates.get("Drafts") || [];

    for (let template of draftTemplates) {
      suggestions.push({
        template: template,
      });
    }

    return suggestions;
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:account_pages.page_account_dashboard.title") + t("seo:tabs.title_suffix"),
            t("seo:account_pages.page_account_dashboard.description")
          )
        }
      />
      <DashboardH1 title={t("page_account.page_home.title")} subtitle={t("page_account.page_home.subtitle")} />
      <div className="space-y-16">
        <DashboardH2
          title={t("common:page_account.page_home.actions.title")}
          visible={actions.length > 0}
          children={<HomeActionsSection actions={actions} />}
        />

        <DashboardH2
          title={t("common:page_account.page_home.stats.title")}
          visible={stats.length > 0}
          children={<HomeStatisticsSection stats={stats} />}
        />

        <DashboardH2
          title={t("common:page_account.page_home.drafts.title")}
          subtitle={`You have ${draftTemplates.length}
                    ${draftTemplates.length === 1 ? t("common:draft") : t("common:drafts")}.
                    ${
                      draftTemplates.length === 1
                        ? t("common:page_account.page_home.drafts.not_empty")
                        : t("common:page_account.page_home.drafts.not_empty")
                    }`}
          visible={draftTemplates.length > 0}
          children={<DraftTemplatesComponent draftTemplates={draftTemplates} />}
        />

        <DashboardH2
          title={t("common:page_account.page_home.promotions.title")}
          subtitle={t("common:page_account.page_home.promotions.subtitle")}
          visible={templatePromotions.length > 0}
          children={<HomeTemplatePromotionsSection templatePromotions={templatePromotions} />}
        />

        <DashboardH2
          title={t("common:page_account.page_home.promotions.profile.title")}
          subtitle={t("common:page_account.page_home.promotions.profile.subtitle")}
          visible={profilePromotionSuggestion != null}
          children={<HomeProfilePromotionsSection profile={profilePromotionSuggestion} />}
        />

        <DashboardH2
          title={t("common:page_account.page_home.promotions.active.title")}
          visible={activeTemplatePromotions.length > 0 || activeProfilePromotion != null}
          children={
            <HomeActivePromotionsItem
              activeProfilePromotion={activeProfilePromotion}
              activeTemplatePromotions={activeTemplatePromotions}
            />
          }
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(HomePage);
