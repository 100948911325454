import "swiper/css";

import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import HeaderH1Item from "../../../../components/common/page/HeaderH1Item";
import SEOComponent, { SEOPage } from "../../../../components/common/seo/SEOComponent";
import { FeatureSection, FeatureSectionType } from "../../../../components/promotion/FeatureSection";
import { Template } from "../../../../models/Template";
import { getLatestTemplates } from "../../../../services/firestore-service";
import { RootState } from "../../../../store";
import { Logger } from "../../../../utils/utils-logging";
import { AlternatingTemplatesComponent } from "./AlternatingTemplatesComponent";

const TemplatesLatestPage = () => {
  const logger = new Logger("TemplatesLatestScreen");
  const { t } = useTranslation();
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const loadTemplates = async () => {
      setLoading(true);
      try {
        const latestTemplates = await getLatestTemplates(50);
        logger.log("loadTemplates: Latest templates", latestTemplates);
        const filteredTemplates = latestTemplates.filter(
          (template) => template.metaData?.desc !== undefined && Object.keys(template.metaData?.desc).length > 0
        );
        setTemplates(filteredTemplates);
      } catch (error) {
        setTemplates([]);
        logger.logError("loadTemplates: Error loading templates", error);
      }
      setLoading(false);
    };

    loadTemplates();
  }, []);

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:main_pages.page_templates_latest.title") + t("seo:tabs.title_suffix"),
            t("seo:main_pages.page_templates_latest.description")
          )
        }
      />
      <div>
        <div>
          <main>
            <HeaderH1Item
              title={t("common:page_templates_latest.title")}
              desc={t("common:page_templates_latest.description")}
            />
            <section aria-labelledby="header-title" className="pb-24 pt-6">
              <Transition
                show={templates.length > 0}
                enter="transition ease-out duration-300 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-200 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="mt-6">
                  <AlternatingTemplatesComponent isDiscover={true} templates={templates} />
                </div>
              </Transition>
            </section>
          </main>
        </div>
      </div>
      <FeatureSection type={FeatureSectionType.USERS} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(TemplatesLatestPage);
