import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export type Showcase = {
  title: string;
  description: string;
  image: string;
};
const ShowcaseComponent: React.FC<Showcase> = ({ title, description, image }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text-md font-semibold tracking-tight text-gray-900">{t(title)}</h3>
      <p className="mt-1 text-sm text-gray-500">{t(description)}</p>
      <img src={image} alt={title} className="mt-8 rounded-md border border-gray-200/50 shadow" />
    </div>
  );
};

export type Benefit = {
  name: string;
  description: string;
  Icon: React.FC;
};

const BenefitComponent: React.FC<Benefit> = ({ name, description, Icon }) => {
  const { t } = useTranslation();
  return (
    <div className="flex">
      <div className="flex gap-x-8 items-center">
        <div className="p-3 bg-gray-100/50 rounded-full">
          <div className="w-5 h-5 text-gray-700">
            <Icon />
          </div>
        </div>
        <div>
          <h3 className="text-md font-semibold text-gray-900">{t(name)}</h3>
          <p className="mt-1 text-sm text-gray-500">{t(description)}</p>
        </div>
      </div>
    </div>
  );
};

interface GuideComponentProps {
  preTitleChildren?: ReactNode;
  title: string;
  price: string;
  currencyCode: string;
  promotionable: boolean;
  description: string;
  postDescriptionChildren?: ReactNode;
  benefits: Benefit[];
  showcases: Showcase[];
}

const PromotionComponent: React.FC<GuideComponentProps> = ({
  preTitleChildren,
  title,
  price,
  currencyCode,
  promotionable,
  description,
  postDescriptionChildren,
  benefits,
  showcases,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto max-w-7xl px-6 py-6">
      <div className="mx-auto max-w-3xl text-center">
        {preTitleChildren}
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t(title)}</h1>
        {promotionable && (
          <p className="mt-3 text-xl font-medium text-gray-900">
            {price} {currencyCode == "USD" ? "$" : "€"}
          </p>
        )}
        <p className="mt-4 text-md font-normal text-gray-500 leading-6">{t(description)}</p>
        {postDescriptionChildren}
      </div>
      {promotionable && (
        <>
          <div>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">{t("page_account.page_promotions.benefits")}</h2>
            <div className="mt-8 gap-12 grid grid-cols-1 sm:grid-cols-2 w-full">
              {benefits.map((benefit) => (
                <BenefitComponent key={benefit.name} {...benefit} />
              ))}
            </div>
          </div>

          <div className="mt-24">
            <h2 className="mt-16 mb-8 text-2xl font-bold tracking-tight text-gray-900">{t("page_account.page_promotions.showcases")}</h2>
            {showcases?.map((showcase) => (
              <ShowcaseComponent key={showcase.title} {...showcase} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PromotionComponent;
