import React from "react";
import { useTranslation } from "react-i18next";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import { classNames } from "../../../utils/utils-react";

type Info = {
  label: string;
  value: string;
};

type Action = {
  title: string;
  link: string;
};

type Step = {
  name: string;
  description: string;
  imageSrc: string;
  imageRatio?: string;
  infos?: Info[];
  actions?: Action[];
};

export default function AffiliateGumroadGuideScreen() {
  const { t } = useTranslation();
  const steps: Step[] = [
    {
      name: "page_affiliate_gumroad.steps.add_affiliate.title",
      description: "page_affiliate_gumroad.steps.add_affiliate.description",
      actions: [
        {
          title: "page_affiliate_gumroad.steps.add_affiliate.link",
          link: "https://app.gumroad.com/affiliates",
        },
      ],
      imageRatio: "aspect-w-5 aspect-h-2",
      imageSrc: process.env.PUBLIC_URL + "/affiliate/gumroad/affiliate_gumroad_guide_1.png",
    },
    {
      name: "page_affiliate_gumroad.steps.setup_affiliate.title",
      description: "page_affiliate_gumroad.steps.setup_affiliate.description",
      infos: [
        {
          label: "page_affiliate_gumroad.steps.setup_affiliate.email",
          value: "elcovia@gmail.com",
        },
        {
          label: "page_affiliate_gumroad.steps.setup_affiliate.fee",
          value: "10%",
        },
      ],
      imageSrc: process.env.PUBLIC_URL + "/affiliate/gumroad/affiliate_gumroad_guide_2.png",
    },
    {
      name: "page_affiliate_gumroad.steps.affiliate_added.title",
      description: "page_affiliate_gumroad.steps.affiliate_added.description",
      imageRatio: "aspect-w-5 aspect-h-2",
      imageSrc: process.env.PUBLIC_URL + "/affiliate/gumroad/affiliate_gumroad_guide_3.png",
    },
    {
      name: "page_affiliate_gumroad.steps.use_affiliate_links.title",
      description: "page_affiliate_gumroad.steps.use_affiliate_links.description",
      imageSrc: process.env.PUBLIC_URL + "/affiliate/gumroad/affiliate_gumroad_guide_4.png",
    },
  ];
  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:main_pages.page_affiliate_gumroad_guide.title") + t("seo:tabs.title_suffix"),
            t("seo:main_pages.page_affiliate_gumroad_guide.description")
          )
        }
      />
      <div>
        <div className="mx-auto max-w-7xl px-6 py-6 sm:py-24 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("page_affiliate_gumroad.title")}
            </h2>
            <p className="mt-4 text-gray-500">{t("page_affiliate_gumroad.subtitle")}</p>
          </div>
          <div className="mt-24 space-y-16">
            {steps.map((step, stepIdx) => (
              <div
                key={step.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-12"
              >
                <div
                  className={classNames(
                    stepIdx % 2 === 0 ? "lg:col-start-1" : "lg:col-start-8 xl:col-start-9",
                    "mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4"
                  )}
                >
                  <h3 className="text-lg font-medium text-gray-900">{t(step.name)}</h3>
                  <p className="mt-2 text-sm text-gray-500">{t(step.description)}</p>
                  <div className="mt-8 space-y-4 text-center">
                    {step.infos &&
                      step.infos.map((info) => (
                        <div key={info.label} className="flex flex-col bg-gray-100 rounded-lg py-4 px-3">
                          <p className="text-lg font-bold tracking-tight text-indigo-500">{t(info.value)}</p>
                          <p className="text-xs font-normal text-gray-600">{t(info.label)}</p>
                        </div>
                      ))}
                  </div>
                  <div className="mt-4 space-y-4">
                    {step.actions &&
                      step.actions.map((action) => (
                        <ButtonComponent
                          key={action.title}
                          target="_blank"
                          style="redirect"
                          rel="noopener noreferrer"
                          href={action.link}
                          baseClassNames="w-full"
                          text={t(action.title)}
                        />
                      ))}
                  </div>
                </div>
                <div
                  className={classNames(
                    stepIdx % 2 === 0 ? "lg:col-start-6 xl:col-start-5" : "lg:col-start-1",
                    "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8"
                  )}
                >
                  <div
                    className={classNames(
                      step.imageRatio || "aspect-video",
                      "overflow-hidden rounded-lg shadow-md bg-gray-100"
                    )}
                  >
                    <img src={step.imageSrc} className="object-cover object-top" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
