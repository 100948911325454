import { User } from "firebase/auth";
import React from "react";
import { Helmet } from "react-helmet-async";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { setSignedIn, setUserProfile } from "../../../redux/UserActions";
import { getUser, signInWithGoogle, signInWithTwitter } from "../../../services/firestore-service";
import { RootState } from "../../../store";
import { Destination } from "../../../utils/constants-navigation";
import { login } from "../../../services/action-service";
import StatsComponent from "../../../components/common/StatsComponent";
import FeaturesComponent from "../../../components/common/sections/FeaturesComponent";
import { UnlockComponent } from "../../../components/common/sections/UnlockComponent";
import {
  trackProviderGoogle,
  trackProviderTwitter,
  trackProviderTwitterError,
} from "../../../services/tracking-service";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import { useTranslation } from "react-i18next";
import { getLanguagesPathBy } from "../../../languages/i18n";
import { Logger } from "../../../utils/utils-logging";

const LoginPage = () => {
  const logger = new Logger("LoginPage");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const userProfile = useSelector((state: RootState) => state.user.profile);

  /**
   * Sign in with Google.
   */
  const useGoogleSignIn = async () => {
    try {
      const result = await signInWithGoogle(i18n.language);
      await processSignInSuccess(result.user);
      trackProviderGoogle();
    } catch (error) {
      trackProviderGoogle();
      console.log(error);
    }
  };

  /**
   * Sign in with Twitter.
   */
  const useTwitterSignIn = async () => {
    try {
      const result = await signInWithTwitter(i18n.language);
      await processSignInSuccess(result.user);
      trackProviderTwitter();
    } catch (error) {
      trackProviderTwitterError();
      console.log(error);
    }
  };

  /**
   * Process sign in success.
   * @param user User that signed in.
   */
  const processSignInSuccess = async (user: User) => {
    await login(dispatch, user);
    const path = getLanguagesPathBy(Destination.DISCOVER, i18n.language);
    navigate(path);
  };

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:main_pages.page_login.title") + t("seo:tabs.title_suffix"),
            t("seo:main_pages.page_login.description")
          )
        }
      />
      {/* The actual login screen. */}
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8 divide-y divide-gray-200">
        <div className="text-center mb-24">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-1xl md:text-5xl">
            {t("page_login.title")}
          </h1>
          <p className="max-w-xl ml-auto mr-auto mt-6 text-base leading-7 text-gray-600">{t("page_login.subtitle")}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="#"
              onClick={useGoogleSignIn}
              className="text-white hover:bg-[#DB4437] bg-[#DB4437] border border-[#DB4437/50] fill-white inline-flex transition-all ease-in-out duration-300 rounded shadow-sm py-3 px-4 text-sm font-medium outline-none justify-center items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 fill-current ml-4 mr-6">
                <title>{t("google")}</title>
                <path d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />
              </svg>

              <span className="text-white mr-4">{t("common:google")}</span>
            </Link>

            <Link
              to="#"
              onClick={useTwitterSignIn}
              className="text-white hover:bg-[#1DA1F2] bg-[#1DA1F2] border border-[#1DA1F2/50] fill-white inline-flex transition-all ease-in-out duration-300 rounded shadow-sm py-3 px-4 text-sm font-medium outline-none justify-center items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 fill-current ml-4 mr-6">
                <title>{t("common:twitter")}</title>
                <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
              </svg>
              <span className="text-white mr-4">{t("common:twitter")}</span>
            </Link>
          </div>
        </div>
        <StatsComponent />
        <FeaturesComponent />
      </main>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(LoginPage);
