import React from "react";
import { useTranslation } from "react-i18next";
import CustomLink from "../../common/links/CustomLink";

type LinkType = {
  text: string;
  href: string;
  newTab?: boolean;
};

export type SectionType = {
  title: string;
  links: LinkType[];
};

export type FooterSections = {
  sections: FooterSection[];
};

export type FooterSection = {
  title: string;
  links: FooterLink[];
};

type FooterLink = {
  href: string;
  newTab?: boolean;
  text: string;
};

interface FooterLinksComponentProps {
  section: SectionType;
}

const FooterLinkComponent: React.FC<FooterLinksComponentProps> = ({ section }) => {
  const { t } = useTranslation();
  return (
    <div className="col-span-6 md:col-span-1">
      <h5 className="font-semibold text-sm text-gray-900 text-start">{t(section.title)}</h5>
      <nav aria-label={section.title} className="mt-3">
        <ul className="space-y-2">
          {section.links.map((link) => (
            <li key={link.text}>
              <CustomLink
                to={link.href}
                className="text-gray-800 font-normal text-sm text-start transition hover:opacity-50"
                target={link.newTab ? "_blank" : undefined}
                rel={link.newTab ? "noopener noreferrer" : undefined}
              >
                {t(link.text)}
              </CustomLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default FooterLinkComponent;
