import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../../utils/utils-react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export interface MenuOption {
  name: string;
  current: boolean;
  icon?: React.FC;
  image?: string;
}

interface SelectionButtonComponentProps {
  defaultOption?: MenuOption;
  options: MenuOption[];
  onClick: (option: MenuOption, index: number) => void;
}

const SelectionButtonComponent: React.FC<SelectionButtonComponentProps> = ({ defaultOption, options, onClick }) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const current = options.find((option) => option.current) || defaultOption || null;
  const isLarge = options.length > 6;

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="px-3.5 py-2 flex gap-x-1 text-sm font-medium text-gray-800 focus:outline-none bg-gray-200/50 rounded-full items-center min-w-56"
        >
          {current?.icon && (
            <div className="w-4 h-4 shrink-0">
              <current.icon />
            </div>
          )}
          <h3 className="ml-1 mr-2 line-clamp-1">{current?.name ? t(current.name) : ""}</h3>
          <ChevronDownIcon className={classNames(isMenuOpen ? "rotate-180" : "", "transition-all duration-300 h-5 w-5 flex-none text-gray-600")} aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        afterLeave={() => setIsMenuOpen(false)}
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 origin-top-right bg-white shadow-2xl drop-shadow focus:outline-none rounded-lg">
          <div className={classNames(isLarge ? "h-96 lg:h-full lg:grid lg:grid-cols-3 space-2 p-2 py-4 lg:block lg:w-120" : "w-48", "py-1 overflow-scroll")}>
            {options.map((option, index) => (
              <Menu.Item key={option.name}>
                {({ active }) => (
                  <a
                    onClick={() => {
                      setIsMenuOpen(false);
                      if (!option.current) {
                        onClick(option, index);
                      }
                    }}
                  >
                    <div
                      className={classNames(
                        option.current ? "font-semibold text-gray-900" : "font-normal text-gray-600",
                        active ? "bg-gray-100" : "",
                        isLarge ? "lg:rounded-md lg:h-full" : "",
                        isLarge && option.icon == null ? "lg:text-center lg:justify-center" : "",
                        "flex items-center gap-x-3 px-4 px-2 py-2 text-sm"
                      )}
                    >
                      {option.icon && (
                        <div className="w-4 h-4 shrink-0">
                          <option.icon />
                        </div>
                      )}
                      <p>{t(option.name)}</p>
                    </div>
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default SelectionButtonComponent;
