import React from "react";
import { useLocation } from "react-router-dom";
import { FooterSocialLinks } from "../../../../utils/constants";
import FooterSocialsComponent from "../../footer/FooterSocialsComponent";
import FooterLegalComponent from "../../footer/FooterLegalComponent";
import { classNames } from "../../../../utils/utils-react";
import { Destination } from "../../../../utils/constants-navigation";
import CustomLink from "../../../common/links/CustomLink";
import { Trans } from "react-i18next";

const DashboardFooterComponent = () => {
  return (
    <footer aria-label="Dashboard Footer">
      <div className="p-8 rounded-3xl m-8 bg-gray-50 px-8">
        <div>
          <p className="pt-16 font-normal text-sm text-gray-500 text-center leading-6">
            <Trans
              i18nKey="common:page_account.page_footer.contact_us"
              components={{ a: <CustomLink className="text-indigo-500" to={Destination.CONTACT} target="_blank" rel="noopener noreferrer" /> }}
            />
          </p>
          <div className="mt-12" />
          <FooterSocialsComponent />
        </div>
        <FooterLegalComponent />
      </div>
    </footer>
  );
};

export default DashboardFooterComponent;
