import { Timestamp } from "@firebase/firestore";
import { Transition } from "@headlessui/react";
import { AcademicCapIcon, CheckBadgeIcon, FaceSmileIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { profile } from "console";
import _, { isEqual } from "lodash";
import { title } from "process";
import React, { Fragment } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BadgeComponent from "../../../components/common/BadgeComponent";
import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import SocialButtonComponent from "../../../components/common/buttons/SocialButtonComponent";
import { DashboardH1, DashboardH3 } from "../../../components/common/common/DashboardHeaderComponents";
import { ErrorDialog } from "../../../components/common/dialogs/ErrorDialog";
import { ProfileIdDialog } from "../../../components/common/dialogs/ProfileIdDialog";
import TextAreaComponent from "../../../components/common/input/TextAreaComponent";
import TextFieldComponent from "../../../components/common/input/TextFieldComponent";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import { Profile } from "../../../models/Profile";
import { setUserProfile } from "../../../redux/UserActions";
import { getUserByUserId, updateUser } from "../../../services/firestore-service";
import { uploadFileToProfile } from "../../../services/storage-service";
import { trackUpdateProfile, trackUpdateProfileError } from "../../../services/tracking-service";
import { RootState } from "../../../store";
import { AvatarIconProvider } from "../../../utils/AvatarIconProvider";
import {
  PROFILE_BIO_MAX_LENGTH,
  PROFILE_BIO_MIN_LENGTH,
  PROFILE_NAME_MAX_LENGTH,
  PROFILE_NAME_MIN_LENGTH,
} from "../../../utils/constants";
import { Logger } from "../../../utils/utils-logging";

const ProfilePage = () => {
  const logger = new Logger("ProfilePage");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);
  const [profile, setProfile] = React.useState<Profile | null>(null);
  const [profileOrginal, setProfileOrginal] = React.useState<Profile | null>(_.cloneDeep(null));
  const [hasChanges, setHasChanges] = React.useState(false);
  const [errors, setErrors] = React.useState({
    name: false,
    bio: false,
    links: Array(6).fill(false),
  });
  const [uploadError, setUploadError] = React.useState<string | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null!);

  const [openProfileIdDialog, setOpenProfileIdDialog] = React.useState(false);

  /**
   * Load profile.
   */
  async function loadProfile() {
    logger.log("loadProfile", {
      isSignedIn: isSignedIn,
      sessionProfile: sessionProfile,
    });
    const profile = await getUserByUserId(sessionProfile?.id!!);
    logger.log("loadProfile", { profile: profile });
    if (!profile) return;
    setProfile(profile);
    setProfileOrginal(_.cloneDeep(profile));
    setUserProfile(profile);
  }

  React.useEffect(() => {
    if (isSignedIn) {
      if (profile == null || profileOrginal == null) {
        loadProfile();
      }
    }
  }, []);

  React.useEffect(() => {
    setHasChanges(checkHasChanges());
  }, [profile, profileOrginal]);

  function checkHasChanges() {
    if (!profile || !profileOrginal) {
      return false;
    }
    const keys = Object.keys(profile);
    for (const key of keys) {
      const newValue = profile[key];
      const originalValue = profileOrginal[key];
      if (!isEqual(newValue, originalValue)) {
        return true;
      }
    }
    return false;
  }

  function validateInput(name: any, value: any) {
    switch (name) {
      case "name":
        return value.length < PROFILE_NAME_MIN_LENGTH || value.length > PROFILE_NAME_MAX_LENGTH;
      case "bio":
        return value.length < PROFILE_BIO_MIN_LENGTH || value.length > PROFILE_BIO_MAX_LENGTH;
      case "links":
        const list = value as string[];
        return list.map((link) => (link.length == 0 ? false : !/^https?:\/\//.test(link)));
      default:
        return false;
    }
  }

  /**
   * Handle profile image upload.
   */
  const handleProfileImageUpload = (event: any) => {
    logger.log("handleProfileImageUpload", { event: event });
    const file = event.target.files[0];
    if (file && file.type.includes("image")) {
      const profileImage = new Image();
      profileImage.onload = async () => {
        const targetWidth = 300;
        const targetHeight = 300;
        const targetRatio = targetWidth / targetHeight;
        const targetSize = 1 * 1024 * 1024; // 1 MB
        const ratio = profileImage.width / profileImage.height;
        if (ratio != targetRatio) {
          setUploadError("Image ratio does not meet the minimum requirements.");
        } else if (file.size > targetSize) {
          setUploadError("File size exceeds the maximum limit.");
        } else if (profileImage.width < targetWidth || profileImage.height < targetHeight) {
          setUploadError("Image resolution does not meet the minimum requirements.");
        } else {
          const result = await uploadFileToProfile(sessionProfile?.id!!, file, true);
          console.log("result", sessionProfile?.id!!, file);
          if (result) {
            const profileImageUrl = result as string;
            handleInputChangeItem("image", profileImageUrl);
          } else {
            setUploadError("Failed to upload file.");
          }
        }
      };
      profileImage.src = URL.createObjectURL(file);
    }
    fileInputRef.current.value = "";
  };

  /**
   * Handle profile item change.
   */
  function handleInputChange(event: { target: { name: string; value: any } }) {
    const { name, value } = event.target;
    handleInputChangeItem(name, value);
  }

  function checkErrors() {
    if (!profile) return false;
    const keys = Object.keys(profile);
    console.log("keys", keys);
    for (const key of keys) {
      const value = profile[key];
      const error = validateInput(key, value);
      const newErrors = { ...errors, [key]: error };
      console.log("key", key, "error", error);
      setErrors(newErrors);
    }
  }

  /**
   * Handle profile item change.
   */
  function handleInputChangeItem(name: string, value: any) {
    if (!profile) return;
    if (name === "links") {
      if (value instanceof Array) {
        value = value.filter((item) => item.length > 0);
      }
    }
    const newProfile = { ...profile, [name]: value };
    const newErrors = { ...errors, [name]: validateInput(name, value) };
    setProfile(newProfile);
    setErrors(newErrors);
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Cancel profile changes.
   */
  const cancelProfile = () => {
    if (!profileOrginal) return;
    setProfile(profileOrginal);
    setProfileOrginal(_.cloneDeep(profileOrginal));
    checkErrors();
  };

  /**
   * Save profile changes.
   */
  const saveProfile = async () => {
    try {
      await updateUser(profile!!);
      setUserProfile(profile);
      setProfileOrginal(_.cloneDeep(profile));
      dispatch(setUserProfile(profile));
      trackUpdateProfile();
    } catch (error) {
      trackUpdateProfileError();
      console.log(error);
    }
  };

  /**
   * Update profile id.
   * @param profileId The profile id.
   */
  const updateProfileId = async (profileId: string) => {
    if (!profile) return;
    const timestamp = Timestamp.now();
    const newProfile = {
      ...profile,
      profileId: profileId,
      lastIdChange: timestamp,
    };
    setProfile(newProfile);

    const newProfileOriginal = {
      ...(profileOrginal || profile),
      profileId: profileId,
      lastIdChange: timestamp,
    };
    setProfileOrginal(_.cloneDeep(newProfileOriginal));
    setUserProfile(newProfileOriginal);
  };

  if (!isSignedIn || !profile) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            You are currently not signed-in.
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:account_pages.page_account_profile.title") + t("seo:tabs.title_suffix"),
            t("seo:account_pages.page_account_profile.description")
          )
        }
      />
      {/* This `div` block is the main container for the screen. */}
      <div>
        <div>
          {uploadError && <ErrorDialog content={uploadError} />}
          <ProfileIdDialog
            show={openProfileIdDialog}
            profile={profile}
            onCancel={() => setOpenProfileIdDialog(false)}
            onFinish={(profileId) => {
              updateProfileId(profileId);
              setOpenProfileIdDialog(false);
            }}
          />
          <DashboardH1
            title={t("page_account.page_profile.title")}
            subtitle={t("page_account.page_profile.subtitle")}
          />
          <DashboardH3
            title={t("page_account.page_profile.profile_picture.title")}
            subtitle={t("page_account.page_profile.profile_picture.subtitle")}
          />
          <div className="inline items-center">
            <div className="w-full flex items-top">
              <div className="shrink-0 flex items-center">
                <div className="">
                  <div
                    className="group flex items-center gap-x-6 cursor-default"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <img
                      onClick={() => fileInputRef.current?.click()}
                      src={profile.image}
                      alt={profile.name || ""}
                      className="h-20 w-20 transition duration-300 ease-in-out rounded-2xl border border-gray-200 group-hover:shadow"
                    />
                    <div className="w-96">
                      <p className="font-medium text-xs text-gray-600">
                        {t("page_account.page_profile.profile_picture.hint")}
                      </p>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      const imageUrl = new AvatarIconProvider().getRandomNotioly();
                      setProfile({ ...profile, image: imageUrl });
                    }}
                    type="button"
                    className="hidden mt-3 rounded-full border border-transparent py-2 px-4 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none"
                  >
                    <div className="flex items-center">
                      <FaceSmileIcon className="h-4 w-4 flex-none text-gray-800 mr-2" aria-hidden="true" />
                      {t("page_account.page_profile.profile_picture.avatar")}
                    </div>
                  </button>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={(e) => handleProfileImageUpload(e)}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className="mt-8 space-y-8">
              <section>
                <DashboardH3
                  title={t("page_account.page_profile.display_name.title")}
                  subtitle={t("page_account.page_profile.display_name.subtitle")}
                />
                <div className="w-96">
                  <TextFieldComponent
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    value={profile.name || ""}
                    id="name"
                    minLength={PROFILE_NAME_MIN_LENGTH}
                    maxLength={PROFILE_NAME_MAX_LENGTH}
                    count={true}
                    placeholder={profile.name || ""}
                  />
                </div>
                {errors.name && (
                  <p className="mt-2 text-xs text-red-500">
                    <Trans
                      i18nKey="page_account.page_profile.display_name.error"
                      values={{ min: PROFILE_NAME_MIN_LENGTH, max: PROFILE_NAME_MAX_LENGTH }}
                    />
                  </p>
                )}
              </section>
              <section>
                <DashboardH3
                  title={t("page_account.page_profile.bio.title")}
                  subtitle={t("page_account.page_profile.bio.subtitle")}
                />
                <>
                  <div>
                    <TextAreaComponent
                      name={"bio"}
                      id="bio"
                      minLength={PROFILE_BIO_MIN_LENGTH}
                      maxLength={PROFILE_BIO_MAX_LENGTH}
                      count={true}
                      rows={3}
                      value={profile.bio}
                      onChange={handleInputChange}
                      placeholder={profile.bio}
                    />
                  </div>
                  {errors.bio && (
                    <p className="mt-2 text-xs text-red-500">
                      <Trans
                        i18nKey="page_account.page_profile.bio.error"
                        values={{ min: PROFILE_BIO_MIN_LENGTH, max: PROFILE_BIO_MAX_LENGTH }}
                      />
                    </p>
                  )}
                </>
              </section>
              <section>
                <DashboardH3
                  title={t("page_account.page_profile.links.title")}
                  subtitle={t("page_account.page_profile.links.subtitle")}
                />
                <div className="mt-2 grid gap-y-4 grid-cols-1">
                  {[...Array(6)].map((_, index) => {
                    const url = profile.links?.[index] ?? "";
                    return (
                      <div>
                        <div className="flex">
                          <div className="relative w-96">
                            <TextFieldComponent
                              type="text"
                              value={url}
                              id={`link-${index}`}
                              onChange={(event) => {
                                const newLinks = [...profile.links];
                                newLinks[index] = event.target.value;
                                handleInputChangeItem("links", newLinks);
                              }}
                              placeholder={url || "https://..."}
                            />
                          </div>
                          {url.length > 0 && (
                            <>
                              <ButtonComponent
                                text={t("common:remove")}
                                onClick={() => {
                                  const newLinks = [...profile.links];
                                  newLinks[index] = "";
                                  setProfile({ ...profile, links: newLinks });
                                }}
                                type="button"
                                style="structural"
                                parentClassNames="ml-3"
                                leftIconChild={
                                  <XMarkIcon className="h-4 w-4 flex-none text-white mr-2" aria-hidden="true" />
                                }
                              />
                              <div className="ml-4 min-w-32">
                                <SocialButtonComponent
                                  parentClassNames="ml-4 block"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={url}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {errors.links[index] && (
                          <p className="mt-2 text-xs text-red-500">{t("page_account.page_profile.links.error")}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </section>
              <section>
                <DashboardH3
                  title={t("page_account.page_profile.status.title")}
                  subtitle={
                    !profile.verified
                      ? t("page_account.page_profile.status.subtitle_verified")
                      : !profile.notionCertified
                      ? t("page_account.page_profile.status.subtitle_notion_certified")
                      : ""
                  }
                />
                <div className="mt-3 flex inline gap-x-2">
                  <BadgeComponent text={t("common:verified")} isActive={profile.verified} icon={<CheckBadgeIcon />} />
                  <BadgeComponent
                    text={t("common:notion_certified")}
                    isActive={profile.notionCertified}
                    icon={<AcademicCapIcon />}
                  />
                </div>
              </section>

              <section>
                <DashboardH3
                  title={t("page_account.page_profile.username.title")}
                  subtitle={t("page_account.page_profile.username.subtitle")}
                />
                {profile.profileId ? (
                  <ButtonComponent
                    parentClassNames="mt-3"
                    style="structural"
                    onClick={() => setOpenProfileIdDialog(true)}
                    text={"@" + profile.profileId}
                  />
                ) : (
                  <ButtonComponent
                    style="structural"
                    onClick={() => setOpenProfileIdDialog(true)}
                    text={t("page_account.page_profile.username.setup_username")}
                  />
                )}
              </section>
              <section>
                <DashboardH3
                  title={t("page_account.page_profile.email.title")}
                  subtitle={t("page_account.page_profile.email.subtitle")}
                />
                <div className="w-96">
                  <TextFieldComponent
                    type="text"
                    name="mail"
                    onChange={handleInputChange}
                    value={profile.mail || ""}
                    id="mail"
                    autoComplete="email"
                    placeholder={profile.mail || ""}
                    disabled={profile.mail !== null}
                  />
                </div>
              </section>
              <div className="mt-24">
                <Transition
                  as={Fragment}
                  show={hasChanges}
                  enter="transition ease-out duration-900"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-900"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Transition.Child
                    enter="transition-opacity ease-linear duration-900"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-900"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    {hasChanges && (
                      <div className="space-x-4 inline-flex">
                        <ButtonComponent
                          style="structural-text"
                          onClick={() => cancelProfile()}
                          text={t("common.cancel")}
                        />
                        <ButtonComponent style="structural" onClick={() => saveProfile()} text={t("common.save")} />
                      </div>
                    )}
                  </Transition.Child>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(ProfilePage);
