export type CategoryType = {
    key: string;
    title: string;
    desc: string;
    image: string;
};

const getFilePath = (imageName: string): string => {
    return process.env.PUBLIC_URL + "/illustrations/categories/" + imageName + ".svg";
};

export const Category: { [key: string]: CategoryType } = {
    PERSONAL_LIFE_MANAGEMENT: {
        key: "00100",
        title: "categories:personal_life_management.title",
        desc: "categories:personal_life_management.desc",
        image: getFilePath("illustration_category_personal_life_management")
    },
    WORK_PRODUCTIVITY: {
        key: "00200",
        title: "categories:work_productivity.title",
        desc: "categories:work_productivity.desc",
        image: getFilePath("illustration_category_work_productivity")
    },
    EDUCATION_LEARNING: {
        key: "00300",
        title: "categories:education_learning.title",
        desc: "categories:education_learning.desc",
        image: getFilePath("illustration_category_education_learning")
    },
    HEALTH_WELLNESS: {
        key: "00400",
        title: "categories:health_wellness.title",
        desc: "categories:health_wellness.desc",
        image: getFilePath("illustration_category_health_wellness")
    },
    HOME_LIVING: {
        key: "00500",
        title: "categories:home_living.title",
        desc: "categories:home_living.desc",
        image: getFilePath("illustration_category_home_living")
    },
    DESIGN_CREATIVITY: {
        key: "00600",
        title: "categories:design_creativity.title",
        desc: "categories:design_creativity.desc",
        image: getFilePath("illustration_category_design_creativity")
    },
    SOFTWARE_DEVELOPMENT_ENGINEERING: {
        key: "00700",
        title: "categories:software_development_engineering.title",
        desc: "categories:software_development_engineering.desc",
        image: getFilePath("illustration_category_software_development_engineering")
    },
    FREELANCE_REMOTE_WORK: {
        key: "00800",
        title: "categories:freelance_remote_work.title",
        desc: "categories:freelance_remote_work.desc",
        image: getFilePath("illustration_category_freelance_remote_work")
    },
    HUMAN_RESOURCES_PEOPLE_MANAGEMENT: {
        key: "00900",
        title: "categories:human_resources_people_management.title",
        desc: "categories:human_resources_people_management.desc",
        image: getFilePath("illustration_category_human_resources_people_management")
    },
    MARKETING_ADVERTISING: {
        key: "01000",
        title: "categories:marketing_advertising.title",
        desc: "categories:marketing_advertising.desc",
        image: getFilePath("illustration_category_marketing_advertising")
    },
    SALES_CUSTOMER_MANAGEMENT: {
        key: "01100",
        title: "categories:sales_customer_management.title",
        desc: "categories:sales_customer_management.desc",
        image: getFilePath("illustration_category_sales_customer_management")
    },
    NON_PROFIT_PHILANTHROPY: {
        key: "01200",
        title: "categories:non_profit_philanthropy.title",
        desc: "categories:non_profit_philanthropy.desc",
        image: getFilePath("illustration_category_non_profit_philantrophy")
    },
    REAL_ESTATE_PROPERTY_MANAGEMENT: {
        key: "01300",
        title: "categories:real_estate_property_management.title",
        desc: "categories:real_estate_property_management.desc",
        image: getFilePath("illustration_category_real_estate_proproperty_management")
    },
    STARTUPS_ENTREPRENEURSHIP: {
        key: "01400",
        title: "categories:startups_entrepreneurship.title",
        desc: "categories:startups_entrepreneurship.desc",
        image: getFilePath("illustration_category_startups_entrepreneurship")
    },
    PUBLIC_RELATIONS_COMMUNICATIONS: {
        key: "01500",
        title: "categories:public_relations_communications.title",
        desc: "categories:public_relations_communications.desc",
        image: getFilePath("illustration_category_public_relations_communications")
    },
    CONTENT_PRODUCTION_MEDIA: {
        key: "01600",
        title: "categories:content_production_media.title",
        desc: "categories:content_production_media.desc",
        image: getFilePath("illustration_category_content_production_media")
    },
    LEGAL_COMPLIANCE: {
        key: "01700",
        title: "categories:legal_compliance.title",
        desc: "categories:legal_compliance.desc",
        image: getFilePath("illustration_category_legal_compliance")
    },
    FINANCE_ACCOUNTING: {
        key: "01800",
        title: "categories:finance_accounting.title",
        desc: "categories:finance_accounting.desc",
        image: getFilePath("illustration_category_finance_accounting")
    },
    TRAVEL_LEISURE: {
        key: "01900",
        title: "categories:travel_leisure.title",
        desc: "categories:travel_leisure.desc",
        image: getFilePath("illustration_category_travel_leisure")
    },
    EVENTS_CONFERENCES: {
        key: "02000",
        title: "categories:events_conferences.title",
        desc: "categories:events_conferences.desc",
        image: getFilePath("illustration_category_events_conferences")
    },
    SCIENCE_RESEARCH: {
        key: "02100",
        title: "categories:science_research.title",
        desc: "categories:science_research.desc",
        image: getFilePath("illustration_category_science_research")
    },
    ENVIRONMENT_SUSTAINABILITY: {
        key: "02200",
        title: "categories:environment_sustainability.title",
        desc: "categories:environment_sustainability.desc",
        image: getFilePath("illustration_category_environment_sustainability")
    },
    ARTIFICIAL_INTELLIGENCE: {
        key: "02300",
        title: "categories:artificial_intelligence.title",
        desc: "categories:artificial_intelligence.desc",
        image: getFilePath("illustration_category_ai")
    }
};
