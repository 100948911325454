import React from "react";
import { Trans, useTranslation } from "react-i18next";

import SEOComponent, { SEOPage } from "../../../../components/common/seo/SEOComponent";
import { Category } from "../../../../models/Category";
import TemplateCategoryItem from "./TemplateCategoryItem";

const TemplateCategoriesPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:main_pages.page_categories.title") + t("seo:tabs.title_suffix"),
            t("seo:main_pages.page_categories.description")
          )
        }
      />
      <div className="mx-auto w-full mb-32">
        <div className="flex gap-x-16 items-center justify-center text-center">
          <div className="max-w-3xl">
            <h2 className="text-5xl font-bold tracking-tight text-gray-900 leading-snug">
              <Trans
                i18nKey="common:page_templates_categories.title"
                components={{ span: <span className="text-indigo-600" /> }}
              />
            </h2>
            <p className="mt-6 text-lg leading-7 text-gray-600">{t("common:page_templates_categories.description")}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-16">
          {Object.values(Category).map((category) => (
            <TemplateCategoryItem category={category} />
          ))}
        </div>
      </div>
    </>
  );
};

export default TemplateCategoriesPage;
