import React from 'react';

interface NoDataStateProps {
  heading1?: string;
  heading2: string;
  description: string;
}

const NoDataStateComponent: React.FC<NoDataStateProps> = ({
  heading1,
  heading2,
  description,
}) => {
  return (
    <div className="overflow-hidden bg-gray-100 px-20 py-20 sm:py-24 sm:px-24 w-full rounded-lg">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                {heading1}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {heading2}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataStateComponent;
