import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Logger } from "../../../utils/utils-logging";
import { useNavigate, useLocation } from "react-router-dom";
import { getLanguagesPathBy, supportedWebLanguages } from "../../../languages/i18n";
import { Destination } from "../../../utils/constants-navigation";

const LanguageRedirect: React.FC = () => {
  const logger = new Logger("LanguageRedirect");
  const { i18n } = useTranslation(); // Hook to get the current i18n instance and language functions
  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation(); // Hook to get the current URL location

  /**
   * Redirects the user to the correct language path based on the URL or localStorage.
   */
  async function redirectToLanguage() {
    // Step 1: Define a regex to extract the language from the path (e.g., '/de' or '/en')
    const regexSupportedWebLanguages = new RegExp(`^/(${supportedWebLanguages.join("|")})(/|$)`);
    const match = location.pathname.match(regexSupportedWebLanguages); // Check if the path contains a supported language
    const pathLanguage = match ? match[1] : null; // Extract the language from the path if it exists

    // Step 2: Store the language found in the path or fallback to the current i18n language
    // This ensures that the language remains consistent between the URL and the app state.
    localStorage.setItem("i18nextLng", pathLanguage || i18n.language);

    // Log the current path and the language detected from the URL for debugging purposes
    logger.log(`path: ${location.pathname}`, `pathLanguage: ${pathLanguage}`);

    // Step 3: Determine the language to be used
    // If the path contains a valid language, use it; otherwise, fallback to 'null' (to be handled later)
    const currentLng = match ? match[1] : null;

    // Step 4: Get the stored language from localStorage or fallback to 'en' if not found
    const storedLanguage = localStorage.getItem("i18nextLng") || "en";

    // Step 5: Check if the current language in the path differs from the stored language
    // If so, construct a new path with the stored language and navigate to it
    if (!currentLng || currentLng !== storedLanguage) {
      // Remove the language part from the current path (e.g., '/de/page' becomes '/page')
      const pathWithoutLng = location.pathname.replace(/^\/[a-z]{2}(-[a-z]{2})?(\/|$)/, "");
      // Construct a new path using the stored language and the remainder of the original path
      const newPath = `/${storedLanguage}${pathWithoutLng}`;

      // Log the redirection details for debugging
      logger.log(`location.pathname: ${location.pathname}`, `newPath: ${newPath}`);

      // Step 6: Navigate to the new path if it differs from the current one
      if (location.pathname !== newPath) {
        navigate(newPath, { replace: true });
      }
    }
  }

  // Step 7: Execute the redirection logic whenever the component is rendered or when the path changes
  useEffect(() => {
    redirectToLanguage();
  }, [navigate]); // Trigger this effect whenever `navigate` changes (though it typically won't)

  return null; // This component doesn't render any UI
};

export default LanguageRedirect;
