import { SquaresPlusIcon } from "@heroicons/react/20/solid";
import SettingsGroupComponent from "../../../components/common/settings/SettingsGroupComponent";
import EmptyButtonStateComponent from "../../../components/common/state/EmptyButtonState";
import TemplateFeatureItemComponent from "../../../components/template/TemplateFeatureItem";
import { TemplateFeature } from "../../../models/TemplateFeature";
import React from "react";
import { FeatureData, FeatureEditDialog } from "../../../components/common/dialogs/FeatureEditDialog";
import { Template } from "../../../models/Template";
import { useTranslation } from "react-i18next";

type TemplateFeaturesProps = {
  templateId: string;
  userId: string;
  features: TemplateFeature[] | null;
  handleSaveFeature: (feature: FeatureData) => void;
  handleDeleteFeature: (feature: FeatureData) => void;
};

const TemplateFeaturesSection = ({ templateId, userId, features, handleSaveFeature, handleDeleteFeature }: TemplateFeaturesProps) => {
  const { t } = useTranslation();
  const [openFeatureEditDialog, setOpenFeatureEditDialog] = React.useState<{
    visible: boolean;
    featureData: FeatureData;
  }>({
    visible: false,
    featureData: {
      feature: null,
      index: null,
    },
  });
  return (
    <>
      <FeatureEditDialog
        uid={userId}
        templateId={templateId}
        show={openFeatureEditDialog.visible}
        featureData={openFeatureEditDialog.featureData}
        onSave={handleSaveFeature}
        onDelete={handleDeleteFeature}
        onClose={() =>
          setOpenFeatureEditDialog({
            visible: false,
            featureData: {
              feature: null,
              index: null,
            },
          })
        }
      />
      <SettingsGroupComponent
        name="features"
        title={t("page_account.page_template.features.features.title")}
        description={t("page_account.page_template.features.features.subtitle")}
        content={
          <div className="mt-6 grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8">
            {features &&
              features.map((feature: TemplateFeature, index: number) => (
                <div
                  key={feature.title}
                  onClick={() =>
                    setOpenFeatureEditDialog({
                      visible: true,
                      featureData: {
                        feature: feature,
                        index: index,
                      },
                    })
                  }
                  className="cursor-default"
                >
                  <TemplateFeatureItemComponent feature={feature} />
                </div>
              ))}
            <EmptyButtonStateComponent
              icon={SquaresPlusIcon}
              onClick={() =>
                setOpenFeatureEditDialog({
                  visible: true,
                  featureData: {
                    feature: null,
                    index: null,
                  },
                })
              }
              visible={true}
              text={t("page_account.page_template.features.add_feature.title")}
              description={t("page_account.page_template.features.add_feature.subtitle")}
            />
          </div>
        }
      />
    </>
  );
};

export default TemplateFeaturesSection;
