import "swiper/css";

import { Dialog, Disclosure, Transition } from "@headlessui/react";
import {
  AcademicCapIcon,
  CheckBadgeIcon,
  SparklesIcon,
  Square2StackIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import SearchButtonComponent from "../../../../components/common/buttons/SearchButtonComponent";
import SelectionButtonComponent from "../../../../components/common/buttons/SelectionButtonComponent";
import SEOComponent, { SEOPage } from "../../../../components/common/seo/SEOComponent";
import ProfileItemComponent from "../../../../components/profile/ProfileItem";
import { FeatureSection, FeatureSectionType } from "../../../../components/promotion/FeatureSection";
import { Profile } from "../../../../models/Profile";
import {
  getDownloadsUsers,
  getLatestUsers,
  getLikedUsers,
  getViewsUsers,
} from "../../../../services/firestore-service";
import { RootState } from "../../../../store";
import { TEMPLATES_AMOUNT } from "../../../../utils/constants";
import {
  defaultTimeOptions,
  getDefaultProfilesSortOptions,
  getDefaultProfilesTimeOptions,
  SortKey,
  SortOption,
  TimeOption,
} from "../../../../utils/utils-filter";
import PeriodId from "../../../../utils/utils-period";
import HeaderH1Item from "../../../../components/common/page/HeaderH1Item";
import { useTranslation } from "react-i18next";
import { Headline } from "../templates/headline/Headline";
import { getCreatorSpecificHeadline } from "./creators-headline-utils";

let initSearch: string | null = null;
let initTimeOptions: TimeOption[] = getDefaultProfilesTimeOptions();
let initProfilesSortOptions: SortOption[] = getDefaultProfilesSortOptions();

interface FilterOption {
  value: string;
  label: string;
  icon: React.FC | null;
  checked: boolean;
}

interface Filter {
  id: string;
  name: string;
  options: FilterOption[];
}

let initFilters: Filter[] = [
  {
    id: "type",
    name: "Type",
    options: [
      {
        value: "all",
        label: "common:filters:profile_types.all",
        checked: true,
        icon: Square2StackIcon,
      },
      {
        value: "verified",
        label: "common:filters:profile_types.verified",
        checked: false,
        icon: CheckBadgeIcon,
      },
      {
        value: "notion-certified",
        label: "common:filters:profile_types.notion_certified",
        checked: false,
        icon: AcademicCapIcon,
      },
      {
        value: "featured",
        label: "common:filters:profile_types.featured",
        checked: false,
        icon: SparklesIcon,
      },
    ],
  },
];

/**
 * Update filters from URL. This only happens on page load.
 * @param filters - The filters to update
 * @returns The updated filters
 */
function updateFiltersFromUrl(filters: Filter[]): Filter[] {
  const urlParams = new URLSearchParams(window.location.search);
  const newFilters = [...filters];
  newFilters.forEach((filter) => {
    const filterValues = urlParams.getAll(filter.id);
    if (filterValues.length > 0) {
      filter.options = filter.options.map((option) => ({
        ...option,
        checked: option.value === filterValues[0],
      }));
    }
  });
  return newFilters;
}

function updateTimeOptionsFromUrl(initTimeOptions: TimeOption[]): TimeOption[] {
  const urlParams = new URLSearchParams(window.location.search);
  const timeValue = urlParams.get("time");
  if (timeValue) {
    const value = initTimeOptions.map((option) => ({
      ...option,
      current: option.key === timeValue,
    }));
    console.log(value);
    return value;
  }
  return initTimeOptions;
}

function updateSortOptionsFromUrl(sortOptions: SortOption[]): SortOption[] {
  const urlParams = new URLSearchParams(window.location.search);
  const timeValue = urlParams.get("sort");
  if (timeValue) {
    const value = initProfilesSortOptions.map((option) => ({
      ...option,
      current: option.key === timeValue,
    }));
    console.log(value);
    return value;
  }
  return sortOptions;
}

function updateSearchFromUrl(initSearch: string | null): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  const searchValue = urlParams.get("search");
  if (searchValue && searchValue.length > 0) {
    return searchValue;
  }
  return initSearch;
}

/**
 * Update filters, sort options, time options, and search from URL.
 * This only happens on page load.
 */
(function updateFromUrl() {
  initFilters = updateFiltersFromUrl(initFilters);
  initProfilesSortOptions = updateSortOptionsFromUrl(initProfilesSortOptions);
  initTimeOptions = updateTimeOptionsFromUrl(initTimeOptions);
  initSearch = updateSearchFromUrl(initSearch);
})();

const ProfilesPage = () => {
  const { t } = useTranslation();
  const [mobileFiltersOpen, setMobileFiltersOpen] = React.useState(false);
  const [filters, setFilters] = React.useState(initFilters);
  const [sortOptions, setSortOptions] = React.useState(initProfilesSortOptions);
  const [timeOptions, setTimeOptions] = React.useState(initTimeOptions);
  const [searchValue, setSearchValue] = React.useState<string | null>(initSearch);
  const [filteredProfiles, setFilteredTemplates] = React.useState<Profile[]>([]);

  const [headline, setHeadline] = useState<Headline | null>(
    getCreatorSpecificHeadline(
      t,
      sortOptions.find((option) => option.current)!!,
      timeOptions.find((option) => option.current)!!,
      filters.find((filter) => filter.id === "type")?.options.find((option) => option.checked)?.value || "all",
      filters.find((filter) => filter.id === "category")?.options.find((option) => option.checked)?.label || null
    )
  );
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    // cleanup function
    return () => {
      setFilteredTemplates([]);
    };
  }, []);

  React.useEffect(() => {
    /**
     * Update URL with new filters. This happens when a filter is changed.
     */
    function updateUrl() {
      // Build new URL search params
      const params = new URLSearchParams();
      filters.forEach((filter) => {
        const checkedValues = filter.options
          .filter((option) => option.checked && option.value !== "all")
          .map((option) => option.label.toLowerCase());
        if (checkedValues.length > 0) {
          params.append(filter.id, checkedValues.join(","));
        }
      });

      sortOptions.forEach((option) => {
        if (option.current && option.default !== true) {
          params.append("sort", option.key);
        }
      });

      timeOptions.forEach((option) => {
        if (option.current && option.default != true) {
          params.append("time", option.key);
        }
      });

      if (searchValue != null && searchValue.length > 0) {
        params.append("search", searchValue);
      }

      // Update URL without reloading the page
      const neUrl = `${window.location.pathname}?${params.toString()}`;
      const queryString = params.toString();
      const newUrl = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;
      window.history.replaceState({ path: newUrl }, "", newUrl);
    }

    /**
     * Load profiles from the API and update the filtered profiles.
     */
    const loadTemplates = async () => {
      setLoading(true);

      // Your original code
      const sortOption = Object.values(sortOptions).find((option) => option.current);
      var profiles = [];

      // Load profiles by period id
      const periodId = timeOptions.find((option) => option.current)?.periodId || PeriodId.total();

      // Load profiles by sort type (likes, views, downloads)
      switch (sortOption?.key) {
        case SortKey.LIKES:
          profiles.push(...(await getLikedUsers(periodId, TEMPLATES_AMOUNT)));
          break;
        case SortKey.DOWNLOADS:
          profiles.push(...(await getDownloadsUsers(periodId, TEMPLATES_AMOUNT)));
          break;
        case SortKey.VIEWS:
          profiles.push(...(await getViewsUsers(periodId, TEMPLATES_AMOUNT)));
          break;
        case SortKey.LATEST:
          profiles.push(...(await getLatestUsers(TEMPLATES_AMOUNT)));
          break;
        default:
          profiles.push(...(await getLikedUsers(periodId, TEMPLATES_AMOUNT)));
          break;
      }

      // Use const for filteredProfiles since it doesn't need reassignment
      let filteredProfiles = profiles.filter((profile) => profile.creator);

      // Filter profiles by search
      if (searchValue) {
        const searchTerm = searchValue.toLowerCase().trim(); // Trim and convert to lowercase
        filteredProfiles = filteredProfiles.filter((profile) =>
          (profile.name + " " + profile.profileId).toLowerCase().includes(searchTerm)
        );
      }

      // Filter profiles by type (free, paid, featured)
      const typeFilter = filters.find((filter) => filter.id === "type");
      if (typeFilter) {
        const typeFilterOption = typeFilter.options.find((option) => option.checked);
        if (typeFilterOption) {
          switch (typeFilterOption.value) {
            case "notion-certified":
              filteredProfiles = filteredProfiles.filter((profile) => profile.notionCertified);
              break;
            case "featured":
              filteredProfiles = filteredProfiles.filter((profile) => profile.promotion != null);
              break;
            case "verified":
              filteredProfiles = filteredProfiles.filter((profile) => profile.verified);
              break;
            default:
              break;
          }
        }
      }

      const headline = getCreatorSpecificHeadline(
        t,
        sortOptions.find((option) => option.current)!!,
        timeOptions.find((option) => option.current)!!,
        filters.find((filter) => filter.id === "type")?.options.find((option) => option.checked)?.value || "all",
        filters.find((filter) => filter.id === "category")?.options.find((option) => option.checked)?.label || null
      );
      setHeadline(headline);

      // Set the filtered templates
      setFilteredTemplates(filteredProfiles);
      setLoading(false);
    };

    loadTemplates();
    updateUrl();
  }, [filters, timeOptions, sortOptions, searchValue, t]);

  /**
   * Handle filter change event. This is called when a filter is changed.
   * @param id - The ID of the filter that was changed
   * @param value - The value of the filter that was changed
   */
  function handleFilterChange(id: string, value: string) {
    console.log("handleFilterChange", id, value);
    const newFilters = [...filters];
    const filter = newFilters.find((filter) => filter.id === id);
    if (filter) {
      filter.options = filter.options.map((option) => ({
        ...option,
        checked: option.value === value ? !option.checked : false,
      }));
    }
    setFilters(newFilters);
  }

  /**
   * Handle sorting option change event. This is called when a sorting option is changed.
   * @param key - The key of the sorting option that was changed
   */
  function handleSortingOptionChange(newOption: SortOption) {
    console.log("handleSortingOptionChange", newOption);
    const newSortOptions = [...sortOptions];
    newSortOptions.forEach((option) => {
      option.current = option.key === newOption.key || false;
    });
    setSortOptions(newSortOptions);
  }

  /**
   * Handle time option change event. This is called when a time option is changed.
   * @param newOption - The key of the time option that was changed
   */
  function handleTimeOptionChange(newOption: TimeOption) {
    console.log("handleTimeOptionChange", newOption);
    const newTimeOptions = [...timeOptions];
    newTimeOptions.forEach((option) => {
      option.current = option.key === newOption.key || false;
    });
    setTimeOptions(newTimeOptions);
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:main_pages.page_creators.title") + t("seo:tabs.title_suffix"),
            t("seo:main_pages.page_creators.description")
          )
        }
      />
      <div>
        <div>
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className="mt-4 border-t border-gray-200">
                      <h3 className="sr-only">Categories</h3>

                      {filters.map((section) => (
                        <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">{section.name}</span>
                                <span className="ml-6 flex items-center"></span>
                              </Disclosure.Button>
                            </h3>
                            <div className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.value} className="flex items-center">
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      defaultChecked={option.checked}
                                      type="radio"
                                      className={
                                        "h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:outline-none"
                                      }
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        </Disclosure>
                      ))}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main>
            <div>
              <div>
                {headline && <HeaderH1Item title={headline.title} desc={headline.description} />}
                <div className="mt-6 grid grid-cols-1 items-center md:flex md:gap-x-4 space-x-4 space-y-4 md:space-x-0 md:space-y-0">
                  <div className="flex flex-grow md:flex md:items-center gap-x-4">
                    <SelectionButtonComponent
                      options={sortOptions}
                      onClick={(option, index) => handleSortingOptionChange(sortOptions[index])}
                    />

                    <SelectionButtonComponent
                      options={timeOptions}
                      onClick={(option, index) => handleTimeOptionChange(timeOptions[index])}
                    />

                    {filters.map((filter) => (
                      <SelectionButtonComponent
                        key={filter.id}
                        options={filter.options.map((option) => ({
                          name: option.label,
                          current: option.checked,
                          icon: option.icon || undefined,
                        }))}
                        onClick={(option, index) => {
                          handleFilterChange(filter.id, filter?.options[index].value || "");
                        }}
                      />
                    ))}
                  </div>
                  <SearchButtonComponent
                    baseClassName="lg:ml-auto"
                    value={searchValue || ""}
                    placeholder={t("common:search")}
                    onInputChange={setSearchValue}
                  />
                </div>
              </div>
            </div>

            <section aria-labelledby="products-heading" className="pb-24 pt-6">
              <h2 id="products-heading" className="sr-only">
                Creators
              </h2>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <Transition
                  show={filteredProfiles.length > 0}
                  enter="transition ease-out duration-300 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in duration-200 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="mt-6 grid grid-cols-2
                   gap-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6"
                  >
                    {filteredProfiles.map((profile: Profile) => (
                      <ProfileItemComponent discover={true} key={profile.profileId} profile={profile} />
                    ))}
                  </div>
                </Transition>
              </div>
            </section>
          </main>
        </div>
      </div>
      <FeatureSection type={FeatureSectionType.TEMPLATES} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(ProfilesPage);
