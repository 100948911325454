import { useTranslation } from "react-i18next";
import { LinkProps, NavLink } from "react-router-dom";
import { classNames } from "../../../utils/utils-react";
import { MouseEventHandler } from "react";

export const removeLanguageCode = (path: string): string => {
  return path.replace(/^\/[a-z]{2}(-[a-z]{2})?(\/|$)/, "/");
};

type CustomNavLinkProps = {
  baseClassName?: string;
  additionalClassName?: string;
  to: LinkProps["to"];
  children: React.ReactNode;
  onClick?: MouseEventHandler<any>;
};

const CustomNavLink: React.FC<CustomNavLinkProps> = ({ baseClassName, additionalClassName, to, children, onClick }) => {
  const isActive = removeLanguageCode(window.location.pathname) === removeLanguageCode(to.toString());
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language
  const languageAwareTo = currentLanguage === "en" ? to : `/${currentLanguage}${to}`;

  return (
    <NavLink
      to={languageAwareTo}
      onClick={onClick}
      className={classNames(
        baseClassName ?? "",
        isActive ? "font-medium text-indigo-500" : "font-medium text-gray-900",
        "transition-all ease-in-out duration-300 " + additionalClassName
      )}
    >
      {children}
    </NavLink>
  );
};

export default CustomNavLink;
