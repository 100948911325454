import {
  AdjustmentsHorizontalIcon,
  BanknotesIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
  GlobeAsiaAustraliaIcon,
  PresentationChartLineIcon,
  Squares2X2Icon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function FeaturesComponent() {
  const { t } = useTranslation();
  const Audience = {
    CREATOR: "Creator",
    VIEWER: "Viewer",
    BOTH: "Both",
  };

  const features = [
    {
      name: t("features:page_features.features.more_curations.title"),
      description: t(
        "features:page_features.features.more_curations.description"
      ),
      icon: PresentationChartLineIcon,
      audience: Audience.CREATOR,
    },
    {
      name: t(
        "features:page_features.features.multilingual_notion_templates.title"
      ),
      description: t(
        "features:page_features.features.multilingual_notion_templates.description"
      ),
      icon: GlobeAltIcon,
      audience: Audience.VIEWER,
    },
    {
      name: t(
        "features:page_features.features.high_reach_with_localization.title"
      ),
      description: t(
        "features:page_features.features.high_reach_with_localization.description"
      ),
      icon: GlobeAsiaAustraliaIcon,
      audience: Audience.CREATOR,
    },
    {
      name: t(
        "features:page_features.features.creator_focused_dashboard.title"
      ),
      description: t(
        "features:page_features.features.creator_focused_dashboard.description"
      ),
      icon: UserCircleIcon,
      audience: Audience.CREATOR,
    },
    {
      name: t(
        "features:page_features.features.expanded_template_selection.title"
      ),
      description: t(
        "features:page_features.features.expanded_template_selection.description"
      ),
      icon: Squares2X2Icon,
      audience: Audience.VIEWER,
    },
    {
      name: t("features:page_features.features.advanced_filtering.title"),
      description: t(
        "features:page_features.features.advanced_filtering.description"
      ),
      icon: AdjustmentsHorizontalIcon,
      audience: Audience.VIEWER,
    },
    {
      name: t(
        "features:page_features.features.modern_and_dynamic_design.title"
      ),
      description: t(
        "features:page_features.features.modern_and_dynamic_design.description"
      ),
      icon: ComputerDesktopIcon,
      audience: Audience.BOTH,
    },
    {
      name: t("features:page_features.features.mobile_app.title"),
      description: t("features:page_features.features.mobile_app.description"),
      icon: DevicePhoneMobileIcon,
      audience: Audience.VIEWER,
    },
    {
      name: t("features:page_features.features.low_affiliate_fee.title"),
      description: t(
        "features:page_features.features.low_affiliate_fee.description"
      ),
      icon: BanknotesIcon,
      audience: Audience.CREATOR,
    },
  ];
  return (
    <div className="py-16 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {t("features:page_features.title")}
            </h2>
            <p className="mt-4 text-md leading-8 text-gray-600 max-w-2xl mx-auto">
              {t("features:page_features.subtitle")}
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-4 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="bg-gray-50 rounded-lg p-4 sm:p-5 text-center py-4"
              >
                <feature.icon className="w-6 h-6 inline mr-2 text-indigo-500" />
                <p className="mt-4 text-md font-bold tracking-tight text-gray-900">
                  {feature.name}
                </p>
                <p className="mt-1 text-sm font-normal leading-6 text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
