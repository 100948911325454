import { AcademicCapIcon, BookOpenIcon, CheckBadgeIcon, ClockIcon, CurrencyDollarIcon, FaceSmileIcon, GiftIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { profile } from "console";
import { Link } from "react-router-dom";
import { setPathId, Destination, appendParamsToPath, Args, setPathAndAppend } from "../../../../utils/constants-navigation";
import { getProfileId } from "../../../../utils/utils-profile";
import { classNames } from "../../../../utils/utils-react";
import { ExternalLinks } from "../../../../utils/constants";
import HeaderH1LargeItem from "../../../../components/common/page/HeaderH1LargeItem";
import { useTranslation } from "react-i18next";
import CustomLink from "../../../../components/common/links/CustomLink";

type Action = {
  icon: React.ReactNode;
  title: string;
  desc: string;
  destination: string;
  newTab?: boolean;
  isExternal?: boolean;
};

type DiscoverActionItemProps = {
  action: Action;
};

const DiscoverActionItem: React.FC<DiscoverActionItemProps> = ({ action }) => {
  return (
    <CustomLink to={action.destination} key={action.destination} target={action.newTab ? "_blank" : "_self"} isExternal={action.isExternal}>
      <div className="transition-all ease-in-out duration-300 group relative bg-indigo-500/[0.025] divide-y divide-solid divide-gray-200/50 shadow-sm rounded-lg border border-1 border-gray-200/50 hover:shadow-md hover:md:scale-[1.02]">
        <div className="flex items-center">
          <div className="flex px-4 items-center">
            <div className="w-full h-full aspect-[1/1]">
              <div className="flex gap-x-2 items-center">{action.icon}</div>
            </div>
          </div>
          <div className="bg-white rounded-r-lg border-l px-3 py-3">
            <h3 className="text-md font-semibold text-gray-800 line-clamp-1">{action.title}</h3>
            <div className="mt-1 flex gap-x-2 items-center">
              <p className="text-sm text-gray-600 leading-5 line-clamp-2">{action.desc}</p>
            </div>
          </div>
        </div>
      </div>
    </CustomLink>
  );
};

type DiscoverActionsSectionProps = {};

export const DiscoverActionsSection: React.FC<DiscoverActionsSectionProps> = ({}) => {
  const { t } = useTranslation();
  const iconClasses = "w-6 h-6 m-2 shrink-0 text-gray-600";
  const pageInfo: Action[] = [
    {
      icon: <ClockIcon className={iconClasses} />,
      title: t("common:page_discover.action_curated_new_notion_templates.title"),
      desc: t("common:page_discover.action_curated_new_notion_templates.description"),
      destination: Destination.TEMPLATES_LATEST,
    },
    {
      icon: <UserCircleIcon className={iconClasses} />,
      title: t("common:page_discover.action_notion_creators.title"),
      desc: t("common:page_discover.action_notion_creators.description"),
      destination: Destination.USERS,
    },
    {
      icon: <BookOpenIcon className={iconClasses} />,
      title: t("common:page_discover.action_notion_templates.title"),
      desc: t("common:page_discover.action_notion_templates.description"),
      destination: setPathAndAppend(Destination.TEMPLATES, {
        [Args.SORT]: "views",
        [Args.TYPE]: "all",
        [Args.TIME]: "year",
      }),
    },
    {
      icon: <GiftIcon className={iconClasses} />,
      title: t("common:page_discover.action_free_notion_templates.title"),
      desc: t("common:page_discover.action_free_notion_templates.description"),
      destination: setPathAndAppend(Destination.TEMPLATES, {
        [Args.SORT]: "views",
        [Args.TYPE]: "free",
        [Args.TIME]: "year",
      }),
    },
    {
      icon: <CurrencyDollarIcon className={iconClasses} />,
      title: t("common:page_discover.action_premium_notion_templates.title"),
      desc: t("common:page_discover.action_premium_notion_templates.description"),
      destination: setPathAndAppend(Destination.TEMPLATES, {
        [Args.SORT]: "views",
        [Args.TYPE]: "paid",
        [Args.TIME]: "year",
      }),
    },
    {
      icon: <img className={classNames(iconClasses, "w-8 h-8")} src={process.env.PUBLIC_URL + "/icons/pinterest.svg"} />,
      title: t("common:page_discover.action_discover_on_pinterest.title"),
      desc: t("common:page_discover.action_discover_on_pinterest.description"),
      destination: ExternalLinks.ELCOVIA_PINTEREST,
      newTab: true,
      isExternal: true,
    },
  ];

  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/elcovia-a8c37.appspot.com/o/users%2Fkm67XR4fIAYeXTBeQ9Pklt8Y6vO2%2FAvatar_white%20background.png?alt=media&token=d411a0b2-c960-45f7-b419-2da875361bc0",
    "https://firebasestorage.googleapis.com/v0/b/elcovia-a8c37.appspot.com/o/users%2FpodY5OUy1lhbsxKfczNsX5n6LEC2%2FElcovial.png?alt=media&token=69fdce9e-76be-4825-87f3-289bf88f47a0",
    "https://firebasestorage.googleapis.com/v0/b/elcovia-a8c37.appspot.com/o/users%2FyF5vOpCkhWYMVtGzf8hTuk5TcmP2%2FHeadshot%209%20lilac%20bg%20300x300.png?alt=media&token=6bdf3e6a-9725-4c5d-bb9d-e56ff0fced53",
    "https://firebasestorage.googleapis.com/v0/b/elcovia-a8c37.appspot.com/o/users%2Fb9XTNVfmwnhBDVLJtIY885GYDml2%2Favatar-joel--03.jpg?alt=media&token=428303f5-f3cd-48fb-b9e8-a511e51c7c79",
    "https://firebasestorage.googleapis.com/v0/b/elcovia-a8c37.appspot.com/o/users%2FlEdpdPX53IOH483XG5rV6Y8dkSA3%2FScreenshot%202024-03-12%20at%2022-25-15%20Untitled%20design.png?alt=media&token=6454cad2-3a99-4da8-91ca-f69d03163903",
    "https://firebasestorage.googleapis.com/v0/b/elcovia-a8c37.appspot.com/o/users%2F9lJc4RwlptffQnDCLl49gU0T79G2%2FSMALL%20WINS.png?alt=media&token=06127058-71b5-48fd-b6da-e5eb86dcc53c",
  ];

  return (
    <>
      <HeaderH1LargeItem title={t("common:page_discover.title")} desc={t("common:page_discover.subtitle")} titleClassName="max-w-3xl lg:max-w-4xl xl:max-w-5xl" />
      <div className="mt-6 flex ml-2">
        {imageUrls.map((url, index) => (
          <img key={url} src={url} alt="" className={classNames("-ml-2 w-8 h-8 rounded-full bg-white border border-1 border-white")} />
        ))}
      </div>
      <div className="mt-12" />
      <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {pageInfo.map((info, index) => {
          return <DiscoverActionItem key={index} action={info} />;
        })}
      </div>
    </>
  );
};
