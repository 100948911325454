import { Dialog, Disclosure, Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  BuildingStorefrontIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  FaceSmileIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment, MouseEventHandler, useState } from "react";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { LinkProps, NavLink, useLocation, useNavigate, useRoutes } from "react-router-dom";

import { logout } from "../../../services/action-service";
import { RootState } from "../../../store";
import { ExternalLinks } from "../../../utils/constants";
import { ARGS_PARAM_DISCOVER, Destination, setPathIdAndAppend } from "../../../utils/constants-navigation";
import { Logger } from "../../../utils/utils-logging";
import { getProfileId } from "../../../utils/utils-profile";
import { classNames } from "../../../utils/utils-react";
import HeaderProfileComponent, { ActionSectionItem, LinkSectionItem } from "./profile_state/HeaderProfileComponent";
import { useTranslation } from "react-i18next";
import CustomNavLink from "../../common/links/CustomNavLink";
import CustomLink from "../../common/links/CustomLink";
import LanguageSwitcher from "../common/LanguageSwitcher";

const logger = new Logger("Header");

const PublicHeaderComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const headerItems = [
    { title: "common:discover", path: Destination.DISCOVER },
    { title: "common:templates", path: Destination.TEMPLATES },
    { title: "common:categories", path: Destination.TEMPLATES_CATEGORIES },
    { title: "common:creators", path: Destination.USERS },
  ];

  const [scrolled, setScrolled] = useState(false);

  /**
   * Handle scroll event.
   */
  React.useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.pageYOffset > 80);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /**
   * Handle logout button click.
   */
  const handleLogout = () => {
    logout(dispatch);
    navigate(Destination.DISCOVER);
  };

  const linkSectionItems: LinkSectionItem[] = [
    {
      name: "common:header_links.profile.title",
      description: "common:header_links.profile.description",
      href: setPathIdAndAppend(Destination.USER_ID_SHORT, getProfileId(userProfile), ARGS_PARAM_DISCOVER),
      icon: FaceSmileIcon,
    },
    {
      name: "common:header_links.dashboard.title",
      description: "common:header_links.dashboard.title",
      href: Destination.ACCOUNT_DASHBOARD,
      icon: BuildingStorefrontIcon,
    },
    {
      name: "common:header_links.settings.title",
      description: "common:header_links.settings.description",
      href: Destination.ACCOUNT_SETTINGS,
      icon: Cog6ToothIcon,
    },
    {
      name: "common:header_links.logout.title",
      description: "common:header_links.logout.description",
      onClick: () => handleLogout(),
      icon: ArrowLeftOnRectangleIcon,
    },
  ];

  const actionSectionItems: ActionSectionItem[] = [
    {
      name: "common:mobile_apps",
      href: Destination.APPS,
      icon: DevicePhoneMobileIcon,
    },
    {
      name: "common:elcovia_gpt",
      href: ExternalLinks.ELCOVIA_GTP,
      icon: ChatBubbleBottomCenterTextIcon,
    },
    {
      name: "common:contact",
      href: Destination.CONTACT,
      icon: EnvelopeIcon,
    },
  ];

  return (
    <header className="transition-all ease-in-out duration-300 bg-white fixed top-0 left-0 right-0 z-10 border-b border-1 border-gray-200">
      <nav className="mx-auto flex max-w-7xl items-center justify-between h-20 px-4 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <CustomLink to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Elcovia</span>
            <div className="flex flex-inline items-center gap-x-4">
              <img className="h-6 w-6" src={process.env.PUBLIC_URL + "/logo.png"} alt="Elcovia Logo" />
              <img className="h-8 w-auto" src={process.env.PUBLIC_URL + "/text-logo.png"} alt="Elcovia Text Logo" />
            </div>
          </CustomLink>
        </div>
        <div className="flex md:hidden">
          <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden md:pl-12 md:flex md:gap-x-12">
          {headerItems.map((item, index) => (
            <CustomNavLink baseClassName={"text-md"} key={item.title} to={item.path}>
              {t(item.title)}
            </CustomNavLink>
          ))}
        </Popover.Group>
        <div className="hidden md:flex md:flex-1 md:justify-end items-center gap-x-4">
          <HeaderProfileComponent linkSectionItems={linkSectionItems} actionSectionItems={actionSectionItems} />
          <div className="hidden lg:block">
            <LanguageSwitcher isSmall={true} />
          </div>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <CustomLink to="/" className="flex -m-1.5 p-1.5 items-center">
              <span className="sr-only">Elcovia</span>
              <img className="h-6 w-6 w-auto" src="../logo.png" alt="Elcovia Logo" />{" "}
            </CustomLink>

            <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {headerItems.map((item, index) => (
                  <CustomNavLink
                    onClick={() => setMobileMenuOpen(false)}
                    baseClassName="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    key={index}
                    to={item.path}
                  >
                    {t(item.title)}
                  </CustomNavLink>
                ))}
              </div>
              <div className="py-6">
                {isSignedIn ? (
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                          <img src={userProfile?.image} alt={userProfile?.name || ""} className="w-6 h-6 rounded-full drop-shadow max" />
                          <h3 className="ml-6 mr-12">{userProfile?.name}</h3>

                          <ChevronDownIcon className={classNames(open ? "rotate-180" : "", " h-5 w-5 flex-none")} aria-hidden="true" />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {linkSectionItems.map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              onClick={item.onClick}
                              href={item.href}
                              className="flex items-center rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              <item.icon className="h-4 w-4 text-gray-600 group-hover:text-indigo-600 mr-4" aria-hidden="true" />
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ) : location.pathname != "/login" ? (
                  <a
                    href=""
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t("common:login")}
                  </a>
                ) : null}
              </div>

              <div className="py-6">
                <div className="space-y-2">
                  {actionSectionItems.map((item) => (
                    <a
                      key={item.name}
                      onClick={item.onClick}
                      href={item.href}
                      className="flex items-center rounded-lg py-2 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      <item.icon className="h-4 w-4 text-gray-600 group-hover:text-indigo-600 mr-4" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(PublicHeaderComponent);
