import React from "react";
import { useTranslation } from "react-i18next";

export type Action = {
  title: string;
  description: string;
  href: string;
  Icon: React.ElementType;
  style: string;
};

type ActionItemProps = {
  actions: Action[];
};

const ActionItem: React.FC<Action> = ({ title, description, href, Icon, style }) => {
  const { t } = useTranslation();
  return (
    <div className="group relative p-4 pl-6 pr-6 flex items-center transition-all ease-in-out duration-300 bg-white rounded-lg border border-gray-200/50 hover:shadow-md hover:md:scale-[1.02]">
      <div>
        <span className={`inline-flex rounded-lg p-3 ${style}`}>
          <Icon className="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div className="ml-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          <a href={href} className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            {t(title)}
          </a>
        </h3>
        <p className="mt-1 text-xs text-gray-500 leading-5">{t(description)}</p>
      </div>
    </div>
  );
};

const HomeActionsSection: React.FC<ActionItemProps> = ({ actions }) => {
  return (
    <div className="sm:grid sm:grid-cols-2 gap-4 mb-12">
      {actions.map((action) => (
        <ActionItem key={action.title} {...action} />
      ))}
    </div>
  );
};

export default HomeActionsSection;
