import { CurrencyDollarIcon, GlobeAmericasIcon, SparklesIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Autoplay, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import LoadingScreenComponent from "../../../components/common/loading/LoadingScreenComponent";
import SEOComponent, { SEOPage } from "../../../components/common/seo/SEOComponent";
import { TemplatePromotionConfig } from "../../../models/data/payment/TemplatePromotionConfig";
import { Template } from "../../../models/Template";
import { getTemplateById } from "../../../services/firestore-service";
import {
  createPaypalTemplatePromotionOrder,
  createStripeTemplatePromotionOrder,
} from "../../../services/functions-service";
import { getTemplatePromotionConfig } from "../../../services/remote-configs-service";
import { handleStripeResponse } from "../../../services/stripe-service";
import { RootState } from "../../../store";
import { localizedValue } from "../../../utils/supported-locales";
import { durationKeyToLabel, timestampToLocalDate } from "../../../utils/utils-formatting";
import { Logger } from "../../../utils/utils-logging";
import PromotionComponent, { Benefit, Showcase } from "./PromotionComponent";

const logger = new Logger("TemplatePromotionScreen");

interface TemplatePromotionScreenParams {
  id: string;
  [key: string]: any;
}

const TemplatePromotionScreen: React.FC<TemplatePromotionScreenParams> = ({}) => {
  const { id } = useParams<TemplatePromotionScreenParams>();
  const { t } = useTranslation();
  const [template, setTemplate] = React.useState<Template | null>(null);
  const [promotionable, setPromotionable] = React.useState<boolean>(true);
  const [onBlockPayButton, setOnBlockPayButton] = React.useState<boolean>(true);

  const [selectedTemplatePromotionConfig, setSelectedTemplatePromotionConfig] =
    React.useState<TemplatePromotionConfig | null>(null);

  const [templatePromotionConfig, setTemplatePromotionConfig] = React.useState<TemplatePromotionConfig[] | null>(null);

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const sessionProfile = useSelector((state: RootState) => state.user.profile);

  /**
   * Set promotionable state.
   */
  React.useEffect(() => {
    if (!template) return;
    setPromotionable(template.promotion == null);
  }, [template]);

  /**
   * Load initial template data.
   */
  React.useEffect(() => {
    if (templatePromotionConfig == null) {
      const fetchTemplatePromotionConfig = async () => {
        try {
          const config = await getTemplatePromotionConfig();
          setTemplatePromotionConfig(config);
          setSelectedTemplatePromotionConfig(config[1]);
          setOnBlockPayButton(false);
          logger.log("fetchTemplatePromotionConfig", {
            config,
          });
        } catch (e) {
          logger.logError("fetchTemplatePromotionConfig", {
            e,
          });
        }
      };
      fetchTemplatePromotionConfig();
    }

    if (template == null) {
      const fetchTemplate = async () => {
        try {
          const template = await getTemplateById(id);

          setTemplate(template);
          logger.log("fetchTemplate", {
            id,
            template,
          });
        } catch (e) {
          logger.logError("fetchTemplate", {
            id,
            e,
          });
        }
      };
      fetchTemplate();
    }
  }, [id]);

  /**
   * Promote template.
   * @param template Template to promote.
   */
  async function promoteTemplate(template: Template) {
    try {
      setOnBlockPayButton(true);
      const templateId = template.id;
      const purchaseKey = selectedTemplatePromotionConfig!!.key;
      // promoteTemplatePayPal(templateId, purchaseKey);
      promoteTemplateStripe(templateId, purchaseKey);
    } catch (error) {
      console.log(error);
    }
    // setOnBlockPayButton(false);
  }

  /**
   * Promote template with Stripe.
   * @param templateId Template ID.
   * @param purchaseKey Purchase key.
   */
  async function promoteTemplateStripe(templateId: string, purchaseKey: string) {
    const response = await createStripeTemplatePromotionOrder(templateId, purchaseKey);
    await handleStripeResponse(response.data);
  }

  /**
   * Promote template with PayPal.
   * @param templateId Template ID.
   * @param purchaseKey Purchase key.
   */
  async function promoteTemplatePayPal(templateId: string, purchaseKey: string) {
    const result = await createPaypalTemplatePromotionOrder(templateId, purchaseKey);
    console.log("Result:" + result);
    const jsonResult = JSON.parse(result.data as string);
    const url = jsonResult.links[1].href;
    window.location.href = url;
  }

  const benefits: Benefit[] = [
    {
      name: "page_account.page_promotion_template.benefits.increased_visibility.title",
      description: t("page_account.page_promotion_template.benefits.increased_visibility.description"),
      Icon: GlobeAmericasIcon,
    },
    {
      name: "page_account.page_promotion_template.benefits.enhanced_recognition.title",
      description: t("page_account.page_promotion_template.benefits.enhanced_recognition.description"),
      Icon: SparklesIcon,
    },
    {
      name: "page_account.page_promotion_template.benefits.attract_enthusiasts.title",
      description: t("page_account.page_promotion_template.benefits.attract_enthusiasts.description"),
      Icon: UserGroupIcon,
    },
    {
      name: "page_account.page_promotion_template.benefits.revenue_expansion.title",
      description: t("page_account.page_promotion_template.benefits.revenue_expansion.description"),
      Icon: CurrencyDollarIcon,
    },
  ];

  const showcases: Showcase[] = [
    {
      image: process.env.PUBLIC_URL + "/imgs/showcase_template_promotion.png",
      title: t("page_account.page_promotion_template.showcases.title"),
      description: t("page_account.page_promotion_template.showcases.description"),
    },
  ];

  if (template == null || selectedTemplatePromotionConfig == null || templatePromotionConfig == null) {
    return <LoadingScreenComponent />;
  }

  return (
    <>
      <SEOComponent
        seo={
          new SEOPage(
            t("seo:account_pages.page_account_template_promotion.title") + t("seo:tabs.title_suffix"),
            t("seo:account_pages.page_account_template_promotion.description")
          )
        }
      />

      <PromotionComponent
        title={t("page_account.page_promotion_template.title_x", { title: localizedValue(template?.title) })}
        promotionable={promotionable}
        price={selectedTemplatePromotionConfig.price}
        currencyCode={selectedTemplatePromotionConfig.currencyCode}
        preTitleChildren={
          <div className="aspect-[16/9] w-120 shadow mx-auto mb-12">
            {(localizedValue(template.coverImages)?.length || 0) > 1 ? (
              <Swiper
                pagination={{ clickable: true }}
                modules={[EffectCreative, Autoplay]}
                navigation={true}
                loop={true}
                className="coverImagesSwiper"
                speed={1000}
                autoplay={{
                  pauseOnMouseEnter: true,
                }}
              >
                {localizedValue(template.coverImages)?.map((image: string) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt={localizedValue(template.title)!!}
                      className="h-full w-full object-cover object-center  lg:h-full lg:w-full rounded-t-md"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <img
                src={localizedValue(template.coverImages)?.[0] || ""}
                alt={localizedValue(template.title)!!}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg"
              />
            )}
          </div>
        }
        description={t("page_account.page_promotion_template.subtitle")}
        postDescriptionChildren={
          <div>
            {promotionable ? (
              <>
                {/* Duration picker */}
                <div className="mt-12">
                  <div className="mt-1 flex items-center justify-between">
                    <h2 className="text-sm font-medium text-gray-900">{t("duration")}</h2>
                  </div>

                  <div className="mt-3">
                    <p className="sr-only">{t("choose_a_duration")}</p>
                    <div className="grid grid-cols-3 gap-4 sm:grid-cols-3">
                      {templatePromotionConfig.map((config) => (
                        <ButtonComponent
                          key={config.key}
                          baseClassNames="w-full"
                          text={durationKeyToLabel(t, config.duration)}
                          onClick={() => setSelectedTemplatePromotionConfig(config)}
                          style={selectedTemplatePromotionConfig == config ? "colored" : "standard"}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {/* Divider separator */}
                <div className="border-t border-gray-200 mt-8 pt-6" />

                {/* Buy button */}
                <div className="mt-6 grid ">
                  <ButtonComponent
                    text={t("promote")}
                    style="structural"
                    baseClassNames="w-64"
                    loading={onBlockPayButton}
                    onClick={() => promoteTemplate(template)}
                    disabled={onBlockPayButton}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mt-12 border-y py-12 border-gray-200">
                  <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                    <Trans
                      i18nKey="page_account.page_promotion_template.active_promotion"
                      components={{ span: <span className="text-green-500" /> }}
                    />
                  </h3>
                  <p className="mt-4 text-md font-normal text-gray-500 leading-6">
                    <Trans
                      i18nKey="page_account.page_promotion_template.active_promotion_subtitle"
                      components={{ span: <span className="font-semibold" /> }}
                      values={{ end: timestampToLocalDate(template.promotion!.end) }}
                    />
                  </p>
                </div>
              </>
            )}
          </div>
        }
        benefits={benefits}
        showcases={showcases}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(TemplatePromotionScreen);
