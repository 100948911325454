import "swiper/css";

import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ButtonComponent from "../../../../components/common/buttons/ButtonComponent";
import HeaderH2Item from "../../../../components/common/page/HeaderH2Item";
import SEOComponent, { SEOPage } from "../../../../components/common/seo/SEOComponent";
import FocusLineComponent from "../../../../components/common/text/FocusLineComponent";
import ProfileItemComponent from "../../../../components/profile/ProfileItem";
import ProfileSmallItemComponent from "../../../../components/profile/ProfileSmallItem";
import TemplateItemComponent from "../../../../components/template/TemplateItem";
import { Category } from "../../../../models/Category";
import { DiscoverCounterType, DiscoverDataType } from "../../../../models/Discover";
import { Profile } from "../../../../models/Profile";
import { Template } from "../../../../models/Template";
import {
  getDiscover,
  getFeaturedTemplates,
  getFeaturedUsers,
  getLatestTemplates,
  getTemplatesByIds,
  getUsersByIds,
} from "../../../../services/firestore-service";
import { RootState } from "../../../../store";
import {
  appendParamsToPath,
  Args,
  Destination,
  setPathAndAppend,
  setPathIdAndAppend,
} from "../../../../utils/constants-navigation";
import { DiscoverActionsSection } from "./DiscoverActionsSection";
import HeaderH1LargeItem from "../../../../components/common/page/HeaderH1LargeItem";
import { classNames } from "../../../../utils/utils-react";
import { useTranslation } from "react-i18next";

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface DiscoverType {
  key?: string;
  priority: number;
  isCarousel: boolean;
  firstLine?: string;
  firstLineSubtitle?: string;
  secondLine?: string;
  buttonText?: string;
  buttonHref?: string;
  image?: string;
  cols?: number;
  smCols?: number;
  lgCols?: number;
  xlCols?: number;
  gap?: string;
  detailedBar: boolean;
  smallProfileView: boolean;
}

type DiscoverData = Template[] | Profile[];

/** Check if the data is an array of templates */
function isTemplateArray(data: any): data is Template[] {
  return Array.isArray(data) && data.every((item) => item instanceof Template);
}

/** Check if the data is an array of profiles */
function isProfileArray(data: any): data is Profile[] {
  return Array.isArray(data) && data.every((item) => item instanceof Profile);
}

const DiscoverPage = () => {
  const { t } = useTranslation();
  const [templateMap, setTemplateMap] = React.useState<Map<DiscoverType, DiscoverData>>(new Map());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPeriodLabel = (periodTime: string) => {
    if (periodTime?.includes("week")) return "common:time_period.week";
    else if (periodTime?.includes("month")) return "common:time_period.month";
    else if (periodTime?.includes("year")) return "common:time_period.year";
    else if (periodTime?.includes("total")) return "common:time_period.total";
  };

  const getTypeLabel = (type: DiscoverCounterType) => {
    if (type === DiscoverCounterType.LIKES) return "common:discover_counter_type.likes";
    else if (type === DiscoverCounterType.VIEWS) return "common:discover_counter_type.views";
  };

  const discoverTypes: Record<string, DiscoverType> = {
    FEATURED: {
      key: "featured",
      priority: 1,
      isCarousel: true,
      firstLine: "common:featured",
      secondLine: "common:templates",
      gap: "h-6",
      detailedBar: false,
      smallProfileView: false,
    },
    FEATURED_ALL: {
      key: "featuredAll",
      priority: 2,
      isCarousel: false,
      firstLine: "common:featured",
      cols: 1,
      smCols: 2,
      lgCols: 4,
      xlCols: 4,
      detailedBar: false,
      smallProfileView: false,
      buttonText: "All",
      buttonHref: setPathAndAppend(Destination.TEMPLATES, {
        [Args.TYPE]: "featured",
        [Args.SORT]: "latest",
      }),
    },
    FEATURED_PROFILES: {
      key: "featuredProfiles",
      priority: 3,
      isCarousel: false,
      firstLine: "common:featured",
      secondLine: "common:creators",
      cols: 1,
      smCols: 2,
      lgCols: 4,
      xlCols: 4,
      smallProfileView: false,
      detailedBar: false,
      buttonText: "All",
      buttonHref: setPathAndAppend(Destination.USERS, {
        [Args.TYPE]: "featured",
      }),
    },
    NEW_TEMPLATES: {
      key: "newTemplates",
      priority: 4,
      isCarousel: false,
      firstLine: "common:new",
      cols: 1,
      smCols: 2,
      lgCols: 3,
      xlCols: 4,
      detailedBar: false,
      smallProfileView: false,
      buttonHref: setPathAndAppend(Destination.TEMPLATES, {
        [Args.SORT]: "latest",
      }),
    },
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const map = new Map<DiscoverType, DiscoverData>();

      const featuredTemplates = await getFeaturedTemplates();
      if (featuredTemplates.length < 4) {
        map.set(discoverTypes.FEATURED, featuredTemplates);
      } else {
        map.set(discoverTypes.FEATURED_ALL, featuredTemplates);
      }
      setTemplateMap(_.cloneDeep(map));
      const featuredCreators = await getFeaturedUsers();
      setTemplateMap(_.cloneDeep(map));
      map.set(discoverTypes.FEATURED_PROFILES, featuredCreators);
      setTemplateMap(_.cloneDeep(map));
      map.set(discoverTypes.NEW_TEMPLATES, await getLatestTemplates(8));
      setTemplateMap(_.cloneDeep(map));

      const discover = await getDiscover();
      for (const item of discover?.data?.values() || []) {
        if (item.counterType != DiscoverCounterType.VIEWS) continue;
        if (!item.timePeriod.includes("month_")) continue;
        if (item.dataType === DiscoverDataType.TEMPLATES) {
          const label = getPeriodLabel(item.timePeriod);
          const typeLabel = getTypeLabel(item.counterType);
          const templates = await getTemplatesByIds(item.data);
          const type = {
            key: item.getKey(),
            priority: 20,
            isCarousel: false,
            firstLine: typeLabel,
            secondLine: label,
            cols: 1,
            smCols: 2,
            lgCols: 4,
            xlCols: 4,
            displaySeeMoreButton: true,
            detailedBar: false,
            smallProfileView: false,
            buttonHref: setPathAndAppend(Destination.TEMPLATES, {
              [Args.SORT]: item.counterType,
              [Args.TIME]: "month",
            }),
          };
          map.set(type, templates);
          setTemplateMap(_.cloneDeep(getSortedMap(map)));
        } else if (item.dataType === DiscoverDataType.TEMPLATES_CATEGORY && item.data.length > 2) {
          const label = getPeriodLabel(item.timePeriod);
          const typeLabel = getTypeLabel(item.counterType);
          const templates = await getTemplatesByIds(item.data);
          if (!item.categoryKey) return;
          const category = Object.values(Category).find((category) => category.key === item.categoryKey);
          if (!category) return;
          const type = {
            key: item.getKey(),
            priority: 21,
            isCarousel: false,
            firstLine: category.title,
            firstLineSubtitle: category.desc,
            image: category.image,
            secondLine: label,
            cols: 1,
            smCols: 2,
            lgCols: 4,
            xlCols: 4,
            detailedBar: false,
            smallProfileView: false,
            displaySeeMoreButton: true,
            buttonHref: setPathAndAppend(Destination.TEMPLATES, {
              [Args.CATEGORY]: encodeURIComponent(category.title.toLowerCase()),
              [Args.TIME]: "total",
              [Args.SORT]: item.counterType,
            }),
          };
          map.set(type, templates);
          setTemplateMap(_.cloneDeep(getSortedMap(map)));
        } else if (item.dataType === DiscoverDataType.CREATORS) {
          const label = getPeriodLabel(item.timePeriod);
          const typeLabel = getTypeLabel(item.counterType);
          const users = await getUsersByIds(item.data);
          const type = {
            key: item.getKey(),
            priority: 22,
            isCarousel: false,
            firstLine: typeLabel,
            secondLine: label,
            cols: 1,
            smCols: 2,
            lgCols: 4,
            xlCols: 5,
            detailedBar: false,
            smallProfileView: true,
            displaySeeMoreButton: true,
            buttonHref: setPathAndAppend(Destination.USERS, {
              [Args.SORT]: item.counterType,
              [Args.TIME]: "month",
            }),
          };
          map.set(type, users);
          setTemplateMap(_.cloneDeep(getSortedMap(map)));
        }
      }
      setTemplateMap(getSortedMap(map));
    };

    fetchData();
  }, []);

  function getSortedMap(map: Map<DiscoverType, DiscoverData>) {
    return new Map([...map.entries()].sort((a, b) => a[0].priority - b[0].priority));
  }

  return (
    <>
      <SEOComponent
        seo={new SEOPage(t("seo:main_pages.page_discover.title"), t("seo:main_pages.page_discover.description"))}
      />
      <div>
        <DiscoverActionsSection />
        <div className="mt-12" />
        {Array.from(templateMap.entries())
          .filter(([discoverType, discoverData]) => discoverData.length > 0) // filter out empty data
          .map(([discoverType, discoverData]) => (
            <section key={"discover" + discoverType.key}>
              <div className="pb-8">
                {discoverType.firstLine && (
                  <>
                    <div className="flex gap-x-6 items-center">
                      {discoverType.image && <img src={discoverType.image} alt="Discover" className="h-52" />}
                      <div>
                        <HeaderH2Item
                          title={t(discoverType.firstLine)}
                          desc={t(discoverType.firstLineSubtitle || "")}
                        />
                        {discoverType.secondLine && (
                          <FocusLineComponent
                            key={"secondLine" + discoverType + discoverType.secondLine}
                            baseClassName={discoverType.image ? "mt-6" : "mt-3"}
                            text={t(discoverType.secondLine)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {!discoverType.isCarousel ? (
                <div
                  className={`grid grid-cols-${discoverType.cols || 1} gap-y-6 gap-x-6 sm:grid-cols-${
                    discoverType.smCols || "auto"
                  } lg:grid-cols-${discoverType.lgCols || "auto"} xl:gap-y-6 xl:gap-x-6  xl:grid-cols-${
                    discoverType.xlCols || "auto"
                  }`}
                >
                  {isTemplateArray(discoverData) &&
                    (discoverData as Template[]).map((template: Template, index) => (
                      <TemplateItemComponent
                        discover={true}
                        detailedBar={discoverType.detailedBar}
                        key={"sections" + discoverType + template.id + index}
                        template={template}
                      />
                    ))}
                  {isProfileArray(discoverData) &&
                    (discoverData as Profile[]).map((profile: Profile, index) =>
                      discoverType.smallProfileView ? (
                        <ProfileSmallItemComponent
                          discover={true}
                          key={"profile_small" + discoverType + profile.id + index}
                          profile={profile}
                        />
                      ) : (
                        <ProfileItemComponent
                          discover={true}
                          key={"profile" + discoverType + profile.id + index}
                          profile={profile}
                        />
                      )
                    )}
                </div>
              ) : (
                <div className="">
                  {isTemplateArray(discoverData) && (
                    <Swiper
                      effect="fade"
                      navigation
                      pagination={{ clickable: true }}
                      autoplay={{ delay: 8000 }}
                      aria-rowcount={2}
                      breakpoints={{
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                          grid: {
                            rows: 2,
                          },
                        },
                        1024: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                          grid: {
                            rows: 2,
                          },
                        },
                      }}
                    >
                      {discoverData.map((template: Template, index) => (
                        <SwiperSlide key={discoverType + template.id}>
                          <div className="m-1 p-4 max-w-xl">
                            <TemplateItemComponent
                              discover={true}
                              template={template}
                              detailedBar={discoverType.detailedBar}
                              key={"swiper" + discoverType + template.id + index}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
              )}
              {discoverType.buttonHref != null && (
                <ButtonComponent
                  target="_blank"
                  style="redirect"
                  rel="noopener noreferrer"
                  href={discoverType.buttonHref}
                  parentClassNames="mt-6 inline-block"
                  text={discoverType.buttonText || t("common:more")}
                />
              )}
              <div className={discoverType.gap || "h-12"} />
            </section>
          ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
  userLikes: state.user.likes,
});

export default connect(mapStateToProps)(DiscoverPage);
