import { Timestamp } from "firebase/firestore";
import { Logger } from "./utils-logging";
import { TFunction } from "i18next";

const logger = new Logger("utils-formatting.ts");

export enum SocialNetwork {
  Youtube = "youtube",
  Paypal = "paypal",
  Payhip = "payhip",
  ProductHunt = "producthunt",
  Twitter = "twitter",
  TikTok = "tiktok",
  Threads = "threads",
  BuyMeACoffee = "buymeacoffee",
  X = "x.",
  Gumroad = "gumroad",
  Facebook = "facebook",
  Instagram = "instagram",
  Lemonsqueezy = "lemosqueezy",
  Etsy = "etsy",
  LinkedIn = "linkedin",
  Pinterest = "pinterest",
  Elcovia = "elcovia.",
}

interface SocialNetworkInfo {
  key: SocialNetwork;
  name: string;
  icon: string;
  color: string; // Add color property for brand color
}

const socialNetworksInfo: SocialNetworkInfo[] = [
  { key: SocialNetwork.ProductHunt, name: "Product Hunt", icon: "/icons/producthunt.svg", color: "#f196e4" }, // Example color
  { key: SocialNetwork.Youtube, name: "YouTube", icon: "/icons/youtube.svg", color: "#FF0000" }, // Example color
  { key: SocialNetwork.Paypal, name: "PayPal", icon: "/icons/paypal.svg", color: "#003087" }, // Example color
  { key: SocialNetwork.Facebook, name: "Facebook", icon: "/icons/facebook.svg", color: "#1877F2" }, // Example color
  { key: SocialNetwork.Instagram, name: "Instagram", icon: "/icons/instagram.svg", color: "#E4405F" }, // Example color
  { key: SocialNetwork.LinkedIn, name: "LinkedIn", icon: "/icons/linkedin.svg", color: "#0077B5" }, // Example color
  { key: SocialNetwork.Twitter, name: "Twitter", icon: "/icons/x.svg", color: "#000" }, // Example color
  { key: SocialNetwork.X, name: "Twitter", icon: "/icons/x.svg", color: "#000" }, // Example color
  { key: SocialNetwork.Gumroad, name: "Gumroad", icon: "/icons/gumroad.svg", color: "#ff90e8" }, // Example color
  { key: SocialNetwork.Lemonsqueezy, name: "Lemon Squeezy", icon: "/icons/lemonsqueezy.svg", color: "#FFA500" }, // Example color
  { key: SocialNetwork.BuyMeACoffee, name: "Buy Me A Coffee", icon: "/icons/buymeacoffee.svg", color: "#FFDD00" }, // Example color
  { key: SocialNetwork.TikTok, name: "TikTok", icon: "/icons/tiktok.svg", color: "#000000" }, // Example color
  { key: SocialNetwork.Threads, name: "Threads", icon: "/icons/threads.svg", color: "#000000" }, // Example color
  { key: SocialNetwork.Etsy, name: "Etsy", icon: "/icons/etsy.svg", color: "#D56400" }, // Example color
  { key: SocialNetwork.Pinterest, name: "Pinterest", icon: "/icons/pinterest.svg", color: "#E60023" }, // Example color
  { key: SocialNetwork.Elcovia, name: "Elcovia", icon: "/icons/elcovia.svg", color: "#000000" }, // Example color
];

export function getSocialNetworkType(domain: string): SocialNetwork | null {
  const networkInfo = socialNetworksInfo.find(item => domain && domain.toLowerCase().includes(item.key));
  if (networkInfo) {
    return networkInfo.key;
  }
  return null;
}

export function getSocialNetworkSvgIcon(network: string): string {
  const info = socialNetworksInfo.find(item => item.name === network);
  return info ? process.env.PUBLIC_URL + info.icon : process.env.PUBLIC_URL + "/icons/url.svg";
}

export function getSocialNetworkName(domain: string): string {
  const networkInfo = socialNetworksInfo.find(item => domain && domain.toLowerCase() === item.key);
  if (networkInfo) {
    return networkInfo.name;
  }
  return domain;
}

export function getSocialNetowrkColor(network: string): string | null {
  const info = socialNetworksInfo.find(item => item.name === network);
  return info ? info.color : null;
}

export function getDomainFromUrl(urlString: string): string {
  try {
    const url = new URL(urlString);

    logger.log("getDomainFromUrl() - URL:" + url, {
      protocol: url.protocol,
      hostname: url.hostname,
      pathname: url.pathname,
    });
    const matchedSocialNetwork = socialNetworksInfo.find(info => urlString.includes(info.key));
    if (matchedSocialNetwork) {
      logger.log("getDomainFromUrl() - Result:", matchedSocialNetwork.key);
      return getSocialNetworkName(matchedSocialNetwork.key);
    }

    // Remove "www." prefix
    let domain = url.hostname.replace("www.", "");

    // List of common TLDs to remove
    const commonTLDs = [
      ".com", ".net", ".org", ".edu", ".gov", ".int",
      ".mil", ".arpa", ".aero", ".asia", ".biz", ".cat",
      ".coop", ".info", ".jobs", ".mobi", ".museum", ".name",
      ".pro", ".tel", ".travel", ".xxx", ".br", ".us",
      // Add more common TLDs as needed
      ".blog", ".company", ".store", ".site", ".tech",
      ".online", ".app", ".io", ".design", ".space",
      ".ninja", ".guru", ".media", ".academy", ".agency",
      ".consulting", ".services", ".global", ".ventures",
      ".photography", ".solutions", ".world", ".works",
      // Add more less common TLDs here
    ];

    // Remove common TLDs
    for (const tld of commonTLDs) {
      if (domain.endsWith(tld)) {
        domain = domain.slice(0, -tld.length);
        break; // Only remove one matching TLD
      }
    }


    // Split the domain by "."
    const domainParts = domain.split(".");

    // Check if there are at least two parts, otherwise return the original domain
    if (domainParts.length < 2) {
      return domain.replace(/^\w/, (c) => c.toUpperCase());
    }

    // Return the second to last part of the domain, which is the main domain
    const mainDomain = domainParts[domainParts.length - 2];

    // Capitalize first letter and return result
    return mainDomain.replace(/^\w/, (c) => c.toUpperCase());
  } catch (e) {
    return urlString;
  }
}

// Convert a Timestamp to a localized date string
export function timestampToLocalDate(timestamp: Timestamp): string {
  const date = timestamp.toDate();
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

// Convert a Timestamp to a localized date/time string
export function timestampToLocalDateTime(timestamp: Timestamp): string {
  const date = timestamp.toDate();
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

// Convert a Timestamp to a localized date/time string
export function timestampToLocaleDateTimeShort(timestamp: Timestamp): string {
  const date = timestamp.toDate();
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true };
  return date.toLocaleDateString(undefined, options);
}

/**
 * Converts a duration key to a label.
 * @param duration The duration key to convert.
 * @return The label for the duration key.
 */
export function durationKeyToLabel(
  t: TFunction,
  duration: string): string {
  const regex = /^(\d+)([DMY])$/;
  const matches = duration.match(regex);

  if (matches && matches.length === 3) {
    const durationValue = parseInt(matches[1]);
    let durationUnit = '';

    switch (matches[2]) {
      case 'D':
        durationUnit = t(durationValue > 1 ? 'days' : 'day');
        break;
      case 'M':
        durationUnit = durationValue > 1 ? 'months' : 'month';
        break;
      case 'Y':
        durationUnit = t(durationValue > 1 ? 'years' : 'year');
        break;
      default:
        return "NaN";
    }

    return `${durationValue} ${durationUnit}`;
  }
  logger.logError('durationKeyToLabel() - Invalid duration key:', duration);
  return "NaN";
}


export function parseStringToPrice(value: string): number {
  const regex = /^(\d+)(\.\d+)?$/;
  const matches = value.match(regex);

  if (matches && matches.length === 3) {
    return parseFloat(matches[0]);
  }

  return 0;
}

export function getFormattedPrice(value: number | null): string | null {
  if (value === null) return null;
  // Create a number formatter based on the user's locale
  const numberFormatter = new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 });
  // Format the parsed value with the number formatter
  const formattedValue = numberFormatter.format(value);
  return formattedValue;
}